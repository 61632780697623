<div *ngIf="_mode !== _enumIssueMode.Unknown">
  <span class="title">{{ _str_mode }}</span>
</div>
<div [ngSwitch]="_mode">
  <div *ngSwitchCase="_enumIssueMode.Unknown" class="mode-block">
    <button type="button" class="btn btn-mode" (click)="changeMode(_enumIssueMode.Email)">{{ STR_MODE_EMAIL }}</button>
    <button type="button" class="btn btn-mode" (click)="changeMode(_enumIssueMode.FAESupport)">
      {{ STR_MODE_FAE_SUPPORT }}
    </button>
  </div>
  <div *ngSwitchCase="_enumIssueMode.Email">
    <form #emailForm="ngForm" class="container-fluid">
      <div class="form-group row">
        <label for="col-issue-title"
          class="col-12 col-sm-4 col-md-3 col-xl-2 col-form-label col-form-label-sm txt-bold">
          &#9830; Subject
        </label>
        <div class="col-12 col-sm-8 col-md-9 col-xl-10">
          <input id="col-issue-title" name="issueTitle" type="text" class="form-control form-control-sm"
            [(ngModel)]="_issueTitle" required #issueTitle="ngModel">
        </div>
      </div>
      <div class="form-group row">
        <label for="col-issue-desc" class="col-12 col-sm-4 col-md-3 col-xl-2 col-form-label col-form-label-sm txt-bold">
          &#9830; Description
        </label>
        <div class="col-12 col-sm-8 col-md-9 col-xl-10">
          <textarea id="col-issue-desc" name="issueContent" class="form-control form-control-sm" rows="10"
            [(ngModel)]="_issueData" #issueContent="ngModel"></textarea>
        </div>
      </div>
    </form>
    <div class="container-fluid">
      <div class="form-group row">
        <div class="col-12 d-flex align-items-center mb-2">
          <label for="col-issue-recipients" class="col-form-label-sm txt-bold">
            &#9830; Recipients (Up to 5 recipients)
          </label>
          <button type="button" class="btn btn-sm btn-action ml-auto"
            [disabled]="_emailList.length === RECIPIENT_LIMIT" (click)="addRecipient()">
            <i class="fas fa-plus mr-1"></i>
            Add recipient
          </button>
        </div>
        <div id="col-issue-recipients" class="col-12">
          <div *ngFor="let e of _emailList; let recordIndex = index" class="form-group row mb-1">
            <label [for]="'col-issue-recipient-' + recordIndex"
              class="col-4 col-md-3 col-xl-2 col-form-label col-form-label-sm pl-4">
              #{{ recordIndex + 1 }}
            </label>
            <div class="col-8 col-md-9 col-xl-10 d-flex align-items-center">
              <input [id]="'col-issue-recipient-' + recordIndex" type="text" class="form-control form-control-sm"
                [(ngModel)]="e.email" [disabled]="!e.removable">
              <i class="fas fa-minus-circle ml-2" [class.red-1]="e.removable" (click)="removeRecipient(e)"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="col-issue-symp" class="col-12 col-form-label col-form-label-sm txt-bold">
          &#9830; Monitor devices
        </label>
        <div class="col-12 text-break">
          <div class="border p-2 ml-2">
            {{ _monitorDeviceNames }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="_enumIssueMode.FAESupport">
    <form #faeForm="ngForm" class="container-fluid">
      <div class="form-group row">
        <label for="col-issue-title"
          class="col-12 col-sm-4 col-md-3 col-xl-2 col-form-label col-form-label-sm txt-bold">
          Subject
        </label>
        <div class="col-12 col-sm-8 col-md-9 col-xl-10">
          <input id="col-issue-title" name="issueTitle" type="text" class="form-control form-control-sm"
            [(ngModel)]="_issueTitle" required #issueTitle="ngModel">
          <div [hidden]="issueTitle.valid || issueTitle.pristine" class="alert alert-danger">
            Subject is required
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="col-issue-data" class="col-12 col-sm-4 col-md-3 col-xl-2 col-form-label col-form-label-sm txt-bold">
          Description
        </label>
        <div class="col-12 col-sm-8 col-md-9 col-xl-10">
          <textarea id="col-issue-data" name="issueContent" class="form-control form-control-sm" rows="10"
            [(ngModel)]="_issueData" required #issueContent="ngModel"></textarea>
          <div [hidden]="issueContent.valid || issueContent.pristine" class="alert alert-danger">
            Description is required
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="col-issue-freq" class="col-12 col-sm-4 col-md-3 col-xl-2 col-form-label col-form-label-sm txt-bold">
          Frequency
        </label>
        <div class="col-12 col-sm-8 col-md-9 col-xl-10">
          <div id="col-issue-freq" class="btn-group w-100">
            <button id="dropdownIssueFreqLink" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
              data-toggle="dropdown">
              {{ _issueFrequency | capitalFirstWord }}
            </button>
            <ul class="dropdown-menu dropdown-menu-left option-list w-100" aria-labelledby="dropdownIssueFreqLink">
              <li *ngFor="let freq of ERROR_FREQUENCY" class="dropdown-item" [class.selected]="_issueFrequency === freq"
                (click)="_issueFrequency = freq">
                {{ freq | capitalFirstWord }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="col-issue-symp" class="col-12 col-sm-4 col-md-3 col-xl-2 col-form-label col-form-label-sm txt-bold">
          Symptom
        </label>
        <div class="col-12 col-sm-8 col-md-9 col-xl-10">
          <div id="col-issue-symp" class="btn-group w-100">
            <button id="dropdownIssueSympLink" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
              data-toggle="dropdown">
              {{ _issueSymptom | capitalFirstWord }}
            </button>
            <ul class="dropdown-menu dropdown-menu-left option-list w-100" aria-labelledby="dropdownIssueSympLink">
              <li *ngFor="let symp of ERROR_SYMPTOM" class="dropdown-item" [class.selected]="_issueSymptom === symp"
                (click)="_issueSymptom = symp">
                {{ symp | capitalFirstWord}}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="col-issue-symp" class="col-12 col-form-label col-form-label-sm txt-bold">
          Monitor devices
        </label>
        <div class="col-12 text-break">
          {{ _monitorDeviceNames }}
        </div>
      </div>
    </form>
  </div>
</div>