import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IUIElement } from '../uiElement.interface';
import { AutoUnsubscribeComponent } from 'app/content/virtual/auto-unsubscribe.component';
import { CameraAutoFramingMode, CameraInfo } from './camera.data';

@Component({
    selector: 'na-camera',
    templateUrl: './camera.component.html',
    styleUrls: ['../uiElement.style.css', './camera.component.css']
})
export class CameraComponent extends AutoUnsubscribeComponent implements IUIElement {
    _valid: boolean = true;
    readonly AUTO_FRAMING_LIST: CameraAutoFramingMode[] = ['Speaker', 'Group', 'Grid'];

    _camera: CameraInfo = new CameraInfo();
    @Input('camera')
    set camera(v: any) {
        if (v) {
            this._camera = new CameraInfo(v);
        }
    }
    @Input('showBorder') _showBorder: boolean = true;
    @Input('unsupportReason') _unsupportReason: string; 
    @Input('disabled') _disabled: boolean;
    @Input('lock') _lockInfo: { isSync: boolean, policyID: string, policyName: string };

    @Output() onCameraInfoChanged = new EventEmitter<CameraInfo>();

    enableAutoframing(enabled: boolean): void {
        this._camera.isAutoframingOn = enabled;
        this.onCameraInfoChanged.emit(this._camera);
    }

    selectAutoframingMode(mode: CameraAutoFramingMode): void {
        if (this._camera.autoframingMode !== mode) {
            this._camera.autoframingMode = mode;
            this.onCameraInfoChanged.emit(this._camera);
        } 
    }

    changeFOV(fov: number): void {
        if (this._camera.fov !== fov) {
            this._camera.fov = fov;
            this.onCameraInfoChanged.emit(this._camera);
        }
    }
}