<div>
    <div>
        Reboot the device(s) immediately or on a specific schedule.
    </div>
    <div *ngIf="_schedule" class="my-2">
        <div class="d-flex align-items-center">
            <label for="col-fw-schedule-update" class="col-form-label txt-bold">
                Schedule
            </label>
            <div class="ml-2">
                <div id="col-fw-schedule-update" class='switch-container'>
                    <input id="cmn-toggle-fw-update" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat"
                        type="checkbox" [checked]="_schedule.enabled" (change)="enableSchedule($event.target.checked)">
                    <label for="cmn-toggle-fw-update">
                        <span class="sr-only">Enable reboot schedule update</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="d-flex align-items-center flex-wrap border p-2">
                    <span>Update on</span>
                    <input type="date" class="ml-2" [value]="_schedule.date" [disabled]="!_schedule.enabled"
                        aria-label="Firmware schedule date" (change)="changeScheduleDate($event.target.value)">
                    <span class="ml-2">starting at</span>
                    <div class="btn-group ml-2">
                        <button id="dropdownScheduleTimeLink" type="button"
                            class="btn btn-sm btn-action btn-light dropdown-toggle" [disabled]="!_schedule.enabled"
                            data-toggle="dropdown">
                            {{ _schedule.time }}
                            <span class="sr-only">modify firmware update time</span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownScheduleTimeLink"
                            style="min-width:300px">
                            <div class="px-2">
                                <na-timePicker [time]="_schedule.time" [minuteIncre]="5" [showBorder]="false"
                                    [showSelectionTime]="false" (onTimeChanged)="changeScheduleTime($event)">
                                </na-timePicker>
                            </div>
                        </ul>
                    </div>
                    <div class="btn-group ml-2">
                        <button id="dropdownScheduleOffsetLink" type="button"
                            class="btn btn-sm btn-action btn-light dropdown-toggle" [disabled]="!_schedule.enabled"
                            data-toggle="dropdown">
                            GMT <ng-template [ngIf]="_schedule.offset >= 0">+</ng-template>{{ _schedule.offset }}:00
                            <span class="sr-only">modify firmware update time offset</span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-left option-list"
                            aria-labelledby="dropdownScheduleOffsetLink">
                            <li *ngFor="let o of GMTLIST" (click)="changeScheduleTimeOffset(o)">
                                GMT <ng-template [ngIf]="o >= 0">+</ng-template>{{ o }}:00
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>