import { DeviceInfo } from '../../device/data/device-info';

export enum AndroidGroupType {
    Unknown = 'Unknown',
    And_44 = '4.4',
    And_71 = '7.1',
    And_9 = '9.0',
    And_12 = '12.0'
}

export enum FwUpdateStatus {
    CHECKING = 'CHECKING',
    WAITING_INSTALL = 'WAITING_INSTALL',
    WAITING_CHECK = 'WAITING_CHECK',
    INSTALLING = 'INSTALLING',
    WAITING_REBOOT = 'WAITING_REBOOT',
    UPDATE_STATUS_UNKNOWN = 'UPDATE_STATUS_UNKNOWN',
    CHECK_FAILED = 'CHECK_FAILED',
    DOWNLOAD_FAILED = 'DOWNLOAD_FAILED',
    INSTALL_FAILED = 'INSTALL_FAILED'
}

export class FirmwareUpdateInfo {
    modelName: string;
    modelDescription: string;
    PCBRevision: number;
    firmwareVersion: string;
    link: string;
    releaseDate: string;
    notice: string;
}

export enum FwUpdateMethod {
    Unknown = 1,
    Latest,
    FromURL
}

export class FirmwareFamilyInfo {
    familyId: string;
    familyDisplayName: string;
    devices: DeviceInfo[];
    modelName: string;
    targetAndroidVersion: AndroidGroupType;
    updateInfo: FirmwareUpdateInfo;
    updateMethod: FwUpdateMethod;
    customURL: string;
    needUpdate: boolean;

    constructor() {
        this.devices = [];
        this.updateMethod = FwUpdateMethod.Unknown;
        this.needUpdate = false;
    }
}

