<div>
    <div class="container-fluid scroll scroll-xs px-0 pr-md-2">
        <div class="form-group row">
            <label for="col-policy-report-name" class="col-6 col-md-3 col-xl-2 col-form-label">
                Policy name
            </label>
            <div class="col-6 col-md-9 col-xl-10">
                <div id="col-policy-report-name" class="form-control-plaintext">
                    {{ _policy.name }}
                    <small *ngIf="_policy.isDefault" class="policy-flag">Default</small>
                    <small *ngIf="_policy.isManaged" class="policy-flag">Managed</small>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="col-policy-report-type" class="col-6 col-md-3 col-xl-2 col-form-label">
                Policy type
            </label>
            <div class="col-6 col-md-9 col-xl-10">
                <div id="col-policy-report-type" class="form-control-plaintext">
                    {{ _policy.type }}
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label for="col-policy-report-setting" class="col-12 col-form-label">
                Setting
            </label>
            <div class="col-12">
                <ng-template policy-type></ng-template>
            </div>
        </div>
        <div class="form-group row">
            <label for="col-policy-report-group" class="col-12 col-form-label">
                Apply to
            </label>
            <div class="col-12">
                <div class="list-block">
                    <div *ngFor="let devGroup of _policy.groupList" class="mr-2 d-flex align-items-center">
                        <div class="name">
                            <i *ngIf="devGroup.type === _enumDevGroupType.device" class="fas fa-inbox green-3 mr-1"></i>
                            <a [routerLink]="['/app/device/devices']" [queryParams]="{'group': devGroup.id}"
                                class="underline">{{ devGroup.name }}</a>
                        </div>
                        <div *ngIf="_policy?.usage?.[devGroup.id]" class="ml-2">
                            <div *ngIf="_policy.usage[devGroup.id].total > 0; else templateNoDevGroup">
                                <div *ngIf="_policy.usage[devGroup.id].total === _policy.usage[devGroup.id].synced; else templatePolicyUnsync" class="green-2">
                                    {{ _policy.usage[devGroup.id].synced }} synced
                                </div>
                                <ng-template #templatePolicyUnsync>
                                    ( <a [routerLink]="['/app/device/devices']" [queryParams]="{'group': devGroup.id, 'policy': _policy.id, 'policyState': 'NotSync'}" class="underline">{{ _policy.usage[devGroup.id].total - _policy.usage[devGroup.id].synced }}</a> / {{ _policy.usage[devGroup.id].total }} ) not synced
                                </ng-template>
                            </div>
                            <ng-template #templateNoDevGroup>
                                No device
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>