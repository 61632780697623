<div class="na-timePicker">
  <div>
    <div *ngIf="showSelectionTime" class="showTxt">
      {{ _selectTime }}
      <i *ngIf="_lockInfo" class="fas fa-lock ml-2 red-1" data-toggle="tooltip"
        [title]="'Lock by policy <<' + _lockInfo.policyName + '>>'"></i>
    </div>
    <div class="timePicker-body">
      <div class="title">Hour</div>
      <fieldset class="hour-group">
        <legend class="sr-only">Hour options</legend>
        <div *ngFor="let h of _hourList">
          <input type="radio" [name]="'h-group-' + _random" [value]="h" [id]="'h' + _random + h"
            [checked]="h === _selectH" [disabled]="isHourDisabled(h)" (change)="changeHour($event.target.value)">
          <label [for]="'h' + _random + h" (click)="!_autoNotify ? $event.stopPropagation() : ''">
            <span>{{ h }}</span>
          </label>
        </div>
      </fieldset>
      <div class="title">Minute</div>
      <fieldset class="minute-group">
        <legend class="sr-only">Minute options</legend>
        <div *ngIf="_selectH !== '24'; else templateSelect24Hour">
          <div *ngFor="let m of _minuteList">
            <input type="radio" [name]="'m-group-' + _random" [value]="m" [id]="'m' + _random + m"
              [checked]="m === _selectM" [disabled]="isMinuteDisabled(m)" (change)="changeMinute($event.target.value)">
            <label [for]="'m' + _random + m" (click)="!_autoNotify ? $event.stopPropagation() : ''">
              <span>{{ m }}</span>
            </label>
          </div>
        </div>
        <ng-template #templateSelect24Hour>
          <input type="radio" [name]="'m-group-' + _random" [value]="'00'" [id]="'m' + _random + '00'"
            [checked]="'00' === _selectM" [disabled]="isMinuteDisabled('00')"
            (change)="changeMinute($event.target.value)">
          <label [for]="'m' + _random + '00'" (click)="!_autoNotify ? $event.stopPropagation() : ''">
            <span>00</span>
          </label>
        </ng-template>
      </fieldset>
    </div>
  </div>
  <div *ngIf="!_autoNotify" class="mt-2 float-right">
    <button type="button" class="btn btn-sm btn-action btn-light" (click)="cancel()">Cancel</button>
    <button type="button" class="btn btn-sm btn-action btn-light ml-2" (click)="ok()">OK</button>
  </div>
  <div *ngIf="!_on || _disabled || _lockInfo" class="overlap-disable"></div>
</div>