import { Component } from '@angular/core';

import { AppStartOverlayInfo, DeviceInfo, DeviceType, IPolicyLockMap } from '../../../device/data/device-info';
import { DevFuncActionInterface } from '../../base/action/dev-func-action.interface';
import { AppConfigService } from '../../../../../app/app.config';
import { TimeserverInfo } from '../../../../../app/uiElement/schedule/timeserver/timeserver.data';
import { DeviceBasicConfigControl, MENU_GENERAL, MENU_SCHEDULE, MENU_SCHEDULE_TIME, MENU_SCHEDULE_REBOOT, MENU_SCHEDULE_LOCKSCREEN, MENU_CONTENT_MAINTENANCE, MENU_CONTENT, MENU_CONTENT_APPSTART, MENU_CONTENT_QRCODE } from '../data/device-basic-config.control';
import { MaintenancePlaybackInfo } from '../../../../uiElement/maintenancePlayback/mtPlayback.data';
import { LockScreenInfo, LockScreenMode } from '../../../../uiElement/schedule/screen/screen.data';
import { ConstantService } from '../../../../../app/lib/common/constant.service';
import { PowerSaveInfo } from '../../../../uiElement/powerSave/power-save.data';
import { AppStartPlaybackMode } from '../../../../uiElement/appstart/appstart.data';
import { DailyRebootInfo } from 'app/uiElement/schedule/reboot/dailyReboot.data';
import { HelperLib } from 'app/lib/common/helper.lib';
import { environment } from 'environments/environment';

@Component({
    templateUrl: './basic-config-action-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './basic-config-action-subform.component.css', '../../dev-func.style.css']
})
export class BasicConfigActionSubFormComponent implements DevFuncActionInterface {
    //global setting
    Enable_name: boolean = AppConfigService.configs.devPage.func.basicConfig.element.name;
    Enable_contentUrl: boolean = AppConfigService.configs.devPage.func.basicConfig.element.contentUrl;
    Enable_appstart: boolean = AppConfigService.configs.devPage.func.basicConfig.element.contentUrl || AppConfigService.configs.devPage.func.basicConfig.element.appStartExtraOption;
    Enable_overlay: boolean = AppConfigService.configs.devPage.func.basicConfig.element.overlay;
    Enable_timezone: boolean = AppConfigService.configs.devPage.func.basicConfig.element.timezone;
    Enable_timeserver: boolean = AppConfigService.configs.devPage.func.basicConfig.element.timeserver;
    Enable_resolution: boolean = AppConfigService.configs.devPage.func.basicConfig.element.resolution;
    Enable_orientation: boolean = AppConfigService.configs.devPage.func.basicConfig.element.orientation;
    Enable_hdcp: boolean = AppConfigService.configs.devPage.func.basicConfig.element.HDCP;
    Enable_adb: boolean = AppConfigService.configs.devPage.func.basicConfig.element.adb;
    Enable_dailyReboot: boolean = AppConfigService.configs.devPage.func.basicConfig.element.dailyReboot;
    Enable_speaker: boolean = AppConfigService.configs.devPage.func.basicConfig.element.speaker;
    Enable_mic: boolean = AppConfigService.configs.devPage.func.basicConfig.element.mic;
    Enable_maintenance: boolean = AppConfigService.configs.devPage.func.basicConfig.element.maintenancePlayback;
    Enable_powersave: boolean = AppConfigService.configs.devPage.func.basicConfig.element.powersave;
    Enable_screenOff: boolean = AppConfigService.configs.devPage.func.basicConfig.element.screenOff.enabled;
    Enable_screensaver: boolean = AppConfigService.configs.devPage.func.basicConfig.element.screenSaver;
    Enable_camera: boolean = AppConfigService.configs.devPage.func.basicConfig.element.camera;

    //menu
    MENU_GENERAL: string = MENU_GENERAL;
    MENU_CONTENT: string = MENU_CONTENT;
    MENU_CONTENT_APPSTART: string = MENU_CONTENT_APPSTART;
    MENU_CONTENT_QRCODE: string = MENU_CONTENT_QRCODE;
    MENU_CONTENT_MAINTENANCE: string = MENU_CONTENT_MAINTENANCE;

    MENU_SCHEDULE: string = MENU_SCHEDULE;
    MENU_SCHEDULE_TIME: string = MENU_SCHEDULE_TIME;
    MENU_SCHEDULE_REBOOT: string = MENU_SCHEDULE_REBOOT;
    MENU_SCHEDULE_LOCKSCREEN: string = MENU_SCHEDULE_LOCKSCREEN;

    actionCompleteHandler: (result: any) => void;
    actionStatusUpdateHandler: (isValid: boolean) => void;

    _config: DeviceBasicConfigControl;
    _device: DeviceInfo;
    
    _lockMap: IPolicyLockMap = {};
    _menuMap: { [menuName: string]: { active?: boolean, isFault?: boolean, firstSubMenu?: string } } = {};

    private _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        if (this._actionData) {
            this._config = this._actionData.configData;
            this._menuMap = this._actionData.configMenu;
            this._device = this._actionData.deviceList[0];
            this._lockMap = this._actionData.lockMap || {};
            this.updateDeviceSupportFuncs();
        }
    }

    constructor(private constantSvc: ConstantService) { }

    private updateDeviceSupportFuncs(): void {
        const deviceType: DeviceType = HelperLib.getDeviceType(this._device?.currentSettings[this.constantSvc.DEVKEY_INFO_FW_FAMILY]);
        this.Enable_name  = AppConfigService.configs.devPage.func.basicConfig.element.name && environment.deviceFuncMap[deviceType]?.basicConfig.element.name;
        this.Enable_contentUrl = AppConfigService.configs.devPage.func.basicConfig.element.contentUrl && environment.deviceFuncMap[deviceType]?.basicConfig.element.contentUrl;
        this.Enable_overlay = AppConfigService.configs.devPage.func.basicConfig.element.overlay && environment.deviceFuncMap[deviceType]?.basicConfig.element.overlay;
        this.Enable_timezone = AppConfigService.configs.devPage.func.basicConfig.element.timezone && environment.deviceFuncMap[deviceType]?.basicConfig.element.timezone;
        this.Enable_timeserver = AppConfigService.configs.devPage.func.basicConfig.element.timeserver && environment.deviceFuncMap[deviceType]?.basicConfig.element.timeserver;
        this.Enable_resolution = AppConfigService.configs.devPage.func.basicConfig.element.resolution && environment.deviceFuncMap[deviceType]?.basicConfig.element.resolution;
        this.Enable_orientation = AppConfigService.configs.devPage.func.basicConfig.element.orientation && environment.deviceFuncMap[deviceType]?.basicConfig.element.orientation;
        this.Enable_hdcp = AppConfigService.configs.devPage.func.basicConfig.element.HDCP && environment.deviceFuncMap[deviceType]?.basicConfig.element.HDCP;
        this.Enable_adb = AppConfigService.configs.devPage.func.basicConfig.element.adb && environment.deviceFuncMap[deviceType]?.basicConfig.element.adb;
        this.Enable_dailyReboot = AppConfigService.configs.devPage.func.basicConfig.element.dailyReboot && environment.deviceFuncMap[deviceType]?.basicConfig.element.dailyReboot;
        this.Enable_speaker = AppConfigService.configs.devPage.func.basicConfig.element.speaker && environment.deviceFuncMap[deviceType]?.basicConfig.element.speaker;
        this.Enable_mic= AppConfigService.configs.devPage.func.basicConfig.element.mic && environment.deviceFuncMap[deviceType]?.basicConfig.element.mic;
        this.Enable_maintenance = AppConfigService.configs.devPage.func.basicConfig.element.maintenancePlayback && environment.deviceFuncMap[deviceType]?.basicConfig.element.maintenancePlayback;
        this.Enable_powersave = AppConfigService.configs.devPage.func.basicConfig.element.powersave && environment.deviceFuncMap[deviceType]?.basicConfig.element.powersave;
        this.Enable_screenOff = AppConfigService.configs.devPage.func.basicConfig.element.screenOff.enabled && environment.deviceFuncMap[deviceType]?.basicConfig.element.screenOff.enabled;
        this.Enable_screensaver = AppConfigService.configs.devPage.func.basicConfig.element.screenSaver && environment.deviceFuncMap[deviceType]?.basicConfig.element.screenSaver;
        this.Enable_camera = AppConfigService.configs.devPage.func.basicConfig.element.camera && environment.deviceFuncMap[deviceType]?.basicConfig.element.camera;
    }

    selectMenu(menuName: string): void {
        //reset all menu
        Object.keys(this._menuMap).forEach((menuName: string) => this._menuMap[menuName].active = false);

        this._menuMap[menuName].active = true;
        if (this._menuMap[menuName].firstSubMenu) {
            this._menuMap[this._menuMap[menuName].firstSubMenu].active = true;
        }
        //check if it has parent
        const menu_pieces = menuName.split('-');
        if (menu_pieces.length === 3) {
            this._menuMap[menu_pieces[0] + '-' + menu_pieces[1]].active = true;
        }
    }

    getLockScreenPolicyLock(): { [lockID: string]: { isSync: boolean, policyID: string, policyName: string, policyType: string } } {
        const ret: { [lockID: string]: { isSync: boolean, policyID: string, policyName: string, policyType: string } }  = {};
        if (this._lockMap[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENOFF]) {
            ret[LockScreenMode.screenOff] = this._lockMap[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENOFF];
        }
        if (this._lockMap[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENSAVER]) {
            ret[LockScreenMode.screenSaver] = this._lockMap[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENSAVER];
        }
        return Object.keys(ret).length > 0 ? ret : null;
    }

    onBasicConfigTimeserverChanged(t: TimeserverInfo): void {
        this._config.timeserverSyncLocation = t.url;
        this._config.enableTimeserver = t.enabled;
    }

    onBasicConfigDailyRebootChanged(s: DailyRebootInfo): void {
        this._config.enableDailyReboot = s.enabled;
        this._config.dailyReboot = s.transform();
        this._config.dailyRebootTime = s.time;
    }

    onBasicConfigMaintenanceChanged(m: MaintenancePlaybackInfo): void {
        this._config.maintenancePlayback = m;
        this._config.ctrl_maintenancePlayback.isValid = m.isValid;
    }

    onBasicConfigLockScreenChanged(s: LockScreenInfo): void {
        this._config.lockScreen = s;
        this._config.ctrl_lockScreen.isValid = s.isValid;
    }

    onBasicConfigAppstartChanged(s: { playbackMode?: AppStartPlaybackMode, property?: { name: string, value: any } }): void {
        if (s.playbackMode) {
            this._config.appstartPlaybackMode = s.playbackMode;
        }
        if (s.property) {
            this._config.appStart.settingMap[s.property.name].value = s.property.value;
        }
    }

    onBasicConfigOverlayQRCodeChanged(s: AppStartOverlayInfo): void {
        this._config.ctrl_qrcode.settingMap[this.constantSvc.DEVKEY_APPSTART_OVERLAY].value = s;
    }

    onBasicConfigPowersaveChanged(s: { data: PowerSaveInfo, isValid: boolean }): void {
        this._config.ctrl_powersave.settingMap[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_POWERSAVE].value = !s.data.enabled;
        this._config.ctrl_powersave.settingMap[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_TIMEOUT].value = s.data.timeout;
        this._config.ctrl_powersave.settingMap[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_ACTION].value = s.data.mode;
    }

    onBasicConfigDisplayOrientationChanged(s: string | number): void {
        this._config.orientation = s;
    }
}