<div class="d-flex">
    <button type="button" class="btn btn-sm btn-action btn-light layout-mobile mr-2"
        [disabled]="_isLoading || _isAcking || _notificationList.length === 0" (click)="selectAllNotifications(!_allChecked)">
        <span *ngIf="!_allChecked; else templateButtonUnselectAll">Select all</span>
        <ng-template #templateButtonUnselectAll>Unselect all</ng-template>
    </button>
    <button *ngIf="supportAckOpenAlert()" id="id_alert_open_do_ack" type="button" class="btn btn-sm btn-action btn-light"
        [disabled]="_isLoading || _isAcking || _notificationList.length === 0" (click)="ack()">
        Acknowledged
    </button>
    <button id="id_alert_open_do_refreshAll" type="button" class="btn btn-sm btn-action btn-light ml-2"
        [disabled]="_isLoading || _isAcking" (click)="refresh()">
        <i class="fas fa-redo-alt mr-1"></i>
        Refresh
    </button>
</div>
<div class="list-group layout-desktop mt-2">
    <div class="list-group-item list-group-header">
        <div class="row">
            <div class="col-md-3 col title d-flex align-items-center">
                <input type="checkbox" id="cb-th-open-alert" class="cmn-checkbox cmn-checkbox-white"
                    [checked]="_allChecked" (change)="selectAllNotifications($event.target.checked)">
                <label for="cb-th-open-alert">
                    <svg>
                        <use xlink:href="#checkmark" />
                    </svg>
                    <span class="sr-only">Select all open alerts</span>
                </label>
                <span class="ml-2">Occurred on</span>
            </div>
            <div class="col-md-2 col title">
                Type
            </div>
            <div class="col title">
                Description
            </div>
            <div class="col-md-2 col-1 title">
                Action
            </div>
        </div>
    </div>
    <div *ngIf="!_isLoading; else templateLoadingOpenAlertList"
        class="list-group-content list-group-hoverless list-group-content-nr-3">
        <div *ngFor="let a of _notificationList" class="list-group-item">
            <div class="row align-items-center">
                <div class="col-md-3 col d-flex align-items-center text-break">
                    <i *ngIf="_selectionMap[a.id].acked; else templateUnackItemCheckbox" class="fas fa-bell-slash"></i>
                    <ng-template #templateUnackItemCheckbox>
                        <input [id]="'cb-td-open-alert-' + a.id" type="checkbox" class="cmn-checkbox"
                            [checked]="_selectionMap[a.id].checked"
                            (change)="selectNotification(a, $event.target.checked)" />
                        <label [for]="'cb-td-open-alert-' + a.id">
                            <svg>
                                <use xlink:href="#checkmark" />
                            </svg>
                            <span class="sr-only">Select alert {{a.id}}</span>
                        </label>
                    </ng-template>
                    <span class="ml-2">{{ a.lastDateTime | date:'yyyy-MM-dd HH:mm:ss' }}</span>
                </div>
                <div class="col-md-2 col text-break">
                    {{ a.type }}
                </div>
                <div class="col text-break">
                    {{ a.subject }}
                    <a *ngIf="a.sourceAlertID" [routerLink]="[_alertConfigRoute, a.sourceAlertID]">
                        <i class="fas fa-external-link-square-alt"></i>
                    </a>
                </div>
                <div class="col-md-2 col-1">
                    <button *ngIf="_selectionMap[a.id].acked" type="button"
                        class="btn btn-sm btn-action btn-light d-md-block d-none" data-toggle="tooltip"
                        title="Unacknowledge" [disabled]="_selectionMap[a.id].working"
                        (click)="!_selectionMap[a.id].working ? unack(a.id) : ''">
                        <div class="text-ellipse">Unacknowledge</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <ng-template #templateLoadingOpenAlertList>
        <div class="loading">
            <p>Please wait...</p>
            <div class="loading-box"></div>
        </div>
    </ng-template>
</div>
<div class="layout-mobile scroll scroll-md col-12 px-0 mt-2">
    <div *ngFor="let a of _notificationList" class="card card-list">
        <div class="card-header d-flex align-items-center">
            <i *ngIf="_selectionMap[a.id].acked; else templateUnackItemCheckbox" class="fas fa-bell-slash p-1"></i>
            <ng-template #templateUnackItemCheckbox>
                <input [id]="'cb-td-open-alert-' + a.id" type="checkbox" class="cmn-checkbox cmn-checkbox-white"
                    [checked]="_selectionMap[a.id].checked" (change)="selectNotification(a, $event.target.checked)" />
                <label [for]="'cb-td-open-alert-' + a.id">
                    <svg>
                        <use xlink:href="#checkmark" />
                    </svg>
                    <span class="sr-only">Select alert {{a.id}}</span>
                </label>
            </ng-template>
        </div>
        <div class="card-body">
            <div class="form-group row">
                <span class="col-4">Occurred on</span>
                <div class="col-8 pl-0">
                    {{ a.lastDateTime | date:'yyyy-MM-dd HH:mm:ss' }}
                </div>
            </div>
            <div class="form-group row">
                <span class="col-4">Type</span>
                <div class="col-8 pl-0">{{ a.type }}</div>
            </div>
            <div class="form-group row">
                <span class="col-4">Description</span>
                <div class="col-8 pl-0">
                    {{ a.subject }}
                    <a *ngIf="a.sourceAlertID" [routerLink]="[_alertConfigRoute, a.sourceAlertID]">
                        <i class="fas fa-external-link-square-alt"></i>
                    </a>
                </div>
            </div>
            <div class="form-group row">
                <span class="col-4">Action</span>
                <div class="col-8 pl-0">
                    <button *ngIf="_selectionMap[a.id].acked" type="button" class="btn btn-sm btn-action btn-light"
                        data-toggle="tooltip" title="Unacknowledge" [disabled]="_selectionMap[a.id].working"
                        (click)="!_selectionMap[a.id].working ? unack(a.id) : ''">
                        Unacknowledge
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="d-flex align-items-center mt-1">
    <div class="ml-auto text-muted font-italic">
        total : {{ _total }}
    </div>
    <div class="ml-2">
        <na-pager [currentPage]="_currentPage" [numberInPage]="30" [total]="_total"
            (onPageChanged)="onPageChange($event)">
        </na-pager>
    </div>
</div>