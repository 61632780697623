<div>Configure ServiceNow connection and synchronize calendars with IAdea Booking-for-Workplace</div>
<div *ngIf="!_loading; else templateLoading">
    <ng-container *ngIf="!_errorMessage; else templateError">
        <ul class='nav nav-tabs mt-2'>
            <li *ngFor="let func of _funcs" class="nav-item">
                <a [id]="'idSvcNowFunc' + func.type" class="nav-link" [class.active]="_activeTab === func.type"
                    [routerLink]="func.link" (click)="_activeTab = func.type">
                    {{ func.displayName }}
                </a>
            </li>
        </ul>
        <div class='tab-content' class="pt-2">
            <router-outlet></router-outlet>
        </div>
    </ng-container>
    <ng-template #templateError>
        <div class="block block-error text-center mt-1">{{ _errorMessage }}</div>
    </ng-template>
</div>
<ng-template #templateLoading>
    <div class="loading">
        <p>Please wait...</p>
        <div class="loading-box"></div>
    </div>
</ng-template>