import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AllDeviceComponent } from './device/devices.component';
import { DeviceDetailComponent } from './device/detail/device-detail.component';
import { ContentComponent } from './content.component';
import { TroubleshootComponent } from './troubleshoot/troubleshoot.component';
import { AlertConfigOverviewComponent } from './setting/alert/alertConfig/alert-config-overview.component';
import { DeviceLicenseComponent } from './license/device-license.component';

import { AuthGuard } from '../entry/auth-guard.service';
import { LanToolComponent } from './setting/lanTool/lanTool.component';
import { ActivityOverviewComponent } from './event/activity/activity-overview.component';
import { ActivityDetailComponent } from './event/activity/activity-detail.component';
import { PolicyOverviewComponent } from './setting/policy/policy-overview.component';
import { AccessKeyComponent } from './setting/accessKey/accessKey.component';
import { AlertWrapperComponent } from './setting/alert/alert-wrapper.component';
import { OpenAlertOverviewComponent } from './setting/alert/alertAck/alert-open-overview.component';
import { CloseAlertOverviewComponent } from './setting/alert/alertAck/alert-close-overview.component';
import { ReportWrapperComponent } from './setting/report/report-wrapper.component';
import { ReportOverviewComponent } from './setting/report/comp/report-overview.component';
import { ReportConfigComponent } from './setting/report/comp/report-config.component';
import { EventLogOverviewComponent } from './event/log/log-overview.component';
import { PairDeviceCOmponent } from './device/pair/dev-pair-own.component';
import { UserOverviewComponent } from './admin/user/user-overview.component';
import { UserPolicyOverviewComponent } from './admin/user/policy/user-policy-overview.component';
import { UserDetailComponent } from './admin/user/user-detail.component';
import { UserPolicyDetailComponent } from './admin/user/policy/comp/user-policy-detail.component';
import { SCEPOverviewComponent } from './admin/scep/scep-overview.component';
import { SCEPDetailWrapperComponent } from './admin/scep/scep-detail-wrapper.component';
import { CustomizationComponent } from './admin/customization/customization.component';
import { DeviceRemoteControlComponent } from './device/remote/dev-remote-ctrl.component';
import { AccountLicenseComponent } from './license/account-license.component';
import { CertOverviewComponent } from './admin/cert/cert-overview.component';
import { ServiceNowWrapperComponent } from './admin/3rdIntegrate/servicenow/svcnow-wrapper.component';
import { ServiceNowRegisterComponent } from './admin/3rdIntegrate/servicenow/comp/svcnow-register.component';
import { ServiceNowPairingComponent } from './admin/3rdIntegrate/servicenow/comp/svcnow-pairing.component';
import { ServiceNowCalendarConfigComponent } from './admin/3rdIntegrate/servicenow/comp/svcnow-calendar-config.component';
import { DashboardComponent } from './dashboard/dashboard.component';


//The order of the routes matters.
//The router uses a first-mathc wins strategy when matching routes, so more specific routes should be placed above less specific routes.
//Generally, routes with a static path are listed first, followed by an empty path route, that matches the default route.
//The wildcard route comes last because it matches every URL and should be selected only if no other routes are matched first.
const contentRoutes: Routes = [
    {
        path: 'app',
        component: ContentComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent
            },
            {
                path: 'device',
                children: [
                    {
                        path: 'devices',
                        component: AllDeviceComponent
                    },
                    {
                        path: 'devices/:id',
                        component: DeviceDetailComponent
                    },
                    {
                        path: 'pair/own',
                        component: PairDeviceCOmponent
                    },
                    {
                        path: 'policy',
                        component: PolicyOverviewComponent
                    },
                    {
                        path: 'policy/:id',
                        component: PolicyOverviewComponent
                    },
                    {
                        path: 'remotectrl/:id',
                        component: DeviceRemoteControlComponent
                    }
                ]
            },
            {
                path: 'notification',
                children: [
                    {
                        path: 'alert',
                        component: AlertWrapperComponent,
                        children: [
                            {
                                path: 'config',
                                component: AlertConfigOverviewComponent
                            },
                            {
                                path: 'config/:id',
                                component: AlertConfigOverviewComponent
                            },
                            {
                                path: 'open',
                                component: OpenAlertOverviewComponent
                            },
                            {
                                path: 'close',
                                component: CloseAlertOverviewComponent
                            }
                        ]
                    },
                    {
                        path: 'report',
                        component: ReportWrapperComponent,
                        children: [
                            {
                                path: 'records',
                                component: ReportOverviewComponent
                            },
                            {
                                path: 'config',
                                component: ReportConfigComponent
                            }
                        ]
                    },
                ]
            },
            {
                path: 'miscellaneous',
                children: [
                    {
                        path: 'LanConfigTool',
                        component: LanToolComponent
                    },
                    {
                        path: 'accessKey',
                        component: AccessKeyComponent
                    }
                ]
            },
            {
                path: 'troubleshoot',
                component: TroubleshootComponent
            },
            {
                path: 'license',
                children: [
                    {
                        path: 'device',
                        component: DeviceLicenseComponent
                    },
                    {
                        path: 'account',
                        component: AccountLicenseComponent
                    }
                ]
            },
            {
                path: 'event',
                children: [
                    {
                        path: 'log',
                        component: EventLogOverviewComponent
                    },
                    {
                        path: 'activity',
                        component: ActivityOverviewComponent
                    },
                    {
                        path: 'activity/:id',
                        component: ActivityDetailComponent
                    }
                ]
            },
            {
                path: 'admin',
                children: [
                    {
                        path: 'users/all',
                        component: UserOverviewComponent
                    },
                    {
                        path: 'users/all/:id',
                        component: UserDetailComponent
                    },
                    {
                        path: 'users/role',
                        component: UserPolicyOverviewComponent,
                        children: [
                            {
                                path: ':id',
                                component: UserPolicyDetailComponent
                            }
                        ]
                    },
                    {
                        path: 'customization',
                        component: CustomizationComponent
                    },
                    {
                        path: 'scep',
                        component: SCEPOverviewComponent
                    },
                    {
                        path: 'appint',
                        children: [
                            {
                                path: 'svcnow',
                                component: ServiceNowWrapperComponent,
                                children: [
                                    {
                                        path: 'register',
                                        component: ServiceNowRegisterComponent
                                    },
                                    {
                                        path: 'pairing',
                                        component: ServiceNowPairingComponent
                                    },
                                    {
                                        path: 'calendar-config',
                                        component: ServiceNowCalendarConfigComponent
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: 'cert',
                        component: CertOverviewComponent
                    },
                    {
                        path: 'scep/:id',
                        component: SCEPDetailWrapperComponent
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(contentRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class ContentRoutingModule { }