<div>
    <div>
        <div class="form-group row">
            <label for="col-policy-app-target" class="col-5 col-md-4 col-xl-3 col-form-label-sm">
                Select your default app
            </label>
            <div id="col-policy-app-target" class="col-7 col-md-8 col-xl-9">
                <div class="btn-group w-100">
                    <button id="dropdownAppstartLink" type="button"
                        class="btn btn-sm btn-action btn-light dropdown-toggle text-wrap" data-toggle="dropdown">
                        {{ _appToLaunch?.name }}
                    </button>
                    <ul class="dropdown-menu option-list w-100" data-backdrop="static" data-keyboard="false"
                        aria-labelledby="dropdownAppstartLink">
                        <ng-template ngFor let-app [ngForOf]="_appLaunchList">
                            <li class="dropdown-item" [class.active]="app.id === _appToLaunch?.id"
                                (click)="changeExecuteByAppstart(app)">
                                <span>
                                    <span *ngIf="app.id !== APP_ID_NONE">[App]</span> {{ app.name }}
                                </span>
                            </li>
                        </ng-template>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="d-flex">
            <label for="app-detail-block" class="col-form-label-sm txt-bold mb-0">App details :</label>
            <button type="button" class="btn btn-sm btn-nb ml-auto" (click)="addApp()">
                <i class="fas fa-plus mr-1"></i>
                Add more app
            </button>
        </div>
        <div *ngIf="_unsupportReason" class="warning-block mb-1">{{ _unsupportReason }}</div>
    </div>
    <div id="app-detail-block" class="app-block">
        <div class="ctrl-bar">
            <strong *ngIf="_app; else templateNoApp">
                App : {{ _currentAppIndex + 1 }} / {{ _appList.length }}
            </strong>
            <ng-template #templateNoApp>
                <strong class="red-1">No app</strong>
            </ng-template>
            <i *ngIf="_appList.length > 1" class="fas fa-angle-left app-anchor ml-4"
                [class.disabled]="_currentAppIndex === 0" (click)="goPrevApp()"></i>
            <i *ngIf="_appList.length > 1" class="fas fa-angle-right app-anchor ml-4"
                [class.disabled]="_currentAppIndex === _appList.length - 1" (click)="goNextApp()"></i>
            <i *ngIf="_app" class="fas fa-times app-anchor ml-auto" (click)="removeApp()"></i>
        </div>
        <form #appDetailForm="ngForm">
            <div *ngIf="_app" class="mt-2 mx-3">
                <div class="form-group row">
                    <label [for]="'app-mgr-apkName-' + _app.id"
                        class="col-12 col-sm-5 col-md-4 col-form-label-sm required">
                        Name
                    </label>
                    <div class="col-12 col-sm-7 col-md-8">
                        <input [id]="'app-mgr-apkName-' + _app.id" type="text" name="appSolutionName"
                            class="form-control form-control-sm" [disabled]="_lockInfo || _disabled"
                            [(ngModel)]="_app.name" required #appSolutionName="ngModel">
                        <div [hidden]="(appSolutionName.valid || appSolutionName.pristine) && _app.name"
                            class="alert alert-danger">
                            App name is required
                        </div>
                        <div [hidden]="!isAppNameDuplicate(_app)" class="alert alert-danger">
                            App name is duplicate
                        </div>
                        <div [hidden]="_app.name !== 'None'" class="alert alert-danger">
                            'None' cannot be used as the app name
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label [for]="'app-mgr-support-models-' + _app.id"
                        class="col-12 col-sm-5 col-md-4 col-form-label-sm required">
                        Support device models
                    </label>
                    <ng-container *ngIf="!_loadingDeviceModel; else templateLoadingDeviceModels">
                        <div [id]="'app-mgr-support-models-' + _app.id" class="col-12 col-sm-7 col-md-8">
                            <div class="d-flex align-items-center">
                                <span *ngIf="_app.isAllModelSelected; else templateModelCount">
                                    All models are selected
                                </span>
                                <ng-template #templateModelCount>
                                    {{ _app.modelList.length }} {{ _app.modelList.length > 1 ? 'models are ' : 'model
                                    is' }} selected
                                </ng-template>
                                <i class="fas fa-pen ml-2 icon-edit" data-toggle="collapse" data-target="#support-model-block"
                                    aria-expanded="false" aria-controls="support-model-block">
                                </i>
                                <div class="d-flex align-items-center ml-2">
                                    <input [id]="'ch-app-' + _app.id + '-all-models'" type="checkbox"
                                        class="cmn-checkbox" [checked]="_app.isAllModelSelected"
                                        (change)="selectAllDeviceModels(_app, $event.target.checked);" />
                                    <label [for]="'ch-app-' + _app.id + '-all-models'">
                                        <svg>
                                            <use xlink:href="#checkmark" />
                                        </svg>
                                    </label>
                                    <span class="ml-2">All models</span>
                                    <small *ngIf="_appModels.get(_app.id).size === 0" class="ml-2 red-1">
                                        * Must select one model
                                    </small>
                                </div>
                            </div>
                            <div class="collapse" id="support-model-block">
                                <div class="item-block">
                                    <div *ngFor="let model of _availableDeviceModels" class="item">
                                        <input [id]="'ch-app-' + _app.id + '-model-' + model" type="checkbox"
                                            class="cmn-checkbox" [checked]="_appModels.get(_app.id)?.get(model)"
                                            (change)="selectDeviceModel(_app, model, $event.target.checked);" />
                                        <label [for]="'ch-app-' + _app.id + '-model-' + model">
                                            <svg>
                                                <use xlink:href="#checkmark" />
                                            </svg>
                                        </label>
                                        <span class="ml-2">{{ model }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #templateLoadingDeviceModels>
                        <div class="loading loading-p0 ml-4">
                            <div class="loading-box"></div>
                        </div>
                    </ng-template>
                </div>
                <div class="form-group row">
                    <label [for]="'app-mgr-apkLink-' + _app.id"
                        class="col-12 col-sm-5 col-md-4 col-form-label-sm required">
                        APK link
                    </label>
                    <div class="col-12 col-sm-7 col-md-8">
                        <input [id]="'app-mgr-apkLink-' + _app.id" type="text" name="appLink"
                            class="form-control form-control-sm" [disabled]="_lockInfo || _disabled"
                            [(ngModel)]="_app.apkLink" required #appLink="ngModel">
                        <div [hidden]="(appLink.valid || appLink.pristine) && _app.apkLink" class="alert alert-danger">
                            App link is required
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label [for]="'app-mgr-appstart-' + _app.id" class="col-12 col-form-label-sm">
                        AppStart settings
                    </label>
                    <div [id]="'app-mgr-appstart-' + _app.id" class="col-12">
                        <div class="form-group row">
                            <label [for]="'app-mgr-package-' + _app.id"
                                class="offset-sm-1 col-12 col-sm-4 col-md-3 col-form-label-sm required">
                                &#9830; Package name
                            </label>
                            <div class="col-12 col-sm-7 col-md-8">
                                <input [id]="'app-mgr-package-' + _app.id" type="text" name="appAppstartPackageName"
                                    class="form-control form-control-sm" [disabled]="_lockInfo || _disabled"
                                    [(ngModel)]="_app.packageName" required #appAppstartPackageName="ngModel">
                                <div [hidden]="(appAppstartPackageName.valid || appAppstartPackageName.pristine) && _app.packageName"
                                    class="alert alert-danger">
                                    Package name is required
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label [for]="'app-mgr-class-' + _app.id"
                                class="offset-sm-1 col-12 col-sm-4 col-md-3 col-form-label-sm">
                                &#9830; Class name
                            </label>
                            <div class="col-12 col-sm-7 col-md-8">
                                <input [id]="'app-mgr-class-' + _app.id" type="text"
                                    class="form-control form-control-sm" [disabled]="_lockInfo || _disabled"
                                    name="appAppstartClass" #appAppstartClass="ngModel" [(ngModel)]="_app.className">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label [for]="'app-mgr-action-' + _app.id"
                                class="offset-sm-1 col-12 col-sm-4 col-md-3 col-form-label-sm">
                                &#9830; Action
                            </label>
                            <div class="col-12 col-sm-7 col-md-8">
                                <input [id]="'app-mgr-action-' + _app.id" type="text"
                                    class="form-control form-control-sm" [disabled]="_lockInfo || _disabled"
                                    name="appAppstartAction" #appAppstartAction="ngModel" [(ngModel)]="_app.action">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label [for]="'app-mgr-versionCode-' + _app.id"
                        class="col-12 col-sm-5 col-md-4 col-form-label-sm required">
                        Version code
                    </label>
                    <div class="col-12 col-sm-7 col-md-8">
                        <input [id]="'app-mgr-versionCode-' + _app.id" type="text" class="form-control form-control-sm"
                            [disabled]="_lockInfo || _disabled" name="appVersionCode" required #appVersionCode="ngModel"
                            [(ngModel)]="_app.versionCode">
                        <div [hidden]="(appVersionCode.valid || appVersionCode.pristine) && _app.versionCode"
                            class="alert alert-danger">
                            Version code is required
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label [for]="'app-mgr-versionName-' + _app.id"
                        class="col-12 col-sm-5 col-md-4 col-form-label-sm required">
                        Version name
                    </label>
                    <div class="col-12 col-sm-7 col-md-8">
                        <input [id]="'app-mgr-versionName-' + _app.id" type="text" class="form-control form-control-sm"
                            [disabled]="_lockInfo || _disabled" name="appVersionName" required #appVersionName="ngModel"
                            [(ngModel)]="_app.versionName">
                        <div [hidden]="(appVersionName.valid || appVersionName.pristine) && _app.versionName"
                            class="alert alert-danger">
                            Version name is required
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label [for]="'app-mgr-checksum-' + _app.id" class="col-12 col-sm-5 col-md-4 col-form-label-sm">
                        Checksum
                    </label>
                    <div class="col-12 col-sm-7 col-md-8">
                        <input [id]="'app-mgr-checksum-' + _app.id" type="text" class="form-control form-control-sm"
                            [disabled]="_lockInfo || _disabled" name="appChecksum" #appChecksum="ngModel"
                            [(ngModel)]="_app.checksum">
                    </div>
                </div>
                <div class="form-group row">
                    <label [for]="'app-mgr-checksumAlg-' + _app.id" class="col-12 col-sm-5 col-md-4 col-form-label-sm">
                        Checksum algorithm
                    </label>
                    <div class="col-12 col-sm-7 col-md-8">
                        <div [id]="'app-mgr-checksumAlg-' + _app.id" class="form-control-plaintext-sm text-break">
                            {{ _app?.checksumAlgorithm }}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label [for]="'app-mgr-downgrade-' + _app.id" class="col-12 col-sm-5 col-md-4 col-form-label-sm">
                        Allow downgrade
                    </label>
                    <div class="col-12 col-sm-7 col-md-8">
                        <div [id]="'app-mgr-downgrade-' + _app.id" class="row mt-1">
                            <div class="cmn-radio col-4">
                                <input [id]="'app-mgr-downgrade-yes-' + _app.id" type="radio"
                                    [attr.name]="'app-mgr-downgrade-option-' + _app.id" [checked]="_app.allowDowngrade"
                                    (change)="changeAllowDowngrade(_app, true)" />
                                <label [for]="'app-mgr-downgrade-yes-' + _app.id" class="radio"></label>
                                <span class="ml-2">YES</span>
                            </div>
                            <div class="cmn-radio col-4">
                                <input [id]="'app-mgr-downgrade-no-' + _app.id" type="radio"
                                    [attr.name]="'app-mgr-downgrade-option-' + _app.id" [checked]="!_app.allowDowngrade"
                                    (change)="changeAllowDowngrade(_app, false)" />
                                <label [for]="'app-mgr-downgrade-no-' + _app.id" class="radio"></label>
                                <span class="ml-2">NO</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label [for]="'app-mgr-splits-' + _app.id"
                        class="col-12 d-flex align-items-center col-form-label-sm">
                        Split APK(s)
                        <button type="button" class="btn btn-sm btn-action ml-auto"
                            (click)="addSplit()">
                            <i class="fas fa-plus mr-1"></i>
                            Add APK
                        </button>
                        <button type="button" class="btn btn-sm btn-action btn-action-offline ml-2"
                            [disabled]="!_split || !_isSplitNameConfirmed" (click)="removeSplit(_split)">
                            <i class="fas fa-trash mr-1"></i>
                            Remove APK
                        </button>
                    </label>
                    <div class="line"></div>
                    <div [id]="'app-mgr-splits-' + _app.id" class="col-12">
                        <ng-template [ngIf]="_split">
                            <div *ngIf="!_isSplitNameConfirmed; else templateSplitContent">
                                <div class="form-group row">
                                    <label [for]="'app-mgr-split-name-' + _split.id"
                                        class="col-12 col-sm-4 col-md-3 col-form-label-sm required">
                                        Name
                                    </label>
                                    <div class="col-12 col-sm-8 col-md-9">
                                        <input [id]="'app-mgr-split-name-' + _split.id" type="text"
                                            [name]="'appSplitName-' + _split.id" class="form-control form-control-sm"
                                            [disabled]="_lockInfo || _disabled" [(ngModel)]="_split.name" required>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <button type="button" class="btn btn-sm btn-action"
                                        (click)="removeSplit()">Cancel</button>
                                    <button type="button" class="btn btn-sm btn-action ml-2"
                                        (click)="confirmSplitName()">Confirm</button>
                                </div>
                            </div>
                            <ng-template #templateSplitContent>
                                <div class="form-group row">
                                    <label [for]="'app-mgr-split-name-' + _split.id"
                                        class="col-12 col-sm-4 col-md-3 col-form-label-sm">
                                        Select an APK
                                    </label>
                                    <div [id]="'app-mgr-split-name-' + _split.id" class="col-12 col-sm-8 col-md-9">
                                        <button id="dropdownAppSplitName" type="button"
                                            class="btn btn-sm btn-action btn-light dropdown-toggle"
                                            data-toggle="dropdown">
                                            {{ _split?.name }}
                                        </button>
                                        <ul class="dropdown-menu option-list w-100" data-backdrop="static"
                                            data-keyboard="false" aria-labelledby="dropdownAppSplitName">
                                            <li *ngFor="let splitItem of _app.splits"
                                                [class.active]="_split?.id === splitItem.id"
                                                [class.error]="!splitItem.link" (click)="selectSplit(splitItem)">
                                                {{ splitItem.name }}
                                                <i *ngIf="!splitItem.link"
                                                    class="fas fa-exclamation-triangle ml-2 red-1"></i>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div *ngIf="_split">
                                    <div class="form-group row">
                                        <label for="app-mgr-split-content"
                                            class="col-12 col-form-label-sm">Settings</label>
                                        <div id="app-mgr-split-content" class="col-12">
                                            <div class="form-group row">
                                                <label [for]="'app-mgr-split-link-' + _split.id"
                                                    class="offset-sm-1 col-12 col-sm-4 col-md-3 col-form-label-sm required">
                                                    &#9830; Link
                                                </label>
                                                <div class="col-12 col-sm-7 col-md-8">
                                                    <input [id]="'app-mgr-split-link-' + _split.id" type="text"
                                                        [name]="'appSplitLink-' + _split.id"
                                                        class="form-control form-control-sm"
                                                        [class.error]="!_split.link" [disabled]="_lockInfo || _disabled"
                                                        [(ngModel)]="_split.link" required>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label [for]="'app-mgr-split-checksum-' + _split.id"
                                                    class="offset-sm-1 col-12 col-sm-4 col-md-3 col-form-label-sm">
                                                    &#9830; Checksum
                                                </label>
                                                <div class="col-12 col-sm-7 col-md-8">
                                                    <input [id]="'app-mgr-split-checksum-' + _split.id" type="text"
                                                        [name]="'appSplitChecksum-' + _split.id"
                                                        class="form-control form-control-sm"
                                                        [disabled]="_lockInfo || _disabled"
                                                        [(ngModel)]="_split.checksum">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label [for]="'app-mgr-split-checksumAlg-' + _split.id"
                                                    class="offset-sm-1 col-12 col-sm-4 col-md-3 col-form-label-sm">
                                                    &#9830; Checksum algorithm
                                                </label>
                                                <div class="col-12 col-sm-7 col-md-8">
                                                    <input [id]="'app-mgr-split-checksumAlg-' + _split.id" type="text"
                                                        [name]="'appSplitChecksumAlg-' + _split.id"
                                                        class="form-control form-control-sm"
                                                        [disabled]="_lockInfo || _disabled"
                                                        [(ngModel)]="_split.checksumAlgorithm">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label [for]="'app-mgr-split-mime-' + _split.id"
                                                    class="offset-sm-1 col-12 col-sm-4 col-md-3 col-form-label-sm">
                                                    &#9830; Mime type
                                                </label>
                                                <div class="col-12 col-sm-7 col-md-8">
                                                    <input [id]="'app-mgr-split-mime-' + _split.id" type="text"
                                                        [name]="'appSplitMime-' + _split.id"
                                                        class="form-control form-control-sm"
                                                        [disabled]="_lockInfo || _disabled"
                                                        [(ngModel)]="_split.mimeType">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-template>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>