import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DevFuncActionDirective } from './dev-func-action.directive';
import { DevFuncActionService } from './dev-func-action.service';

import { TranslateModule } from '../../../../translate/translate.module';
import { CustomPipeModule } from '../../../../lib/pipes/custom-pipe.module';
import { UIElementModule } from '../../../../uiElement/uiElement.module';

import { IssueActionSubFormComponent } from '../../troubleshoot/action/issue-action-subform.component';
import { FirmwareActionSubFormComponent } from '../../firmware/action/firmware-action-subform.component';
import { BasicConfigActionSubFormComponent } from '../../config/action/basic-config-action-subform.component';
import { NetConfigActionSubFormComponent } from '../../config/action/net-config-action-subform.component';
import { AlertActionSubFormComponent } from '../../alert/action/alert-action-subform.component';
import { AlertElementModule } from '../../../setting/alert/alertConfig/comp/alert-element.module';
import { MultiBasicConfigActionSubFormComponent } from '../../config-m/action/m-basic-config-action-subform.component';
import { SecurityActionSubFormComponent } from '../../security/action/security-action-subform.component';
import { OTPActionSubFormComponent } from '../../otp/action/otp-action-subform.component';
import { ScriptActionSubFormComponent } from '../../script/action/script-action-subform.component';
import { RebootActionSubFormComponent } from '../../reboot/action/reboot-action-subform.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        CustomPipeModule,
        UIElementModule,
        AlertElementModule
    ],
    providers: [
        DevFuncActionService
    ],
    declarations: [
        DevFuncActionDirective,
        IssueActionSubFormComponent,
        FirmwareActionSubFormComponent,
        BasicConfigActionSubFormComponent,
        MultiBasicConfigActionSubFormComponent,
        NetConfigActionSubFormComponent,
        AlertActionSubFormComponent,
        SecurityActionSubFormComponent,
        OTPActionSubFormComponent,
        ScriptActionSubFormComponent,
        RebootActionSubFormComponent
    ],
    exports: [
        DevFuncActionDirective
    ]
})
export class DevFuncActionModule {
}