<div class="activity-content-block">
    <h2 class="my-1">
        <i class="fas fa-arrow-alt-circle-left icon-arrow" [routerLink]="['/app/event/activity']"></i>
        Activity detail
    </h2>
    <div class="ctrl-block">
        <button id="id_event_devAct_detail_do_cancel" type="button" class="btn btn-sm btn-action btn-light ml-auto"
            [disabled]="_isLoading || _exporting || _isCancelling || !_allowCancel || _activity?.requestForCancel"
            (click)="cancelTask()">
            <i class="fas fa-ban mr-1"></i>
            <span *ngIf="_isCancelling; else templateWaitCancel" class="text-loading"
                [attr.data-text]="'Cancelling...'">Cancelling...</span>
            <ng-template #templateWaitCancel>Cancel</ng-template>
        </button>
        <button id="id_event_devAct_detail_do_refresh" type="button" class="btn btn-sm btn-action btn-light ml-2"
            [disabled]="_isLoading || _exporting || _refreshCounter > 0" (click)="refreshTask()">
            <i class="fas fa-sync mr-1"></i>
            Refresh
            <span *ngIf="_refreshCounter > 0" class="ml-1"> - {{ _refreshCounter }} s</span>
        </button>
        <button id="id_event_devAct_detail_do_export" type="button" class="btn btn-sm btn-action btn-light ml-2"
            [disabled]="_isLoading || _exporting || _bNoDevice" (click)="exportTask()">
            <i class="fas fa-file-export mr-1"></i>
            Export
            <i *ngIf="_exporting" class="ml-2 fas fa-spinner rotate"></i>
        </button>
        <a #reportLink class="d-none"></a>
    </div>
    <div *ngIf="!_isLoading; else templateLoading" class="task-content-block">
        <div class="container-fluid">
            <!-- subject -->
            <div class="form-group row">
                <label for="task-name" class="col-xl-2 col-md-3 col-12 col-form-label col-form-label-sm">
                    Activity
                </label>
                <div class="col-xl-10 col-md-9 col-12">
                    <div id="task-name" class="form-control-plaintext">
                        {{ _activity?.subject }}
                        (<a *ngIf="_activity.taskList.length > 0" [routerLink]="['/app/device/devices']"
                            [queryParams]="{'devActivity': _activity?.activityID}"
                            class="mx-1">{{_activity.taskList.length}} device{{_activity.taskList.length > 1 ? 's' :
                            ''}}
                        </a>)
                        <span *ngIf="_activity?.isScheduled" class="far fa-calendar-alt"></span>
                    </div>
                </div>
            </div>
            <!-- status -->
            <div class="form-group row">
                <label for="task-status" class="col-xl-2 col-md-3 col-12 col-form-label col-form-label-sm">
                    Activity status
                </label>
                <div class="col-xl-10 col-md-9 col-12">
                    <span id="task-status" class="ticket-status" [ngClass]="'status-' + _activity?.statusCode">
                        {{ _activity?.statusCode | capitalFirstWord }}
                    </span>
                    <span *ngIf="_activity?.requestForCancel" class="ml-2 red-1">( User requested cancel on
                        {{ _activity?.requestForCancelDateTime | date: 'yyyy-MM-dd HH:mm:ss' }} )</span>
                </div>
            </div>
            <!-- id -->
            <div class="form-group row">
                <label for="task-id" class="col-xl-2 col-md-3 col-12 col-form-label col-form-label-sm">
                    Activity ID
                </label>
                <div class="col-xl-10 col-md-9 col-12">
                    <div id="task-id" class="form-control-plaintext">
                        {{ _activity?.activityID }}
                    </div>
                </div>
            </div>
            <!-- issue date -->
            <div class="form-group row">
                <label for="task-issueDate" class="col-xl-2 col-md-3 col-12 col-form-label col-form-label-sm">
                    Issue date
                </label>
                <div class="col-xl-10 col-md-9 col-12">
                    <div id="task-issueDate" class="form-control-plaintext">
                        {{ _activity?.issueDateTime | date:'yyyy-MM-dd HH:mm:ss' }}
                    </div>
                </div>
            </div>
            <!-- schedule date -->
            <div *ngIf="_activity?.isScheduled" class="form-group row">
                <label for="task-scheduleDate" class="col-xl-2 col-md-3 col-12 col-form-label col-form-label-sm">
                    Schedule date
                </label>
                <div class="col-xl-10 col-md-9 col-12">
                    <div id="task-scheduleDate" class="form-control-plaintext">
                        {{ _activity?.scheduledDateTime | date: 'yyyy-MM-dd HH:mm:ss' }}
                    </div>
                </div>
            </div>
            <!-- start date -->
            <div class="form-group row">
                <label for="task-startDate" class="col-xl-2 col-md-3 col-12 col-form-label col-form-label-sm">
                    Start date
                </label>
                <div class="col-xl-10 col-md-9 col-12">
                    <div *ngIf="_activity?.startDateTime; else templateNoStartDateTime" id="task-startDate"
                        class="form-control-plaintext">
                        {{ _activity?.startDateTime | date:'yyyy-MM-dd HH:mm:ss' }}
                    </div>
                    <ng-template #templateNoStartDateTime>---</ng-template>
                </div>
            </div>
            <!-- finish date -->
            <div class="form-group row">
                <label for="task-finishDate" class="col-xl-2 col-md-3 col-12 col-form-label col-form-label-sm">
                    Finish date
                </label>
                <div class="col-xl-10 col-md-9 col-12">
                    <div *ngIf="_activity?.finishedDateTime; else templateNoFinishedDateTime" id="task-finishDate"
                        class="form-control-plaintext">
                        {{ _activity?.finishedDateTime | date:'yyyy-MM-dd HH:mm:ss' }}
                    </div>
                    <ng-template #templateNoFinishedDateTime>---</ng-template>
                </div>
            </div>
            <!-- common settings -->
            <div *ngIf="_activity.commonActivityInputList" class="form-group row">
                <label for="task-common-config" class="col-xl-2 col-md-3 col-12 col-form-label col-form-label-sm">
                    Configurations
                </label>
                <div id="task-common-config" class="col-xl-10 col-md-9 col-12">
                    <ng-template ngFor let-ai [ngForOf]="_activity.commonActivityInputList">
                        <div *ngIf="ai.resourceData && ai.resourceData.settings" class="list-group my-1">
                            <div class="list-group-item list-group-header black">
                                <div class="row">
                                    <div class="col-2 title text-break">
                                        Setting
                                    </div>
                                    <div class="col-5 title text-break">
                                        Last value
                                    </div>
                                    <div class="col-5 title text-break">
                                        New value
                                    </div>
                                </div>
                            </div>
                            <div class="list-group-content list-group-hoverless">
                                <ng-template ngFor let-config [ngForOf]="ai.resourceData.settings">
                                    <ng-template [ngIf]="!config.hidden">
                                        <div class="list-group-item">
                                            <div class="row">
                                                <div class="col-2 d-flex align-items-center text-break">
                                                    {{ (config.langKey | translate | capitalFirstWord) || (config.name |
                                                    capitalFirstWord) }}
                                                </div>
                                                <div class="col-5 text-break">
                                                    <div [ngSwitch]="config.name">
                                                        <ng-template [ngSwitchCase]="constantSvc.DEVKEY_APPSTART">
                                                            <na-appstart-preview [data]="config.origin"
                                                                [compare]="config.value">
                                                            </na-appstart-preview>
                                                        </ng-template>
                                                        <div *ngSwitchDefault>
                                                            <span>{{ expandObject(config.origin) }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-5 text-break">
                                                    <div [ngSwitch]="config.name">
                                                        <ng-template [ngSwitchCase]="constantSvc.DEVKEY_APPSTART">
                                                            <na-appstart-preview [data]="config.value"
                                                                [compare]="config.origin">
                                                            </na-appstart-preview>
                                                        </ng-template>
                                                        <div *ngSwitchDefault>
                                                            <span>{{ expandObject(config.value) }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-template>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="m-1">
            <ul class="nav nav-tabs" role="tablist">
                <li *ngFor="let tab of _deviceStatusList" class="nav-item">
                    <div [id]="'act-tab-' + tab.name" class="nav-link" [class.active]="tab.active"
                        [attr.href]="'#act-tab-content-' + tab.name" data-toggle="tab" role="tab"
                        [attr.aria-controls]="'act-tab-' + tab.name">
                        <div class="ball mr-2" [ngClass]="'status-' + tab.name"></div>
                        {{ tab.name | capitalFirstWord }}
                        (
                        <a *ngIf="tab.deviceInfoList.length > 0; else templateNoDevice" class="mx-1"
                            [routerLink]="['/app/device/devices']"
                            [queryParams]="{'devActivity': _activity?.activityID, 'devActivityState': tab.name}">
                            {{ tab.deviceInfoList.length }}
                        </a>
                        <ng-template #templateNoDevice>{{ tab.deviceInfoList.length }}</ng-template>
                        )
                    </div>
                </li>
            </ul>
            <div *ngIf="!_bNoDevice; else templateAllDeviceUnpaired" class="tab-content">
                <div *ngFor="let tab of _deviceStatusList" [id]="'act-tab-content-' + tab.name" class="tab-pane fade"
                    [ngClass]="tab.active ? 'show active' : ''" role="tabpanel"
                    [attr.aria-labelledby]="'act-tab-' + tab.name">
                    <div class="list-group">
                        <div class="list-group-item list-group-header black">
                            <div class="row">
                                <div class="col title">
                                    Device name
                                </div>
                                <div class="col title">
                                    MAC
                                </div>
                                <div *ngIf="tab.name === 'fail'" class="col title">
                                    Error
                                </div>
                            </div>
                        </div>
                        <div class="list-group-content list-group-hoverless list-group-content-nr-2">
                            <div *ngFor="let ds of tab.deviceInfoList" class="list-group-item">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <div *ngIf="ds.device; else templateDeviceNotExist">
                                            <i *ngIf="ds.inputData && ds.inputData.resourceData"
                                                class="fas fa-cog icon-config" data-toggle="modal"
                                                data-target="#devConfigModalHost" (click)="viewDeviceConfig(ds)"></i>
                                            <a [routerLink]="['/app/device/devices', ds.device?.virtualId]">
                                                <span class="ml-1" data-toggle="tooltip"
                                                    title="{{ ds.virtualDeviceID }}">
                                                    {{ ds.device?.virtualName }}
                                                </span>
                                            </a>
                                        </div>
                                        <ng-template #templateDeviceNotExist>
                                            Unknown ({{ ds.virtualDeviceID }})
                                        </ng-template>
                                    </div>
                                    <div class="col">
                                        <span
                                            *ngIf="ds.device?.currentSettings[constantSvc.DEVKEY_NET_WIFI_CONNECTED] && !ds.device?.currentSettings[constantSvc.DEVKEY_NET_LAN_CONNECTED]; else templateMacNoWifi">
                                            {{ ds.device?.currentSettings[constantSvc.DEVKEY_NET_WIFI_MAC] }}
                                            <span class="badge badge-info">
                                                <i class="fas fa-wifi"></i>
                                            </span>
                                        </span>
                                        <ng-template #templateMacNoWifi>
                                            {{ ds.device?.currentSettings[constantSvc.DEVKEY_NET_LAN_MAC] }}
                                        </ng-template>
                                    </div>
                                    <div *ngIf="tab.name === 'fail'" class="col red-1">{{ ds.errorMessage }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #templateAllDeviceUnpaired>
                <div class="red-1 mt-2 text-center">Devices under this activity may be unpaired.</div>
            </ng-template>
        </div>
    </div>
    <ng-template #templateLoading>
        <div class="loading">
            <div class="loading-box"></div>
        </div>
    </ng-template>
    <div *ngIf="_deviceConfig && _deviceConfig.inputData && _deviceConfig.inputData.resourceData"
        id="devConfigModalHost" class="modal fade" data-backdrop="static" data-keyboard="false" role="dialog">
        <div [id]="_dialogName" class='modal-dialog config-dlg' role="document">
            <div class='modal-content'>
                <div class='modal-header'>
                    <span class="modal-title">
                        "{{ _deviceConfig.device?.virtualName || _deviceConfig.virtualDeviceID }}" configurations
                    </span>
                    <button #dlgClose type="button" class="close" data-dismiss="modal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="list-group mt-2">
                        <div class="list-group-item list-group-header black">
                            <div class="row">
                                <div class="col-2 title text-break">
                                    Setting
                                </div>
                                <div class="col-5 title text-break">
                                    Last value
                                </div>
                                <div class="col-5 title text-break">
                                    New value
                                </div>
                            </div>
                        </div>
                        <div class="list-group-content list-group-hoverless">
                            <ng-template ngFor let-config [ngForOf]="_deviceConfig.inputData.resourceData.settings">
                                <ng-template [ngIf]="!config.hidden">
                                    <div class="list-group-item">
                                        <div class="row">
                                            <div class="col-2 d-flex align-items-center text-break">
                                                {{ (config.langKey | translate | capitalFirstWord) || (config.name |
                                                capitalFirstWord) }}
                                            </div>
                                            <div class="col-5 text-break">
                                                <div [ngSwitch]="config.name">
                                                    <ng-template [ngSwitchCase]="constantSvc.DEVKEY_APPSTART">
                                                        <na-appstart-preview [data]="config.origin"
                                                            [compare]="config.value">
                                                        </na-appstart-preview>
                                                    </ng-template>
                                                    <ng-template [ngSwitchCase]="constantSvc.DEVKEY_FAKE_MAINTENANCE">
                                                        <na-mtPlayback-preview [data]="config.origin">
                                                        </na-mtPlayback-preview>
                                                    </ng-template>
                                                    <ng-template
                                                        [ngSwitchCase]="constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENOFF">
                                                        <na-screenOff-preview [data]="config.origin">
                                                        </na-screenOff-preview>
                                                    </ng-template>
                                                    <div *ngSwitchDefault>
                                                        <span>{{ expandObject(config.origin) }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-5 text-break">
                                                <div [ngSwitch]="config.name">
                                                    <ng-template [ngSwitchCase]="constantSvc.DEVKEY_APPSTART">
                                                        <na-appstart-preview [data]="config.value"
                                                            [compare]="config.origin">
                                                        </na-appstart-preview>
                                                    </ng-template>
                                                    <ng-template [ngSwitchCase]="constantSvc.DEVKEY_FAKE_MAINTENANCE">
                                                        <na-mtPlayback-preview [data]="config.value">
                                                        </na-mtPlayback-preview>
                                                    </ng-template>
                                                    <ng-template
                                                        [ngSwitchCase]="constantSvc.DEVKEY_FAKE_LOCKSCREEN_SCREENOFF">
                                                        <na-screenOff-preview [data]="config.value">
                                                        </na-screenOff-preview>
                                                    </ng-template>
                                                    <div *ngSwitchDefault>
                                                        <span>{{ expandObject(config.value) }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-template>
                        </div>
                    </div>
                    <div class="row control-block d-flex justify-content-center">
                        <button type="button" class="btn btn-action btn-light" data-dismiss="modal"
                            (click)="clearDeviceConfig()">
                            <span class="fas fa-times-circle icon-left"></span>
                            <span>Close</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>