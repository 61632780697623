import { Component } from '@angular/core';

import { BaseConfigFormComponent } from '../base/base-config-form.component';
import { DLG_NAME_REBOOT, ConfigDialogPage, GeneralScheduleInfo } from '../base/base-config-data';
import { LicenseScopeType } from '../../license/license.data';
import { HelperLib } from '../../../lib/common/helper.lib';

@Component({
    templateUrl: '../base/base-config-form.component.html',
    styleUrls: ['../base/base-config-form.component.css', './reboot-form.component.css']
})
export class RebootFormComponent extends BaseConfigFormComponent {
    ngOnInit(): void {
        this._dialogName = DLG_NAME_REBOOT;
        this._licenseScopeType = LicenseScopeType.taskConfig;

        super.ngOnInit();
    }

    protected goNext(fromPage?: ConfigDialogPage): void {
        if (this._page === ConfigDialogPage.checking) {
            this._actionData.schedule = this._actionData.schedule || new GeneralScheduleInfo();
        }

        super.goNext();
    }

    protected submit(): void {
        super.submit();

        let scheduleDate: Date;
        if (this._actionData.schedule) {
            const schedule: GeneralScheduleInfo = this._actionData.schedule as GeneralScheduleInfo;
            if (schedule && schedule.enabled) {
                scheduleDate = schedule.toDate();
            }
        }

        this.devSvc.batchReboot(this._bSpecific ? this._legalDevices : this._legalDevices.filter(d => d.isSelect), scheduleDate).subscribe(res => {
            this._page++;
            if (res.isFault()) {
                this._errorMessage = HelperLib.getErrorMessage(res);
            }

            if (this.dialogCompleteHandler) {
                this.dialogCompleteHandler(res);
            }
        });

    }
}