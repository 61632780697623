<div *ngIf="_hasLicense; else templateNoLicense">
  <div *ngIf="_licenseInfo?.iCareLicense" class="form-group row">
    <label class="col-xl-4 col-5 col-form-label col-form-label-sm">
      {{ (_licenseInfo.iCareLicense.currentActivatedShowName || _licenseInfo.iCareLicense.currentActivated) |
      mergeContentPipe }}
    </label>
    <div class="col-xl-8 col-7">
      <div *ngIf="_licenseInfo.iCareLicense.currentActivated && _licenseInfo.iCareLicense.currentActivated.length > 0">
      </div>
      <div class="form-control-plaintext">
        <i class="far fa-clock"></i>
        {{ _licenseInfo.iCareLicense?.expiryDate | date:'yyyy-MM-dd Z' }}
        <span *ngIf="_licenseInfo.iCareLicense.isExpired" class="warning-block">Expired</span>
      </div>
    </div>
  </div>
  <div *ngFor="let item of _licenseInfo.other | keyvalue" class="form-group row">
    <label class="col-xl-4 col-5 col-form-label col-form-label-sm">
      {{ (item.value.currentActivatedShowName || item.value.currentActivated) | mergeContentPipe }}
    </label>
    <div class="col-xl-8 col-7">
      <div class="form-control-plaintext">
        <i class="far fa-clock"></i> {{ item.value.expiryDate | date:'yyyy-MM-dd Z' }}
        <span *ngIf="item.value.isExpired" class="warning-block">Expired</span>
      </div>
    </div>
  </div>
</div>
<ng-template #templateNoLicense>
  <div class="warning">No allocated license. Install one to enable more features.</div>
</ng-template>