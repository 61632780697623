<div [ngSwitch]="_previewMode">
    <ng-template [ngSwitchCase]="_enumPreviewMode.diff">
        <div>
            <div *ngIf="_data.uri !== _compare.uri">
                <strong>Content url :</strong>
                <span class="ml-2 text-break">{{ _data.uri }}</span>
            </div>
            <div *ngIf="_data.data !== _compare.data">
                <strong>Content url :</strong>
                <span class="ml-2 text-break">{{ _data.data }}</span>
            </div>
            <!-- <div *ngIf="_data.packageName !== _compare.packageName">
                <strong>Package name :</strong>
                <span class="ml-2 text-break">{{ _data.packageName }}</span>
            </div>
            <div *ngIf="_data.className !== _compare.className">
                <strong>Class name :</strong>
                <span class="ml-2 text-break">{{ _data.className }}</span>
            </div>
            <div *ngIf="_data.action !== _compare.action">
                <strong>Action :</strong>
                <span class="ml-2 text-break">{{ _data.action }}</span>
            </div> -->
            <!-- extra settings -->
            <div *ngIf="_diffExtraList?.length > 0">
                <strong>Extras :</strong>
                <div class="ml-2">
                    <div *ngFor="let extra of _diffExtraList">
                        <span class="text-info">{{ extra.displayName }} :</span>
                        <span class="ml-2">{{ extra.value }}</span>
                    </div>
                </div>
            </div>
            <!-- overlay -->
            <div *ngIf="_data.overlay">
                <strong>QR code overlay</strong>
                <div class="ml-2">
                    <na-overlay-qrcode-preview [data]="_data.overlay">
                    </na-overlay-qrcode-preview>
                </div>
            </div>
            <!-- screen saver -->
            <div *ngIf="_data.screensaver">
                <strong>Screen saver</strong>
                <div class="ml-2">
                    <na-appstart-screensaver-preview [data]="_data.screensaver"></na-appstart-screensaver-preview>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template [ngSwitchCase]="_enumPreviewMode.keyPair">
        <!-- appstart -->
        <div
            *ngIf="_keyPair[constantSvc.DEVKEY_APPSTART_CONTENTURL] && _keyPair[constantSvc.DEVKEY_APPSTART_CONTENTURL].origin !== _keyPair[constantSvc.DEVKEY_APPSTART_CONTENTURL].value">
            <strong>Content url</strong>
            <span class="ml-2 text-break">{{ _keyPair[constantSvc.DEVKEY_APPSTART_CONTENTURL][_target] }}</span>
        </div>
        <div *ngIf="_showExtra">
            <strong>Extras</strong>
            <div class="ml-2">
                <ng-template ngFor let-extra [ngForOf]="_appstartExtraList">
                    <div *ngIf="_keyPair[extra.property].origin !== _keyPair[extra.property].value">
                        <span class="text-info">{{ extra.displayName }}</span>
                        <span class="ml-2">{{ _keyPair[extra.property][_target] }}</span>
                    </div>
                </ng-template>
            </div>
        </div>
        <!-- overlay -->
        <div
            *ngIf="_keyPair[constantSvc.DEVKEY_APPSTART_OVERLAY] && _keyPair[constantSvc.DEVKEY_APPSTART_OVERLAY][_target]">
            <strong>QR code overlay</strong>
            <div class="ml-2">
                <na-overlay-qrcode-preview [data]="_keyPair[constantSvc.DEVKEY_APPSTART_OVERLAY][_target]">
                </na-overlay-qrcode-preview>
            </div>
        </div>
        <!-- screen saver -->
        <div
            *ngIf="_keyPair[constantSvc.DEVKEY_APPSTART_SCREENSAVER] && _keyPair[constantSvc.DEVKEY_APPSTART_SCREENSAVER][_target]">
            <strong>Screen saver</strong>
            <div class="ml-2">
                <na-appstart-screensaver-preview [data]="_keyPair[constantSvc.DEVKEY_APPSTART_SCREENSAVER][_target]">
                </na-appstart-screensaver-preview>
            </div>
        </div>
    </ng-template>
    <div *ngSwitchDefault>
    </div>
</div>