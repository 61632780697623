<div class="camera">
    <label for="config-camera" class="title">
        Camera
        <i *ngIf="_lockInfo" class="fas fa-lock ml-2 red-1" data-toggle="tooltip"
            [title]="'Lock by policy <<' + _lockInfo.policyName + '>>'"></i>
    </label>
    <div *ngIf="_unsupportReason" class="warning-block mb-1">{{ _unsupportReason }}</div>
    <div id="config-camera" [ngClass]="_showBorder ? 'showBorder' : ''">
        <div [ngClass]="_lockInfo || _disabled || _unsupportReason || !_valid ? 'inactive' : ''">
            <div class="form-group row align-items-center">
                <label for="col-camera-autoframingSwitch" class="col-12 col-md-4 col-form-label col-form-label-sm">
                    Auto framing
                </label>
                <div id="col-camera-autoframingSwitch" class="col-12 col-md-8">
                    <div class='switch-container'>
                        <input id="cmn-toggle-autoframingSwitch" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat"
                            type="checkbox" [disabled]="_lockInfo || _disabled || _unsupportReason"
                            [checked]="_camera.isAutoframingOn" (change)="enableAutoframing($event.target.checked)">
                        <label for="cmn-toggle-autoframingSwitch">
                            <span class="sr-only">Enable autoframing</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-group row align-items-center">
                <label for="col-camera-autoframeMode" class="col-12 col-md-4 col-form-label col-form-label-sm">
                    Auto framing mode
                </label>
                <div id="col-camera-autoframeMode" class="col-12 col-md-8">
                    <div class="position-relative">
                        <button id="btn-autoframeMode" type="button"
                            class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown"
                            [disabled]="!_camera.isAutoframingOn">
                            {{ _camera.autoframingMode }}
                        </button>
                        <ul class="dropdown-menu option-list w-100" data-backdrop="static" data-keyboard="false"
                            aria-labelledby="btn-autoframeMode">
                            <li *ngFor="let mode of AUTO_FRAMING_LIST" [class.active]="_camera.autoframingMode === mode"
                                (click)="selectAutoframingMode(mode)">
                                {{ mode }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="form-group row align-items-center">
                <label for="col-camera-fov" class="col-12 col-md-4 col-form-label col-form-label-sm">
                    FOV
                </label>
                <div class="col-12 col-md-8">
                    <div class="d-flex align-items-center">
                        <input id="col-camera-fov" type="range" name="fov" min="1" max="117" step="1"
                            [disabled]="_camera.isAutoframingOn" [ngModel]="_camera.fov"
                            (ngModelChange)="changeFOV($event)" />
                        <span class="ml-2">{{ _camera.fov }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>