import { Component } from "@angular/core";
import { DatePipe } from "@angular/common";

import { DevFuncActionInterface } from "../../base/action/dev-func-action.interface";
import { GeneralScheduleInfo } from "../../base/base-config-data";
import { DeviceInfo } from "../../../../../app/content/device/data/device-info";
import { GMT_OFFSET_LIST } from "../../../../../app/lib/common/helper.lib";

@Component({
    templateUrl: './reboot-action-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css']
})
export class RebootActionSubFormComponent implements DevFuncActionInterface {
    private readonly GMTLIST: number[] = GMT_OFFSET_LIST;
    _schedule: GeneralScheduleInfo = new GeneralScheduleInfo();
    _devices: DeviceInfo[] = [];

    private _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        if (this._actionData) {
            this._schedule = this._actionData.schedule;
            this._schedule.date = this._schedule.date || this.datePipe.transform(new Date(), 'yyyy-MM-dd');
            this._devices = this._actionData.deviceList;

            setTimeout(() => {
                this.updateActionStatus();
            }, 0);
        }
    }

    actionCompleteHandler?: (result: any) => void;
    actionStatusUpdateHandler?: (isValid: boolean) => void;
    actionCommunicateHandler?: (data: any) => void;

    constructor(private datePipe: DatePipe) { }

    enableSchedule(enabled: boolean): void {
        this._schedule.enabled = enabled;
        this.updateActionStatus();
    }

    changeScheduleDate(date: string): void {
        this._schedule.date = date;
        this.updateActionStatus();
    }

    changeScheduleTime(time: string): void {
        this._schedule.time = time;
        this.updateActionStatus();
    }

    changeScheduleTimeOffset(offset: number): void {
        if (this._schedule.offset !== offset) {
            this._schedule.offset = offset;
            this.updateActionStatus();
        }
    }

    private updateActionStatus(): void {
        let isValid: boolean = true;
        if (isValid && this._schedule.enabled && !this._schedule.date) {
            isValid = false;
        }

        this.actionStatusUpdateHandler(isValid);
    }
}