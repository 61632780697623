import { Directive, EventEmitter, Injectable, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, concatMap, catchError } from "rxjs/operators";

import { NAService } from './API/na.service';
import { AccountService } from './entry/account.service';
import { AppCacheData, AppMode, AppPreviewCacheData } from "./app.data";
import { CustomizationItemInfo } from "./content/admin/customization/customization.data";
import { AppConfigService } from "./app.config";
import { Logger } from "./lib/common/logger";
import { environment } from "environments/environment";

@Directive()
@Injectable()
export class AppService {
    readonly FILE_SERVER_NAME: string = AppConfigService.configs.server.fileServer.baseUrl;
    readonly FILE_SERVER_PROTOCOL: string = AppConfigService.configs.server.fileServer.protocol;
    readonly RESOURCE_BASE_PATH: string = 'assets/resource/';
    private readonly TAG: string = 'appSvc';

    private _defaultBgUrl: string = `${this.RESOURCE_BASE_PATH}bg.jpg`;
    private _defaultLogoDarkUrl: string = `${this.RESOURCE_BASE_PATH}logo_iadea.svg`;
    private _defaultLogoLightUrl: string = `${this.RESOURCE_BASE_PATH}logo_iadea_light.svg`;

    private _cache: AppCacheData;
    get cache(): AppCacheData {
        return this._cache;
    }
    private _hostname: string = '';
    private _appMode: AppMode = AppMode.normal;
    get appMode(): AppMode {
        return this._appMode;
    }

    private _useEnterpriseLayout: boolean = true;
    get useEnterpriseLayout(): boolean {
        return this._useEnterpriseLayout;
    }
    set useEnterpriseLayout(v: boolean) {
        if (this._useEnterpriseLayout !== v) {
            this._useEnterpriseLayout = v;
            this.reset();
        }
    }

    bgUrl: string;
    logoDarkUrl: string;
    logoLightUrl: string;

    @Output() onAppModeChanged = new EventEmitter<{ mode: AppMode, data: AppCacheData }>();

    constructor(
        private router: Router,
        private naSvc: NAService,
        private accountSvc: AccountService
    ) {
        this.accountSvc.loginChanged.subscribe((isLogin: boolean) => {
            if (isLogin) {
                this.reset();
            }
        });

        this.reset();
    }

    private reset(): void {
        this._hostname = window.location.hostname;
        if (this._hostname) {
            // [brand].[env].care.oniadea.com. ex: iadea-enterprise.stage.care.oniadea.com
            let match: RegExpMatchArray = this._hostname.match(/(?<brand>[^\.]+).(?<env>[^\.]+).care.(?<domain>.+)/);
            if (!match) {
                // care.[env].[brand].oniadea.com. ex: care.stage.iadea-enterprise.oniadea.com
                match = this._hostname.match(/care.(?<env>[^\.]+).(?<brand>[^\.]+).(?<domain>.+)/);
            }

            Logger.logInfo(this.TAG, 'reset', `hostname regex result:`, match);

            // if it is IAdea default domain, use basic login layout and see if to switch to enterprise layout by the login account.
            this._useEnterpriseLayout = true;
            if (match?.groups) {
                this._useEnterpriseLayout = false;
            }
            else if (environment.enterprise?.domains) {
                Logger.logInfo(this.TAG, 'reset', 'use designated enterprise domains: ', environment.enterprise.domains);
                if (environment.enterprise.domains.find((domain) => domain === this._hostname)) {
                    this._useEnterpriseLayout = true;
                }
                else {
                    this._useEnterpriseLayout = false;
                }
            }
        }

        if (!this._useEnterpriseLayout) {
            // check if it is forced to use enterprise from config
            this._useEnterpriseLayout = AppConfigService.configs.common.enterprise.useEnterpriseImage;
        }

        if (!this._useEnterpriseLayout) {
            // check if it is an enterprise account if user is logged-in.
            this._useEnterpriseLayout = this.accountSvc.isLoggedIn && (this.accountSvc.isEnterprise() || this.accountSvc.isEnterpriseSSO()) ? true : this._useEnterpriseLayout;
        }

        Logger.logInfo(this.TAG, 'reset', `hostname: ${this._hostname}, useEnterpriseLayout: ${this._useEnterpriseLayout}`);

        // The logo & bg should comes from the server if the login account is an enterprise account.
        this.bgUrl = this._useEnterpriseLayout ? this.generateEnterpriseUrl('background-light') : this._defaultBgUrl;
        this.logoDarkUrl = this._useEnterpriseLayout ? this.generateEnterpriseUrl('logo-dark') : this._defaultLogoDarkUrl;
        this.logoLightUrl = this._useEnterpriseLayout ? this.generateEnterpriseUrl('logo-light') : this._defaultLogoLightUrl;

        Logger.logInfo(this.TAG, 'reset', `Bg: ${this.bgUrl}; LogoDark: ${this.logoDarkUrl}; LogoLight: ${this.logoLightUrl}`);
    }

    private generateEnterpriseUrl(itemName: string): string {
        return this.FILE_SERVER_PROTOCOL + '://' + this.FILE_SERVER_NAME + '/enterprises/' + this._hostname + '_' + itemName;
    }

    enterPreviewMode(fromUrl: string, demoRouteList: { route: string, hint?: string }[] = [], style: { bg: CustomizationItemInfo, logoDark: CustomizationItemInfo, logoLight: CustomizationItemInfo }, rawData?: any): void {
        fromUrl = fromUrl.replace(/(.+)\b\?(.+)/, '$1');
        if (this._appMode !== AppMode.preview) {
            this._appMode = AppMode.preview;
            this.bgUrl = style.bg.urlAfter || this.bgUrl;
            this.logoDarkUrl = style.logoDark.urlAfter || this.logoDarkUrl;
            this.logoLightUrl = style.logoLight.urlAfter || this.logoLightUrl;

            this._cache = new AppPreviewCacheData(fromUrl, demoRouteList, style.bg.urlAfter, style.logoDark.urlAfter, style.logoLight.urlAfter, rawData);

            this.onAppModeChanged.emit({ mode: this._appMode, data: this._cache });
            this.router.navigate([demoRouteList[0].route]);
        }
    }

    enterNormalMode(): void {
        this._appMode = AppMode.normal;
        this.reset();

        const data: AppCacheData = this._cache;

        this.onAppModeChanged.emit({ mode: this._appMode, data: data });
        if (data.backURL) {
            this.router.navigate([data.backURL]);
        }
    }

    goToRoute(route: string): void {
        this.router.navigate([route]);
    }

    save(style: { bg?: CustomizationItemInfo, logoDark?: CustomizationItemInfo, logoLight?: CustomizationItemInfo }): Observable<{ isFault: boolean, errorMessage?: string }> {
        Logger.logInfo(this.TAG, 'save', 'style: ', style);

        return of(true).pipe(
            concatMap(() => {
                if (style?.bg?.isChanged()) {
                    const formData = new FormData();
                    formData.append('light', style.bg.raw);
                    return this.naSvc.setEnterpriseBackground(formData, this.accountSvc.token);
                }

                return of(true);
            }),
            concatMap(() => {
                let isChanged: boolean = false;
                const formData = new FormData();
                if (style?.logoLight?.isChanged()) {
                    formData.append('light', style.logoLight.raw);
                    isChanged = true;
                }
                if (style?.logoDark?.isChanged()) {
                    formData.append('dark', style.logoDark.raw);
                    isChanged = true;
                }

                return isChanged ? this.naSvc.setEnterpriseLogo(formData, this.accountSvc.token) : of(true);
            }),
            map(() => {
                this._defaultBgUrl = style?.bg?.urlAfter || this._defaultBgUrl;
                this._defaultLogoDarkUrl = style?.logoDark?.urlAfter || this._defaultLogoDarkUrl;
                this._defaultLogoLightUrl = style?.logoLight?.urlAfter || this._defaultLogoLightUrl;
                Logger.logInfo(this.TAG, 'save', `Default images: Bg -> ${this._defaultBgUrl}; LogoDark -> ${this._defaultLogoDarkUrl} ; LogoLight -> ${this._defaultLogoLightUrl}`);
                this.reset();

                return { isFault: false };
            }),
            catchError(err => of({
                isFault: true,
                errorMessage: err.toString()
            }))
        );
    }
}