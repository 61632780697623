import { Output, EventEmitter, Directive } from '@angular/core';

export const LOCALSTORAGE_KEY_ACCOUNT: string = 'IAdeaCare-Account';
export const LOCALSTORAGE_KEY_TOKEN: string = 'IAdeaCare-Token';
export const LOCALSTORAGE_KEY_TOKEN_INIT_LOCAL_TIME: string = 'IAdeaCare-Token-Init-Local-Time';
export const LOCALSTORAGE_KEY_TOKEN_INIT_SERVER_TIME: string = 'IAdeaCare-Token-Init-Server-Time';
export const LOCALSTORAGE_KEY_TOKEN_EXPIRE_SERVER_TIME: string = 'IAdeaCare-Token-End-Server-Time';
export const LOCALSTORAGE_KEY_IDLE_TIME: string = 'IAdeaCare-Idle-Time';
export const LOCALSTORAGE_KEY_USER_PREFERENCE: string = 'IAdeaCare-UserPref';

export enum TriState {
    Negative = 'Negative',
    Undetermin = 'Undetermin',
    Positive = 'Positive'
}

export enum PermissionCode {
    BasicConfig = 'BasicConfig',
    NetConfig = 'NetConfig',
    Reboot = 'Reboot',
    FirmwareUpdate = 'FirmwareUpdate',
    Troubleshoot = 'Troubleshoot',
    Pairing = 'Pairing',
    UserManagement = 'UserManagement',
    LicenseManagement = 'LicenseManagement'
}

export enum SortType {
    none = 'none',
    descend = 'descend',
    ascend = 'ascend'
}

export enum OwnerType {
    self = 'self'
}

export enum DialogPage {
    prepare = 'prepare',
    action = 'action',
    confirm = 'confirm',
    submit = 'submit',
    result = 'result'
}

export enum ConfigurableItemKey {
    PlayerName = 'PlayerName',
    AppStart = 'AppStart',
    OverlayQRCode = 'OverlayQRCode',
    Resolution = 'Resolution',
    DisplayOrientation = 'DisplayOrientation',
    ScreenSaver = 'ScreenSaver',
    Powersave = 'Powersave',
    ADB = 'ADB',
    HDCP = 'HDCP',
    Timezone = 'Timezone',
    Timeserver = 'Timeserver',
    DailyReboot = 'DailyReboot',
    Speaker = 'Speaker',
    Mic = 'Mic',
    ScreenOff = 'ScreenOff',
    MaintenancePlayback = 'MaintenancePlayback',
    FailSafe = 'FailSafe',
    LockScreen = 'LockScreen'
}

export interface ITableSorter {
    currentSortType: SortType;
}

export interface ITableFilter {
    filterList: { displayName: string, key: string }[];
    currentFilter: { displayName: string, key: string };
}

export class ITableHeaderInfo {
    id?: string;
    displayName: string;
    dataKey: string;
    sorter?: ITableSorter;
    filter?: ITableFilter;
    supportSelect?: boolean;
}

@Directive()
export class TableHeaderHandler {
    list: ITableHeaderInfo[] = [];

    @Output() sortChanged = new EventEmitter<{ columnName: string, dataKey: string, sorter: ITableSorter }>();
    @Output() filterChanged = new EventEmitter<{ columnName: string, dataKey: string, filter: ITableFilter }>();

    init(list: ITableHeaderInfo[]) {
        this.list = list;
    }

    getByDatakey(dataKey: string): ITableHeaderInfo {
        return this.list.find(l => l.dataKey === dataKey);
    }

    sort(item: ITableHeaderInfo, sortType: SortType): void {
        if (sortType === SortType.ascend) {
            return this.ascend(item);
        }
        else if (sortType === SortType.descend) {
            return this.descend(item);
        }
    }

    ascend(item: ITableHeaderInfo): void {
        if (!item.sorter) {
            return;
        }

        this.reset_sorter(item.dataKey);
        item.sorter.currentSortType = SortType.ascend;
        if (this.sortChanged) {
            this.sortChanged.emit({
                columnName: item.displayName,
                dataKey: item.dataKey,
                sorter: item.sorter
            });
        }
    }

    descend(item: ITableHeaderInfo): void {
        if (!item.sorter) {
            return;
        }

        this.reset_sorter(item.dataKey);
        item.sorter.currentSortType = SortType.descend;
        if (this.sortChanged) {
            this.sortChanged.emit({
                columnName: item.displayName,
                dataKey: item.dataKey,
                sorter: item.sorter
            });
        }
    }

    private reset_sorter(exceptDataKey: string): void {
        for (const listData of this.list) {
            if (!listData.sorter) {
                continue;
            }

            if (listData.dataKey === exceptDataKey) {
                continue;
            }

            listData.sorter.currentSortType = SortType.none;
        }
    }

    filter(item: ITableHeaderInfo, selectFilter: { displayName: string, key: string }): void {
        if (!item.filter) {
            return;
        }

        item.filter.currentFilter = selectFilter;
        if (this.filterChanged) {
            this.filterChanged.emit({
                columnName: item.displayName,
                dataKey: item.dataKey,
                filter: item.filter
            })
        }
    }
}

export interface IClass {
    className: string;
}

export interface ITimezoneInfo {
    id: string;
    displayName?: string;
    offset: string;
}

export interface IAccountToken {
    accountID: string;
    accountName: string;
    enterpriseAccountID: string;
    enterpriseAccountName: string;
    enterpriseID: string;
    enterpriseName: string;
    applicationID: string;
    applicationScope: any[];
    exp: number;
    iat: number;
    iss: string;
    metaScope: string[];
    refreshToken: string;
    tokenID: string;
    type: string;
    userScope: string[];
}

export class CustomResponse<T> {
    error: number | string;
    errorMessage: string;
    actionName?: string;
    data?: T;
    hasNext?: boolean;

    static fail(error: number | string, errorMessage: string, actionName?: string) {
        let res: CustomResponse<null> = new CustomResponse<null>();
        res.error = error;
        res.errorMessage = errorMessage;
        res.actionName = actionName;

        return res;
    }

    static failWithData<T>(data: T, error: number | string, errorMessage: string, actionName?: string) {
        let res: CustomResponse<T> = new CustomResponse<T>();
        res.error = error;
        res.errorMessage = errorMessage;
        res.actionName = actionName;
        res.data = data;

        return res;
    }

    static success<T>(data: T = null, hasNext: boolean = false) {
        let res: CustomResponse<T> = new CustomResponse<T>();
        res.error = 0;
        res.data = data;
        res.hasNext = hasNext;

        return res;
    }

    isFault(): boolean {
        return this.error !== 0;
    }
}