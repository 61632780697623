export interface ILicenseInfo {
    isLicenseKeyActivated: boolean;
    isLicenseKeyExpired: boolean;
    license: {
        isLicenseTransferable: boolean;
        licenseID: string;
        licenseLevelInCategory: number;
        licenseName: string; //premium
        licenseShowName: string;
    }
    licenseKeyActivatedDate: string;
    licenseKeyAssignee?: {
        virtualDeviceID: string;
    };
    licenseKeyDurationInDay: number;
    licenseKeyExpiryDate: string;
    licenseKeyOwner: {
        accountID: string;
    };
    licenseKeyPartialCode: string;
    licenseKeyToken: string;
}

export interface ILicenseCategoryInfo {
    expiryDate: string;
    isExpired: boolean;
    currentActivated: string[];
    currentActivatedShowName: string[];
    detail: {
        [licenseLevel: string]: {
            licenseLevelInCategory: number,
            expiryDate: string;
            isExpired: boolean;
            isCurrentActivated: boolean;
            licenseKeyList: ILicenseInfo[];
            licenseShowName: string;
        }
    };
    scope: string[];
}