<label for="config-screen-orientation" class="title">
    {{ _title }}
    <i *ngIf="_lockInfo" class="fas fa-lock ml-2 red-1" data-toggle="tooltip"
        [title]="'Lock by policy <<' + _lockInfo.policyName + '>>'"></i>
</label>
<div id="config-screen-orientation" class="btn-group w-100 mt-1">
    <button id="dropdownMultiConfigOrientationLink" type="button" [disabled]="_lockInfo"
        class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown">
        {{ _orientation || 'Select display orientation' }}
        <span class="sr-only">Select display orientation</span>
    </button>
    <ul class="dropdown-menu dropdown-menu-left option-list w-100" aria-labelledby="dropdownMultiConfigOrientationLink">
        <li *ngFor="let orientation of _availableOrientationList" [class.selected]="_orientation === orientation"
            (click)="changeOrientation(orientation)">
            {{ orientation }}
        </li>
    </ul>
</div>