<div class="row login-page">
    <h1 class="col-12 title">Enterprise account application form</h1>
    <div class="col-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3">
        <div *ngIf="_page !== _enumPage.result; else templateEnterpriseSubmitResult" class="wrapper">
            <div class="content">
                <form #enterpriseApplyForm="ngForm">
                    <div class="form-group">
                        <label for="regEntName">Enterprise name</label>
                        <input #regEntName="ngModel" id="regEntName" name="regEntName" type="text"
                            class="form-control form-control-sm" [disabled]="_page === _enumPage.submit"
                            [(ngModel)]="_data.name" autocomplete="name" required>
                    </div>
                    <div class="form-group">
                        <label for="regEntUrl">
                            Custom URL
                            <i id="dropdownEntUrlHelpLink" class="fas fa-info-circle blue-2 ml-1 icon-hint"
                                data-toggle="dropdown"></i>
                            <div class="dropdown-menu pt-0 hint" data-backdrop="static" data-keyboard="false"
                                aria-labelledby="dropdownEntUrlHelpLink">
                                <div>
                                    Domain rules :
                                    <ul class="mb-0">
                                        <li>3-24 characters allowed</li>
                                        <li>Accept A-Z, 0-9, hyphen (-) characters only</li>
                                        <li>Cannot start or end with hyphen nor have consecutive hyphens</li>
                                        <li>Reserved keywords : dev, stage</li>
                                    </ul>
                                </div>
                            </div>
                            <span *ngIf="!regEntUrl.invalid" class="ml-4">
                                <span *ngIf="_checkingSite; else templateCheckSiteComplete" [class.text-loading]="true"
                                    [attr.data-text]="'....'" class="white">....</span>
                                <ng-template #templateCheckSiteComplete>
                                    <span *ngIf="_isSiteAvailable; else templateSiteExisted" class="px-1 bg-green-2 white">
                                        Site is available
                                    </span>
                                    <ng-template #templateSiteExisted>
                                        <span class="px-1 bg-red-1 white">
                                            Site already exists
                                        </span>
                                    </ng-template>
                                </ng-template>
                            </span>
                        </label>
                        <div class="mt-1">
                            https://
                            <input #regEntUrl="ngModel" id="regEntUrl" name="regEntUrl" type="text"
                                class="form-control form-control-sm d-inline w-auto"
                                [disabled]="_page === _enumPage.submit" [(ngModel)]="_data.url" required domainName
                                queryDomain="true" queryURLTemplate="https://[].care.oniadea.com" maxlength="24"
                                autocomplete="off" (queryStatusChange)="onQueryStatusChange($event)">
                                .care.oniadea.com/
                        </div>
                        <small>
                            Example: https://<strong class="red-1">your-domain</strong>.care.oniadea.com/
                        </small>
                        <div *ngIf="regEntUrl.invalid && (regEntUrl.dirty || regEntUrl.touched) && regEntUrl.errors.domainName"
                            class="alert alert-danger">
                            {{ regEntUrl.errors.domainName.error }}
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="regEntRootAccount">Root user (e-mail)</label>
                        <input #regEntRootAccount="ngModel" id="regEntRootAccount" name="regEntRootAccount" type="text"
                            class="form-control form-control-sm" [disabled]="_page === _enumPage.submit" email
                            [ngModel]="_data.rootAccount" (ngModelChange)="changeRootAccount($event)"
                            autocomplete="email" required>
                        <div *ngIf="regEntRootAccount.invalid && (regEntRootAccount.dirty || regEntRootAccount.touched) && regEntRootAccount.errors.email"
                            class="alert alert-danger">
                            Email format is wrong.
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="regEntDomain">
                            Enterprise domain
                            <i id="dropdownEntDomainHelpLink" class="fas fa-info-circle blue-2 ml-1 icon-hint"
                                data-toggle="dropdown"></i>
                            <div class="dropdown-menu py-0 hint" data-backdrop="static" data-keyboard="false"
                                aria-labelledby="dropdownEntDomainHelpLink">
                                <div>
                                    For user who will be able to log in your Enterprise site with this domain.
                                </div>
                            </div>
                        </label>
                        <input #regEntDomain="ngModel" id="regEntDomain" name="regEntDomain" type="text"
                            class="form-control form-control-sm" disabled
                            [(ngModel)]="_data.domain" autocomplete="off" required>
                    </div>
                    <div class="form-group">
                        <label for="regEntSecondEmail">Secondary contact e-mail</label>
                        <input #regEntSecondEmail="ngModel" id="regEntSecondEmail" name="regEntSecondEmail" type="text"
                            class="form-control form-control-sm" [disabled]="_page === _enumPage.submit" email
                            [(ngModel)]="_data.secondEmail" autocomplete="email" required>
                        <div *ngIf="regEntSecondEmail.invalid && (regEntSecondEmail.dirty || regEntSecondEmail.touched) && regEntSecondEmail.errors.email"
                            class="alert alert-danger">
                            Email format is wrong.
                        </div>
                    </div>
                </form>
                <div class="mt-4">
                    <div class="d-flex align-items-center">
                        <i class="fas fa-cog mr-1"></i>
                        <strong>SSO Settings</strong>
                        <div class='switch-container ml-2'>
                            <input id="regSSOSwitch" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat"
                                type="checkbox" [checked]="_data.sso.enabled" [disabled]="_page === _enumPage.submit"
                                (change)="_data.sso.enabled = $event.target.checked">
                            <label for="regSSOSwitch">
                                <span class="sr-only">Enable SSO setting</span>
                            </label>
                        </div>
                    </div>
                    <form #ssoForm="ngForm" class="setting-block">
                        <div class="form-group">
                            <label for="regSSOTenantID">Directory ID (Tenant ID)</label>
                            <input #regSSOTenantID="ngModel" id="regSSOTenantID" name="regSSOTenantID" type="text"
                                class="form-control form-control-sm"
                                [disabled]="!_data.sso.enabled || _page === _enumPage.submit"
                                [(ngModel)]="_data.sso.tenantID" required autocomplete="off">
                        </div>
                        <div class="form-group">
                            <label for="regSSOAppID">Account application ID</label>
                            <input #regSSOAppID="ngModel" id="regSSOAppID" name="regSSOAppID" type="text"
                                class="form-control form-control-sm"
                                [disabled]="!_data.sso.enabled || _page === _enumPage.submit"
                                [(ngModel)]="_data.sso.appID" required autocomplete="off">
                        </div>
                        <div class="form-group">
                            <label for="regSSOAppName">Account application name</label>
                            <input #regSSOAppName="ngModel" id="regSSOAppName" name="regSSOAppName" type="text"
                                class="form-control form-control-sm"
                                [disabled]="!_data.sso.enabled || _page === _enumPage.submit"
                                [(ngModel)]="_data.sso.appName" required autocomplete="off">
                        </div>
                        <div class="form-group">
                            <label for="regSSOAppIdentity">Account application SSO identifier</label>
                            <input #regSSOAppIdentity="ngModel" id="regSSOAppIdentity" name="regSSOAppIdentity"
                                type="text" class="form-control form-control-sm"
                                [disabled]="!_data.sso.enabled || _page === _enumPage.submit"
                                [(ngModel)]="_data.sso.appIdentity" required autocomplete="off">
                        </div>
                        <div class="form-group">
                            <label for="regSSOAppCert">
                                Account base64 certificate (attachment)
                                <input type="file" class="ml-2"
                                    [disabled]="!_data.sso.enabled || _page === _enumPage.submit"
                                    (click)="onFileClick($event)" (change)="onCertFileChange($event)">
                            </label>
                            <textarea id="regSSOAppCert" class="form-control form-control-sm txt-cert" rows="5"
                                readonly>
                                {{ _data.sso.appCert }}
                            </textarea>
                        </div>
                        <!--
                        <div class="form-group">
                            <label for="regSSOUsername">IAdeaCare account user name</label>
                            <input #regSSOUsername="ngModel" id="regSSOUsername" name="regSSOUsername" type="text"
                                class="form-control form-control-sm"
                                [disabled]="!_data.sso.enabled || _page === _enumPage.submit"
                                [(ngModel)]="_data.sso.userName" required autocomplete="username">
                        </div>
                        <div class="form-group">
                            <label for="regSSOUserpwd">IAdeaCare account password</label>
                            <input #regSSOUserpwd="ngModel" id="regSSOUserpwd" name="regSSOUserpwd" type="text"
                                class="form-control form-control-sm"
                                [disabled]="!_data.sso.enabled || _page === _enumPage.submit"
                                [(ngModel)]="_data.sso.userPwd" required autocomplete="current-password">
                        </div>
                        -->
                    </form>
                </div>
            </div>
            <div class="footer">
                <button [disabled]="_page === _enumPage.submit" class="btn btn-custom btn-gray" type="button"
                    [routerLink]="['/account/login']">
                    cancel
                </button>
                <button
                    [disabled]="!enterpriseApplyForm.form.valid || (_data.sso.enabled && !ssoForm.form.valid) || _page === _enumPage.submit"
                    class="btn btn-custom btn-green" type="submit" (click)="apply()">
                    Apply
                </button>
                <div [class.loading-box]="_page === _enumPage.submit"></div>
            </div>
        </div>
        <ng-template #templateEnterpriseSubmitResult>
            <div class="wrapper">
                <div class="content text-center">
                    <i class="fas fa-check-circle green-2 my-4" style="font-size:4rem"></i>
                    <h2>Thank you</h2>
                    <div>Your form has been submitted and we will contact you soon.</div>
                    <button class="btn btn-custom btn-gray mt-4 mb-2 w-50" type="button"
                        [routerLink]="['/account/login']">
                        Back to login
                    </button>
                </div>
            </div>
        </ng-template>
    </div>
</div>