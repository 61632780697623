import { Component, OnInit } from "@angular/core";
import { AutoUnsubscribeComponent } from "../virtual/auto-unsubscribe.component";
import { ChartOptions } from "chart.js";
import { QuickEventType, StatisticInfo } from "./dashboard.data";
import { DeviceService } from "../device/device.service";
import { DeviceInfo, OnlineStatus } from "../device/data/device-info";
import { FirmwareService } from "../devfunc/firmware/firmware.service";
import { buffer, concatAll, concatMap, delay, map, takeUntil } from "rxjs/operators";
import { forkJoin, Observable, of, Subject } from "rxjs";
import { ConstantService } from "app/lib/common/constant.service";
import { FirmwareUpdateInfo } from "../devfunc/firmware/firmware-data";
import { AlertService } from "../setting/alert/alert.service";
import { EventLogService } from "../event/log/eventLog.service";
import { EventLogEventFilterData, EventLogInfo, EventLogSeverityFilterData, EventLogTimeFilterData } from "../event/log/eventLog.data";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";

@Component({
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends AutoUnsubscribeComponent implements OnInit {
    _loading: boolean = false;
    private _devices: DeviceInfo[] = [];
    private _data: { connectivity?: StatisticInfo, liveUsage?: StatisticInfo, fwStatus?: StatisticInfo } = {};
    datas: StatisticInfo[] = [];

    _loadingAlert: boolean = false;
    _alerts: { id: string, lastDateTime: string, type: string, subject: string, sourceAlertID: string, isAck: boolean }[] = [];

    _loadingEventLog: boolean = false;
    _eventLogs: EventLogInfo[] = [];
    _eventLogLinkStatus: { [eventId: string]: boolean } = {};

    _chartOption: ChartOptions;
    _chartType: string = 'doughnut';

    _enumQuickEventType: typeof QuickEventType = QuickEventType;
    _activeEventType: QuickEventType = QuickEventType.Unknown;

    constructor(private router: Router, private datePipe: DatePipe, private devSvc: DeviceService, private fwSvc: FirmwareService, private constantSvc: ConstantService, private alertSvc: AlertService, private eventSvc: EventLogService) {
        super();
        this._data = {
            connectivity: new StatisticInfo('Connectivity Status', 0),
            liveUsage: new StatisticInfo('Live Utilization', 0),
            fwStatus: new StatisticInfo('Firmware Status', 0)
        };

        this.datas = Object.values(this._data);

        /*
        const FW_MODEL_MAP = {
            // 9e7df0ed-2a5c-4a19-bec7
            'Hawk': ['XMP-6400', 'XDS-1078', 'MBR-1100', 'XMP-6200'],
            // 22a6d755-8ca6-4a82-a724
            'Lion King': ['XMP-7300', 'XMP-7500', 'XOP-7300', 'XDS-2288', 'XDS-2285'],
            // 57e2db2c-3934-49ab-af5b, v3
            'Octopus': ['XDS-1078-A9', 'XDS-1098', 'WRP-1000-L', 'WRP-1000-A', 'WRP-1000-H', 'XMP-6552'],
            // 3704f7b4-b949-47d0-a565
            'Raptor': ['XDS-1078-A12', 'WRP-1000-L-V2', 'WRP-1000-A-V2', 'WRP-1000-H-V2', 'XMP-8550', 'XMP-8552'],
        };
        */
    }

    ngOnInit(): void {
        this._loading = true;
        this.devSvc.getDevicesByBatch('dashboard onInit').pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((res: { isFault: boolean, hasNext: boolean, devices: DeviceInfo[], total: number, errorMessage?: string }) => {
            if (!res.isFault) {
                this._devices = this._devices.concat(res.devices).filter(d => d.isPaired);
                this._loading = false;
            }

            if (!res.hasNext) {
                this._loading = false;
                forkJoin([this.generateConnectivityData(), this.generateLiveUsageData(), this.generateFwData()]).pipe(
                    takeUntil(this._unsubscribe$)
                ).subscribe(res => { });
            }
        });

        this.setActiveQuickEvent();
    }

    setActiveQuickEvent(quickEventType: QuickEventType = QuickEventType.Alert): void {
        setTimeout(() => {
            if (this._activeEventType !== quickEventType) {
                this._activeEventType = quickEventType;
                switch (this._activeEventType) {
                    case QuickEventType.Alert:
                        {
                            this.loadAlertDatas();
                        }
                        break;
                    case QuickEventType.EventLog:
                        {
                            this.loadEventLogDatas();
                        }
                        break;
                }
            }
        }, 0);
    }

    linkToDeviceTaskActivity(eventId: string): void {
        if (this._eventLogLinkStatus[eventId]) {
            return;
        }

        this._eventLogLinkStatus[eventId] = true;
        this.eventSvc.getDeviceTaskEventLogLink(eventId).subscribe((link: string) => {
            this._eventLogLinkStatus[eventId] = false;
            if (link) {
                this.router.navigate([link]);
            }
        });
    }

    private loadAlertDatas(): void {
        this._loadingAlert = true;
        this.alertSvc.getOpenAlertNotificationList().subscribe((res: { data: { id: string, lastDateTime: string, type: string, subject: string, sourceAlertID: string, isAck: boolean }[], total?: number, skip?: number, limit?: number, errorMessage?: string }) => {
            if (!res.errorMessage) {
                this._alerts = res.data;
            }

            this._loadingAlert = false;
        });
    }

    private loadEventLogDatas(): void {
        this._loadingEventLog = true;
        this.eventSvc.getEventStructure().pipe(
            concatMap((eventStructure: { [category: string]: { [event: string]: string } }) => {
                const eventFilter: EventLogEventFilterData = new EventLogEventFilterData(eventStructure);
                const severityFilter: EventLogSeverityFilterData = new EventLogSeverityFilterData();
                const timeFilter: EventLogTimeFilterData = new EventLogTimeFilterData(this.datePipe);

                const levelList: string[] = severityFilter.getFilterData();
                const timeData: { begin: string, end: string } = timeFilter.getFilterData();
                const categoryEventData: { categoryList: string[], eventList: string[] } = eventFilter.getFilterData();

                return this.eventSvc.searchEventLogs(
                    false,
                    timeData.begin,
                    timeData.end,
                    levelList,
                    categoryEventData.categoryList,
                    categoryEventData.eventList,
                    null,
                    0,
                    null,
                    100
                );
            }),
            takeUntil(this._unsubscribe$)
        ).subscribe((res: { data: EventLogInfo[], limit?: number, page?: number, total?: number, errorMessage?: string }) => {
            if (!res.errorMessage) {
                this._eventLogs = res.data;
            }
            this._loadingEventLog = false;
        });
    }

    private generateConnectivityData(): Observable<StatisticInfo> {
        this._data.connectivity.updateTotal(this._devices.length);

        let [online, disconnect, offline] = [0, 0, 0];
        this._devices.forEach(d => {
            switch (d.onlineStatus) {
                case OnlineStatus.Online:
                    {
                        online++;
                    }
                    break;
                case OnlineStatus.Disconnect:
                    {
                        disconnect++;
                    }
                    break;
                case OnlineStatus.Offline:
                    {
                        offline++;
                    }
                    break;
            }

        });

        this._data.connectivity.addDataset('Online', '#00cc8b', online);
        this._data.connectivity.addDataset('Disconnected', '#ec9818', disconnect);
        this._data.connectivity.addDataset('Offline', '#d50000', offline);
        this._data.connectivity.generateChartData();

        return of(this._data.connectivity);
    }

    private generateFwData(): Observable<StatisticInfo> {
        const emitOb: Subject<void> = new Subject();
        return of(true).pipe(
            concatMap(() => {
                this._data.fwStatus.updateTotal(this._devices.length);

                const deviceFamilyPrefixMap: { [prefix: string]: Set<string> } = this._devices.reduce((prev, curr) => {
                    const deviceID: string = curr.currentSettings[this.constantSvc.DEVKEY_INFO_PID];
                    const prefix = deviceID?.substring(0, deviceID?.lastIndexOf('-'));
                    if (prefix) {
                        prev[prefix] = prev[prefix] || new Set();
                        prev[prefix].add(curr.currentSettings[this.constantSvc.DEVKEY_INFO_MODEL]);
                    }

                    return prev;
                }, {});

                const obs: Observable<{ hasNext: boolean, fwData: FirmwareUpdateInfo, isFault: boolean, errorMessage?: string }>[] = [];
                Object.keys(deviceFamilyPrefixMap).forEach(prefix => {
                    deviceFamilyPrefixMap[prefix].forEach((model: string) => {
                        obs.push(this.fwSvc.getLatestInfo(prefix, model).pipe(
                            map((res: FirmwareUpdateInfo) => {
                                return { hasNext: true, fwData: res, isFault: false };
                            })
                        ));
                    });
                });

                obs.push(of({ hasNext: false, fwData: null, isFault: false }));

                return obs;
            }),
            concatAll(),
            map((res: { hasNext: boolean, fwData: FirmwareUpdateInfo, isFault: boolean, errorMessage?: string }) => {
                if (!res.hasNext) {
                    emitOb.next();
                    emitOb.complete();
                }

                return res;
            }),
            buffer(emitOb),
            takeUntil(this._unsubscribe$),
            map((res: { hasNext: boolean, fwData: FirmwareUpdateInfo, isFault: boolean, errorMessage?: string }[]) => {
                const devFwInfoMap: { [model: string]: FirmwareUpdateInfo } = res.reduce((prev, curr) => {
                    if (!curr.isFault && curr.fwData) {
                        prev[curr.fwData.modelName] = curr.fwData;
                    }

                    return prev;
                }, {});

                let [uptodate, outofdate, unknown] = [0, 0, 0];
                this._devices.forEach(d => {
                    if (!devFwInfoMap[d.currentSettings[this.constantSvc.DEVKEY_INFO_MODEL]]) {
                        unknown++;
                        return;
                    }

                    if (d.currentSettings[this.constantSvc.DEVKEY_INFO_FW_VERSION] >= devFwInfoMap[d.currentSettings[this.constantSvc.DEVKEY_INFO_MODEL]].firmwareVersion) {
                        uptodate++;
                    }
                    else {
                        outofdate++;
                    }
                });

                this._data.fwStatus.addDataset('Up-to-Date', '#00cc8b', uptodate);
                this._data.fwStatus.addDataset('Out-of-Date', '#d50000', outofdate);
                this._data.fwStatus.addDataset('Unknown', '#959595', unknown);
                this._data.fwStatus.generateChartData();

                return this._data.fwStatus;
            })
        );
    }

    private generateLiveUsageData(): Observable<StatisticInfo> {
        return of(true).pipe(
            map(() => {
                this._data.liveUsage.updateTotal(this._devices.length);
            }),
            delay(2000),
            map(() => {
                let [inuse, notinuse, idle, unknown] = [0, 0, 0, 0];

                inuse = Math.floor(Math.random() * this._devices.length);
                notinuse = Math.floor(Math.random() * (this._devices.length - inuse));
                idle = Math.floor(Math.random() * (this._devices.length - inuse - notinuse));
                unknown = this._devices.length - inuse - notinuse - idle;

                this._data.liveUsage.addDataset('In-Use', '#00cc8b', inuse);
                this._data.liveUsage.addDataset('Not In-Use', '#ec9818', notinuse);
                this._data.liveUsage.addDataset('Idle', '#64abf0', idle);
                this._data.liveUsage.addDataset('Unknown', '#959595', unknown);
                this._data.liveUsage.generateChartData();

                return this._data.liveUsage;
            })
        );
    }
}