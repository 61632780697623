import { Component } from '@angular/core';
import { DevFuncActionInterface } from '../../base/action/dev-func-action.interface';
import { MultiConfigItem } from '../multi-config-data';
import { TimeserverInfo } from '../../../../../app/uiElement/schedule/timeserver/timeserver.data';
import { ConfigurableItemKey, ITimezoneInfo } from '../../../../../app/lib/common/common.data';
import { AppConfigService } from '../../../../app.config';
import { ConstantService } from '../../../../../app/lib/common/constant.service';
import { AppStartOverlayInfo, DeviceInfo } from '../../../device/data/device-info';
import { PowerSaveInfo } from '../../../../uiElement/powerSave/power-save.data';
import { AndroidGroupType } from '../../firmware/firmware-data';
import { AppStartPlaybackMode } from '../../../../uiElement/appstart/appstart.data';
import { LockScreenInfo } from 'app/uiElement/schedule/screen/screen.data';
import { DailyRebootInfo } from 'app/uiElement/schedule/reboot/dailyReboot.data';

@Component({
    templateUrl: './m-basic-config-action-subform.component.html',
    styleUrls: ['../../base/base-config-form.component.css', './m-basic-config-action-subform.component.css', '../../dev-func.style.css']
})
export class MultiBasicConfigActionSubFormComponent implements DevFuncActionInterface {
    //global setting
    readonly Enable_appStartExtraOption: boolean = AppConfigService.configs.devPage.func.basicConfig.element.appStartExtraOption;
    readonly Enable_speaker: boolean = AppConfigService.configs.devPage.func.basicConfig.element.speaker;
    readonly Enable_mic: boolean = AppConfigService.configs.devPage.func.basicConfig.element.mic;
    readonly Enable_maintenance: boolean = AppConfigService.configs.devPage.func.basicConfig.element.maintenancePlayback;
    readonly Enable_powersave: boolean = AppConfigService.configs.devPage.func.basicConfig.element.powersave;

    _configList: { name: string, enabled: boolean, contentList: MultiConfigItem[] }[] = [];
    _selectedConfig: MultiConfigItem;

    _deviceList: DeviceInfo[] = [];
    _enumConfigurableItemKey: typeof ConfigurableItemKey = ConfigurableItemKey;

    private _actionData: any;
    set actionData(data: any) {
        this._actionData = data;
        if (this._actionData) {
            this._deviceList = this._actionData.deviceList;

            this._configList = [
                {
                    name: 'General',
                    enabled: this._actionData.generalConfigList.filter(config => config.enabled).length > 0,
                    contentList: this._actionData.generalConfigList || []
                },
                {
                    name: 'Content',
                    enabled: this._actionData.contentConfigList.filter(config => config.enabled).length > 0,
                    contentList: this._actionData.contentConfigList || []
                },
                {
                    name: 'Schedule',
                    enabled: this._actionData.scheduleConfigList.filter(config => config.enabled).length > 0,
                    contentList: this._actionData.scheduleConfigList || []
                }
            ];
        }
    }

    _appstartExtraList: {
        property: string;
        displayName: string;
        condition?: (androidVersion: AndroidGroupType) => boolean
    }[] = [];

    constructor(private constantSvc: ConstantService) {
        this._appstartExtraList = this.constantSvc.getAppstartExtraList();
    }

    selectConfig(c: MultiConfigItem): void {
        this._selectedConfig = c;
    }

    removeConfig(config: MultiConfigItem): void {
        this.clearSetting(config);
        if (this._selectedConfig.key === config.key) {
            this._selectedConfig = null;
        }
    }

    reset(): void {
        this._configList.forEach(i => {
            i.contentList.forEach(c => this.clearSetting(c));
        });
    }

    private clearSetting(config: MultiConfigItem): void {
        config.isConfig = false;
        if (config.reset) {
            config.reset(config);
        }
    }

    onBatchConfigTimeserverChanged(config: MultiConfigItem, newSetting: TimeserverInfo): void {
        config.settingMap[this.constantSvc.DEVKEY_TIME_TIMESERVER_ENABLED].value = newSetting.enabled;
        config.settingMap[this.constantSvc.DEVKEY_TIME_TIMESERVER_SOURCE].value = newSetting.url;
        config.isValid = newSetting.isValid;
    }

    onBatchConfigDisplayOrientationChanged(config: MultiConfigItem, s: string | number): void {
        config.settingMap[this.constantSvc.DEVKEY_HD_VIDEO_ROTATION].value = s;
    }

    onBatchConfigTimezoneChanged(config: MultiConfigItem, s: ITimezoneInfo): void {
        config.settingMap[this.constantSvc.DEVKEY_TIME_TIMEZONE].value = s.id;
    }

    onBatchConfigDailyRebootChanged(config: MultiConfigItem, s: DailyRebootInfo): void {
        config.settingMap[this.constantSvc.DEVKEY_SCHEDULE_REBOOT_ENABLED].value = s.enabled;
        config.settingMap[this.constantSvc.DEVKEY_SCHEDULE_REBOOT_TIME].value = s.time;
        config.settingMap[this.constantSvc.DEVKEY_SCHEDULE_REBOOT].value = s.transform();
    }

    onBatchConfigAppstartChanged(config: MultiConfigItem, s: { playbackMode?: AppStartPlaybackMode, property?: { name: string, value: any } }): void {
        config.settingMap[s.property.name].value = s.property.value;
    }

    onBatchConfigOverlayQRCodeChanged(config: MultiConfigItem, s: AppStartOverlayInfo): void {
        config.settingMap[this.constantSvc.DEVKEY_APPSTART_OVERLAY].value = s;
    }

    onBatchConfigMaintenanceUpdated(config: MultiConfigItem, s: any): void {
        config.settingMap[this.constantSvc.DEVKEY_FAKE_MAINTENANCE].value = s;
    }

    onBatchConfigLockScreenChanged(config: MultiConfigItem, s: { data: LockScreenInfo, isValid: boolean }): void {
        config.settingMap[this.constantSvc.DEVKEY_FAKE_LOCKSCREEN].value = s.data;
    }

    onBatchConfigPowersaveChanged(config: MultiConfigItem, s: { data: PowerSaveInfo, isValid: boolean }): void {
        config.settingMap[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_DISABLE_POWERSAVE].value = !s.data.enabled;
        config.settingMap[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_TIMEOUT].value = s.data.timeout;
        config.settingMap[this.constantSvc.DEVKEY_APPSETTING_CONSOLE_POWERSAVE_ACTION].value = s.data.mode;
    }
}