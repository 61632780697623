<div class='modal-dialog config-dlg'>
    <div class='modal-content'>
        <div class='modal-header'>
            <span class="modal-title">{{ title }}</span>
            <button #dlgClose type="button" class="close" data-dismiss="modal">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class='modal-body'>
            <div *ngIf="_page === _enumPage.prepare" class="container-fluid">
                <ul class="pl-0">
                    <li *ngIf="_devices.length === 0" class="warning">
                        No device.
                    </li>
                </ul>
            </div>
            <div *ngIf="_page === _enumPage.action" class="container-fluid">
                <ng-template [ngIf]="!_loading">
                    <div class="row align-items-center mb-2 mx-n2">
                        <strong class="form-label">Transfer license to the device:</strong>
                        <div class="dropdown-wrapper btn-group flex-fill ml-2">
                            <button id="dropdownLicenseReallocateLicenseTargetLink" type="button"
                                class="btn btn-sm btn-action btn-light dropdown-toggle text-wrap"
                                data-toggle="dropdown">
                                {{ _selectDevice?.virtualName || 'Select a device' }}
                            </button>
                            <div class="dropdown-menu dropdown-menu-left w-100" aria-labelledby="dropdownLicenseReallocateLicenseTargetLink">
                                <div class="input-group input-group-sm mb-2">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="fas fa-search"></i>
                                        </span>
                                    </div>
                                    <input #searchLicenseRequestor class="form-control form-control-sm" type='text'
                                        [disabled]="_loading" name='search' placeholder="Search by device name ..."
                                        aria-label="Search devices">
                                </div>
                                <ul class="w-100 option-list">
                                    <li *ngFor="let device of _displayLicenseRequestorDevices" class="text-break"
                                        [class.selected]="_selectDevice?.virtualId === device.virtualId"
                                        (click)="selectPlayer(device)">
                                        {{ device.virtualName }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="btn-group ml-2">
                            <button type="button" class="btn btn-sm btn-action btn-light"
                                [disabled]="!isLicenseChanged()" (click)="recover()">
                                <span class="layout-desktop">Reset</span>
                                <i class="fas fa-sync-alt layout-mobile"></i>
                            </button>
                            <button type="button" class="btn btn-sm btn-action btn-light" data-toggle="tooltip"
                                title="refresh license" [disabled]="_loading" (click)="refresh()">
                                <span class="layout-desktop">Refresh</span>
                                <i class="fas fa-redo-alt layout-mobile"></i>
                            </button>
                        </div>
                    </div>
                    <div class="list-group mx-n2">
                        <div class="list-group-item list-group-header black">
                            <div class="row">
                                <div *ngFor="let HEADER_INFO of _playerTableHeaderHandler.list"
                                    class="col title d-flex">
                                    <span>{{ HEADER_INFO.displayName | capitalFirstWord }}</span>
                                    <div *ngIf="HEADER_INFO.sorter" class="sorter">
                                        <span class="fas fa-caret-up sort-icon descend"
                                            [ngClass]="HEADER_INFO.sorter.currentSortType === _enumSortType.descend ? 'active' : ''"
                                            (click)="_playerTableHeaderHandler.sort(HEADER_INFO, _enumSortType.descend)"></span>
                                        <span class="fas fa-caret-down sort-icon ascend"
                                            [ngClass]="HEADER_INFO.sorter.currentSortType === _enumSortType.ascend ? 'active' : ''"
                                            (click)="_playerTableHeaderHandler.sort(HEADER_INFO, _enumSortType.ascend)"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="list-group-content view-block assigned-licenses">
                            <ng-template [ngIf]="_selectDevice && _selectDevice.virtualId">
                                <ng-template ng-template ngFor let-l
                                    [ngForOf]="_devLicenseRecord[_selectDevice.virtualId].licenseList">
                                    <div class="list-group-item list-group-item-action"
                                        [ngClass]="l.selected ? 'active' : ''" (click)="selectLicense(l)">
                                        <div class="row align-items-center">
                                            <div *ngFor="let HEADER_INFO of _playerTableHeaderHandler.list"
                                                class="col text-break">
                                                {{ l[HEADER_INFO.dataKey] | capitalFirstWord}}
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-template>
                        </div>
                    </div>

                    <!-- ctrl buttons -->
                    <div class="row justify-content-center my-2">
                        <button type="button" class="btn btn-action btn-light" [disabled]="!allowRelease()"
                            (click)="releaseLicenses()">
                            <i class="fas fa-angle-double-down"></i>
                            <span class="sr-only">Remove license</span>
                        </button>
                        <button type="button" class="btn btn-action btn-light ml-2" [disabled]="!allowAssign()"
                            (click)="assignLicenses()">
                            <i class="fas fa-angle-double-up"></i>
                            <span class="sr-only">Assign license</span>
                        </button>
                    </div>
                    <div class="row align-items-center mb-2 mx-n2">
                        <strong class="form-label">Transfer license from the device:</strong>
                        <div class="dropdown-wrapper btn-group flex-fill ml-2">
                            <button id="dropdownLicenseReallocateLicenseOwnerLink" type="button"
                                class="btn btn-sm btn-action btn-light dropdown-toggle text-wrap"
                                data-toggle="dropdown">
                                {{ _currentAssignableLicenseOwner?.displayName }}
                            </button>
                            <div class="dropdown-menu dropdown-menu-left w-100" aria-labelledby="dropdownLicenseReallocateLicenseOwnerLink">
                                <div class="input-group input-group-sm mb-2">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="fas fa-search"></i>
                                        </span>
                                    </div>
                                    <input #searchLicenseOwner class="form-control form-control-sm" type='text'
                                        [disabled]="_loading" name='search' placeholder="Search by device name ..."
                                        aria-label="Search devices">
                                </div>
                                <ul class="option-list">
                                    <li *ngFor="let licenseOwner of _displayLicenseOwnerDevices"
                                        [class.selected]="_currentAssignableLicenseOwner?.deviceID === licenseOwner.deviceID"
                                        (click)="changeAssignableLicenseOwner(licenseOwner)">
                                        <div>
                                            {{ licenseOwner.displayName }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="list-group mx-n2">
                        <div class="list-group-item list-group-header black">
                            <div class="row">
                                <div *ngFor="let HEADER_INFO of _assignableTableHeaderHandler.list"
                                    class="col title d-flex flex-wrap">
                                    <span *ngIf="!HEADER_INFO.filter">
                                        {{ HEADER_INFO.displayName | capitalFirstWord }}
                                    </span>
                                    <div *ngIf="HEADER_INFO.sorter" class="sorter">
                                        <span class="fas fa-caret-up sort-icon descend"
                                            [ngClass]="HEADER_INFO.sorter.currentSortType === _enumSortType.descend ? 'active' : ''"
                                            (click)="_assignableTableHeaderHandler.sort(HEADER_INFO, _enumSortType.descend)"></span>
                                        <span class="fas fa-caret-down sort-icon ascend"
                                            [ngClass]="HEADER_INFO.sorter.currentSortType === _enumSortType.ascend ? 'active' : ''"
                                            (click)="_assignableTableHeaderHandler.sort(HEADER_INFO, _enumSortType.ascend)"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="list-group-content view-block assigned-licenses">
                            <ng-template ngFor let-l
                                [ngForOf]="_devLicenseRecord[_currentAssignableLicenseOwner.deviceID].licenseList">
                                <div class="list-group-item list-group-item-action"
                                    [ngClass]="l.selected ? 'active' : ''" (click)="selectLicense(l)">
                                    <div class="row align-items-center">
                                        <div *ngFor="let HEADER_INFO of _assignableTableHeaderHandler.list"
                                            class="col text-break">
                                            {{ l[HEADER_INFO.dataKey] | capitalFirstWord }}
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </ng-template>
                <div *ngIf="_loading" class="loading">
                    <p>Please wait...</p>
                    <div class="loading-box"></div>
                </div>
            </div>
            <div *ngIf="_page === _enumPage.confirm">
                <p *ngIf="!allowSubmit()" class="text-danger">No changes</p>
                <div *ngIf="allowSubmit()" class="list-group">
                    <div class="list-group-item list-group-header black">
                        <div class="row">
                            <div class="col title">
                                Device name
                            </div>
                            <div class="col title">
                                Original allocation
                            </div>
                            <div class="col title">
                                New allocation
                            </div>
                        </div>
                    </div>
                    <div class="list-group-content list-group-hoverless list-group-content-modal-nr-1">
                        <div *ngFor="let confirmLicenseInfo of _confirmDevLicenseList"
                            class="list-group-item list-group-item-action">
                            <div class="row align-items-top">
                                <div class="col">
                                    {{ confirmLicenseInfo.device.virtualName }}
                                </div>
                                <div class="col">
                                    <div *ngFor="let licenseTypeItem of confirmLicenseInfo.originalLicenseTypeList">
                                        <strong>{{ licenseTypeItem.showName | capitalFirstWord }}</strong>
                                        <ul class="pl-0">
                                            <li *ngFor="let item of confirmLicenseInfo.originalAllocation[licenseTypeItem.type]">
                                                <span>{{ item.licenseInfo.code }}</span>
                                                <span *ngIf="item.changeType === '-'"
                                                    class="text-danger ml-lg-2 ml-1">(-)</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col">
                                    <div *ngFor="let licenseTypeItem of confirmLicenseInfo.newLicenseTypeList">
                                        <strong>{{ licenseTypeItem.showName | capitalFirstWord }}</strong>
                                        <ul class="pl-0">
                                            <li *ngFor="let item of confirmLicenseInfo.newAllocation[licenseTypeItem.type]">
                                                <span>{{ item.licenseInfo.code }}</span>
                                                <span *ngIf="item.changeType === '+'"
                                                    class="red-1 ml-lg-2 ml-1">(+)</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="_confirmDevLicenseList.length > 0" class="red-1">
                    (-) : These license(s) will be put under your account.
                </div>
            </div>
            <div *ngIf="_page === _enumPage.submit" class="container-fluid">
                <div class="progress">
                    <div class="progress-bar progress-bar-success progress-bar-striped active" role="progressbar"
                        aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                        Updating the changes... Please wait...
                    </div>
                </div>
            </div>
            <div *ngIf="_page === _enumPage.result">
                <div>License assignment result :</div>
                <div class="container-fluid">
                    <div class="row result-header">
                        <span class="col">Status</span>
                        <span class="col">License</span>
                        <span class="col"><i class="fas fa-long-arrow-alt-right"></i></span>
                        <span class="col-6">Target location</span>
                    </div>
                    <div class="row mb-2 py-2 border align-items-center"
                        *ngFor="let licenseAssignResult of _licenseAssignResultList">
                        <div class="col">
                            <i *ngIf="licenseAssignResult.isFault" class="text-danger fas fa-exclamation-triangle"></i>
                            <i *ngIf="!licenseAssignResult.isFault" class="text-success fas fa-check-circle"></i>
                        </div>
                        <ul class="col mb-0">
                            <li *ngFor="let licenseCode of licenseAssignResult.licenseCodeList">
                                {{ licenseCode }}
                            </li>
                        </ul>
                        <div class="col">
                            <i class="fas fa-long-arrow-alt-right"></i>
                        </div>
                        <div *ngIf="licenseAssignResult.targetDevice" class="col-6">
                            {{ licenseAssignResult.targetDevice.virtualName }}
                        </div>
                        <div *ngIf="!licenseAssignResult.targetDevice" class="col-6">
                            <i class="fas fa-user"></i> Under account
                        </div>
                        <div *ngIf="licenseAssignResult.isFault" class="col-12 text-danger">
                            <span>Error : </span>
                            <span class="ml-2">{{ licenseAssignResult.errorMessage }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row control-block d-flex justify-content-center'>
                <button *ngIf="allowGoBack()" type="button" class="btn btn-sm btn-action btn-light"
                    [disabled]="_loading" (click)="goBack()">
                    <span class="fas fa-angle-double-left icon-left"></span>
                    {{ 'key-prev' | translate | capitalFirstWord }}
                </button>
                <button *ngIf="allowCancel()" type="button" class="btn btn-sm btn-action btn-light"
                    data-dismiss="modal">
                    <span class="fas fa-times-circle icon-left"></span>
                    {{ 'key-cancel' | translate | capitalFirstWord }}
                </button>
                <button *ngIf="allowGoNext()" type="button" class="btn btn-sm btn-action btn-light"
                    [disabled]="_loading" (click)="goNext()">
                    {{ 'key-next' | translate | capitalFirstWord }}
                    <span class="fas fa-angle-double-right icon-right"></span>
                </button>
                <button *ngIf="allowSubmit()" type="button" class="btn btn-sm btn-action btn-light"
                    [disabled]="_loading" (click)="goNext();">
                    <span class="fas fa-check-circle icon-left"></span>
                    {{ 'key-apply' | translate | capitalFirstWord }}
                </button>
                <button *ngIf="allowClose()" type="button" class="btn btn-sm btn-action btn-light" [disabled]="_loading"
                    data-dismiss="modal">
                    <span class="fas fa-times-circle icon-left"></span>
                    {{ 'key-close' | translate | capitalFirstWord }}
                </button>
            </div>
        </div>
    </div>
</div>
<div *ngIf="_showSwitchPlayerWarning" class="overlay">
</div>
<div id='licenseAlertModal' class='modal fade' [ngClass]="_showSwitchPlayerWarning ? 'show' : 'hide'"
    data-backdrop='static' data-keyboard='false' role='dialog'>
    <div class='modal-dialog'>
        <div class='modal-content'>
            <div class='modal-header'>
                <h4 class="modal-title">Warning</h4>
                <button #dlgClose type="button" class="close" (click)="switchToAnotherPlayer(false)">
                    <!-- data-dismiss="modal" -->
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class='modal-body'>
                <div>
                    <p>The changes you made will be lost if you switch to another device.</p>
                    <p>Are you sure?</p>
                </div>
                <div class="row control-block d-flex justify-content-center">
                    <button type="button" class="btn btn-sm btn-action btn-light" (click)="switchToAnotherPlayer(true)">
                        <span class="fas fa-check-circle icon-left"></span>
                        {{ 'key-ok' | translate | capitalFirstWord }}
                    </button>
                    <button type="button" class="btn btn-sm btn-action btn-light"
                        (click)="switchToAnotherPlayer(false)">
                        <span class="fas fa-times-circle icon-left"></span>
                        {{ 'key-cancel' | translate | capitalFirstWord }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>