export class StatisticInfo {
    title: string;
    total: number;
    datasets: StatisticDataset[];
    chartData: any;
    loading: boolean;

    constructor(title: string, total: number) {
        this.title = title;
        this.total = total;
        this.datasets = [];
        this.loading = false;
    }

    updateTotal(total: number): void {
        this.total = total;
        this.loading = true;
    }

    addDataset(name: string, color: string, amount: number): void {
        this.datasets.push({
            name: name,
            color: color,
            amount: amount,
            percent: Math.round(100 * amount / this.total)
        });
    }

    generateChartData(): any {
        this.chartData = {
            datasets: [{
                label: '',
                data: this.datasets.map(d => d.amount),
                backgroundColor: this.datasets.map(d => d.color),
                hoverOffset: 4
            }]
        };

        this.loading = false;
    }

    clear(): void {
        this.datasets = [];
        this.total = 0;
    }
}

export interface StatisticDataset {
    name: string;
    color: string;
    amount: number;
    percent: number;
}

export enum QuickEventType {
    Alert,
    EventLog,
    Unknown
}