<div *ngIf="data.length > 0; else templateNoRemoveUser">
    <div>Are you sure to delete these users ?</div>
    <ul class="border py-1 mt-1">
        <li *ngFor="let user of data">
            {{ user.email }}
        </li>
    </ul>
    <div class="block block-warning">
        <div>
            <i class="fas fa-exclamation-circle"></i>
            <span class="ml-2">Warning</span>
        </div>
        Once the user is deleted, the user will not be able to log in anymore.
    </div>
</div>
<ng-template #templateNoRemoveUser>
    <p>Please select at least a user.</p>
</ng-template>