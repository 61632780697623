import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IUIElement } from '../uiElement.interface';
import { AutoUnsubscribeComponent } from 'app/content/virtual/auto-unsubscribe.component';

@Component({
    selector: 'na-mic',
    templateUrl: './mic.component.html',
    styleUrls: ['../uiElement.style.css', './mic.component.css']
})
export class MicComponent extends AutoUnsubscribeComponent implements IUIElement {
    _valid: boolean = true;

    @Input('noiseReduction') _noiseReduction: number = 1;
    @Input('mute') _mute: number = 1;
    @Input('showBorder') _showBorder: boolean = true;
    @Input('unsupportReason') _unsupportReason: string; 
    @Input('disabled') _disabled: boolean;
    @Input('lock') _lockInfo: { isSync: boolean, policyID: string, policyName: string };

    @Output() onMicNoiseReductionChanged = new EventEmitter<number>();
    @Output() onMicMuteChanged = new EventEmitter<number>();

    mute(mute: boolean): void {
        this._mute = mute ? 1 : 0;
        this.onMicMuteChanged.next(this._mute);
    }

    enableNoiseReduction(enabled: boolean): void {
        this._noiseReduction = enabled ? 1 : 0;
    }
}