<div class="row login-page">
  <h1 class="col-12 title">Sign up</h1>
  <div class="col-xl-6 offset-xl-3 col-md-8 offset-md-2 col-12">
    <div class="wrapper">
      <div class="content">
        <form #signUpForm="ngForm">
          <div class="form-group">
            <label for="regEmail">Email (as login account)</label>
            <input id="regEmail" name="regEmail" type="text" class="form-control" [(ngModel)]="_regEmail" required
              email #regEmail="ngModel" autocomplete="username">
            <div *ngIf="regEmail.invalid && (regEmail.dirty || regEmail.touched)" class="alert alert-danger">
              <div *ngIf="regEmail.errors.required">
                Email is required
              </div>
              <div *ngIf="regEmail.errors.email">
                Email format is wrong.
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="regPwd">Password</label>
            <input #regPwd="ngModel" id="regPwd" name="regPwd" type="password" class="form-control"
              placeholder="(at least 10 characters long)" [(ngModel)]="_regPassword" required minlength="10"
              validateEqual="regPwdConfirm" reverse="true" autocomplete="new-password">
            <div *ngIf="regPwd.invalid && (regPwd.dirty || regPwd.touched)" class="alert alert-danger">
              <div *ngIf="regPwd.errors.required">
                Password is required
              </div>
              <div *ngIf="regPwd.errors.minlength">
                Password must be at least 10 characters long.
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="regPwdConfirm">Confirm your password</label>
            <input #regPwdConfirm="ngModel" id="regPwdConfirm" name="regPwdConfirm" type="password" class="form-control"
              [(ngModel)]="_regPasswordVerify" required validateEqual="regPwd" autocomplete="new-password">
            <div *ngIf="regPwdConfirm.invalid && (regPwdConfirm.dirty || regPwdConfirm.touched)"
              class="alert alert-danger">
              <div *ngIf="regPwdConfirm.errors.required">
                Password retype is required
              </div>
              <div *ngIf="regPwdConfirm.errors.validateEqual">
                Password mismatch
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="regFirstName">First name</label>
            <input id="regFirstName" name="regFirstName" type="text" class="form-control" [(ngModel)]="_regFirstName"
              required #regFirstName="ngModel">
            <div [hidden]="regFirstName.valid || regFirstName.pristine" class="alert alert-danger">
              Name is required
            </div>
          </div>
          <div class="form-group">
            <label for="regLastName">Last name</label>
            <input id="regLastName" name="regLastName" type="text" class="form-control" [(ngModel)]="_regLastName"
              required #regLastName="ngModel">
            <div [hidden]="regLastName.valid || regLastName.pristine" class="alert alert-danger">
              Name is required
            </div>
          </div>
          <div class="form-group">
            <label for="regSecondEmail">Secondary email (optional)</label>
            <input id="regSecondEmail" name="regSecondEmail" type="text" class="form-control"
              [(ngModel)]="_regSecondaryEmail" email #regSecondEmail="ngModel">
            <div *ngIf="regSecondEmail.invalid && (regSecondEmail.dirty || regSecondEmail.touched)"
              class="alert alert-danger">
              <div *ngIf="regSecondEmail.errors.email">
                Email format is wrong.
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="regCompany">Company</label>
            <input id="regCompany" name="regCompany" type="text" class="form-control" [(ngModel)]="_regCompany" required
              #regCompany="ngModel">
            <div [hidden]="regCompany.valid || regCompany.pristine" class="alert alert-danger">
              Company is required
            </div>
          </div>
        </form>
        <div class="eula">By clicking 'Submit', you agree to the
          <a href="https://support.iadea.com/hc/en-us/articles/360000568623-IAdeaCare-End-User-License-Agreements-"
            target="_blank">
            EULA
          </a>
        </div>
      </div>
      <div class="footer">
        <button [disabled]="_isSigningUp" class="btn btn-custom btn-blue" type="button"
          [routerLink]="['/account/login']">cancel</button>
        <button [disabled]="!signUpForm.form.valid || _isSigningUp" class="btn btn-custom btn-green" type="submit"
          (click)="submit()">sign up</button>
        <div [class.loading-box]="_isSigningUp"></div>
      </div>
    </div>
  </div>
</div>