import { Component, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { concatMap, mergeMap, takeUntil } from 'rxjs/operators';

import { DeviceGroupInfo, DEVICE_GROUP_FUNC_EXCLUDE, DEVICE_GROUP_FUNC_MOVE, GroupSwitch } from './group/group.data';
import { DeviceGroupService } from './group/dev-group.service';
import { HelperLib } from '../../../app/lib/common/helper.lib';
import { DeviceGroupFuncItem, DeviceGroupFuncInterface } from './group/dlg/group-func.def';
import { DeviceGroupFuncService } from './group/dlg/group-func.service';
import { DeviceGroupFuncDirective } from './group/dlg/group-func.directive';
import { DeviceLabelInfo } from './label/dev-label.data';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DeviceService } from './device.service';
import { PolicyService } from '../setting/policy/policy.service';
import { PolicyInfo } from '../setting/policy/policy.data';
import { DeviceAdvFilterOptionInfo } from 'app/uiElement/dev/dev-adv-filter.data';
import { Logger } from 'app/lib/common/logger';
import { DeviceInfo } from './data/device-info';
import { AutoUnsubscribeComponent } from '../virtual/auto-unsubscribe.component';
import { TranslateService } from 'app/translate/translate.service';

enum DeviceFilterType {
  ByGroup = 'ByGroup',
  ByLabel = 'ByLabel'
}

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends AutoUnsubscribeComponent implements OnInit, AfterViewInit {
  _loading: boolean = false;

  _devFilterType: DeviceFilterType = DeviceFilterType.ByGroup;
  _enumDevFilterType: typeof DeviceFilterType = DeviceFilterType;
  _isDeviceLoading: boolean = false;
  // group
  _devGroupRouteList: DeviceGroupInfo[] = [];
  _showGroupTree: boolean = true;
  _groupCtrlAllowHide: boolean = false;
  _devGroupSwitch: GroupSwitch = GroupSwitch.on;
  _enumGroupSwitch: typeof GroupSwitch = GroupSwitch;
  _selectedDeviceGroup: DeviceGroupInfo;
  _proactiveFilter: { onlineStatus?: { [state: string]: boolean }, label?: string };
  _filterRecord: { [type: string]: string[] };
  // label
  _selectedDeviceLabel: DeviceLabelInfo = null;

  _selectedDeviceCount: number = 0;

  @HostListener('window:resize', ['$event'])
  onresize(event) {
    if (HelperLib.isMobileLayout(event.target.innerWidth)) {
      this._showGroupTree = false;
      this._groupCtrlAllowHide = true;
    }
    else {
      this._showGroupTree = true;
      this._groupCtrlAllowHide = false;
    }
  }

  @ViewChild(DeviceGroupFuncDirective, { static: true }) devGroupFuncHost: DeviceGroupFuncDirective;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private devGroupSvc: DeviceGroupService,
    private devGroupFuncSvc: DeviceGroupFuncService,
    private devSvc: DeviceService,
    private policySvc: PolicyService,
    private translateSvc: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this._devGroupSwitch = this.devGroupSvc.groupSwitch;

    this.devGroupSvc.onRouteChanged.pipe(
      takeUntil(this._unsubscribe$)
    ).subscribe((routeList: DeviceGroupInfo[]) => {
      this._devGroupRouteList = routeList;
    });

    this.devGroupSvc.onGroupSwitchChanged.pipe(
      takeUntil(this._unsubscribe$)
    ).subscribe((groupSwitch: GroupSwitch) => {
      this._devGroupSwitch = groupSwitch;
    });

    // record filter info
    this.devSvc.deviceFilterApplied.pipe(
      takeUntil(this._unsubscribe$)
    ).subscribe((res: { isApplied: boolean, devices?: DeviceInfo[], sourceFilters?: { rules?: { type: string, value: string }[], labels?: string[], onlineStatus?: { [state: string]: boolean }, search?: { key: string, value: string, langKey?: string }, devGroup?: { id: string, switch: GroupSwitch } } }) => {
      Logger.logInfo('dashboard', 'OnInit', 'Adv filter ev: ', res);
      if (res.isApplied && res.sourceFilters) {
        let recordKey: string;
        // rules
        if (res.sourceFilters.rules?.length > 0) {
          let advRuleCount: number = 0;
          let policyRuleInfo: { id: string, isSynced: boolean };
          let deviceGroupRuleInfo: { id: string };
          let ticketRuleInfo: { id: string, status: string };
          res.sourceFilters.rules.forEach(rule => {
            if (rule.value.startsWith('[#DevicePolicy')) {
              // Policy rule, should be at most one policy rule at a time.
              const matches: RegExpMatchArray = rule.value.match(/\[#DevicePolicy\.([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}):(\w+)\]/);
              if (matches) {
                policyRuleInfo = { id: matches[1], isSynced: Boolean(matches[2]) };
              }
            }
            else if (rule.value.startsWith('[#DeviceGroup')) {
              // Dev Group rule, should be at most one dev-group rule at a time.
              const matches: RegExpMatchArray = rule.value.match(/\[#DeviceGroup:\"([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})\"\]/);
              if (matches) {
                deviceGroupRuleInfo = { id: matches[1] };
              }
            }
            else if (rule.value.startsWith('[Ticket')) {
              // Ticket rule, should be at most one ticket rule at a time.
              const matches: RegExpMatchArray = rule.value.match(/\[Ticket:\"([a-f0-9]{13}-[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})(:*)(\w*)\"\]/);
              if (matches) {
                ticketRuleInfo = { id: matches[1], status: matches[3] };
              }
            }
            else {
              // advance filter rules
              advRuleCount++;
            }
          });

          of(policyRuleInfo).pipe(
            concatMap((policyRule: { id: string, isSynced: boolean }) => policyRule?.id ? this.policySvc.getPolicyList() : of([])),
          ).subscribe((policies: PolicyInfo[]) => {
            if (policyRuleInfo) {
              const policy: PolicyInfo = policies.find(p => p.id === policyRuleInfo.id);
              this.addFilterRecord('Device policy', `Policy = ${policy?.name || policyRuleInfo.id}`, `Sync status = ${ policyRuleInfo.isSynced ? 'Synced' : 'Not synced'}`);
            }
            if (deviceGroupRuleInfo?.id) {
              const targetDevGroup: DeviceGroupInfo = this.devGroupSvc.getGroupByID(deviceGroupRuleInfo.id);
              if (targetDevGroup) {
                this.addFilterRecord('Device group', `Device group = ${targetDevGroup.name}`);
              }
            }
            if (ticketRuleInfo) {
              this.addFilterRecord('Device activity', `Activity ID = ${ticketRuleInfo.id}`, `${ticketRuleInfo.status ? `Activity status = ${ticketRuleInfo.status}`: ''}`);
            }
            if (advRuleCount > 0) {
              this.addFilterRecord('Advance filter', `${advRuleCount} filter(s) applied`);
            }
          });
        }
        // online status
        if (res.sourceFilters.onlineStatus) {
          recordKey = 'Online status';
          const onlineStatusState = HelperLib.getOnlineStatusState(res.sourceFilters.onlineStatus);
          if (onlineStatusState.isChanged) {
            this.addFilterRecord(recordKey, ...Object.keys(onlineStatusState.onlineStatusIndicator).map(key => `${key}: ${onlineStatusState.onlineStatusIndicator[key]}`));
          }
          else {
            this.removeFilterRecord(recordKey);
          }
        }
        // search
        if (res.sourceFilters.search) {
          recordKey = 'Search';
          if (res.sourceFilters.search.value) {
            this.addFilterRecord(recordKey, `"${this.translateSvc.instant(res.sourceFilters.search.langKey)}" includes "${res.sourceFilters.search.value}"`);
          }
          else {
            this.removeFilterRecord(recordKey);
          }
        }
        // device group
        if (res.sourceFilters.devGroup) {
          recordKey = 'Device group';
          if (res.sourceFilters.devGroup?.id) {
            this.addFilterRecord(recordKey, `Group = ${this.devGroupSvc.getGroupByID(res.sourceFilters.devGroup.id)?.name || res.sourceFilters.devGroup.id}`);
          }
          else {
            this.removeFilterRecord(recordKey);
          }
        }
        // labels
        if (res.sourceFilters.labels) {
          recordKey = 'Device label';
          if (res.sourceFilters.labels.length > 0) {
            this.addFilterRecord(recordKey, `Label name = ${res.sourceFilters.labels[0]}`);
          }
          else {
            this.removeFilterRecord(recordKey);
          }
        }
      }
      else {
        this._filterRecord = null;
      }
    });

    this._loading = true;
    HelperLib.checkState(1, () => { return this.devGroupSvc.isReady }, () => {
      this.route.queryParamMap.pipe().pipe(
        takeUntil(this._unsubscribe$)
      ).subscribe((params: ParamMap) => {
        const targetDevGroupID: string = params.get('group');
        const targetDevPolicyID: string = params.get('policy');
        const targetDevPolicyState: string = params.get('policyState');
        const devActivityID: string = params.get('devActivity');
        const devActivityState: string = params.get('devActivityState');
        Logger.logInfo('dashboard', 'OnInit', `query params: group id: ${targetDevGroupID}, policy id: (${targetDevPolicyID}, ${targetDevPolicyState}), dev activity: (${devActivityID}, ${devActivityState})`);

        if (targetDevGroupID) {
          const targetDevGroup: DeviceGroupInfo = this.devGroupSvc.getGroupByID(targetDevGroupID);
          if (targetDevGroup) {
            this.updateSelectedDeviceGroup(targetDevGroup);
          }
        }
        else {
          this.updateSelectedDeviceGroup(null);
        }

        if (targetDevGroupID || (targetDevPolicyID && targetDevPolicyState) || devActivityID) {
          this.doAdvanceFilterSearch({
            deviceGroup: { groupID: targetDevGroupID },
            devicePolicy: { policyID: targetDevPolicyID, policyState: targetDevPolicyState },
            deviceActivity: { activityID: devActivityID, activityState: devActivityState }
          });
        }

        // remove the route parameter
        this.router.navigate([], { queryParams: {}, replaceUrl: true, relativeTo: this.route });
      });

      this._devGroupRouteList = this.devGroupSvc.getHomeGroupRoute();
      this._loading = false;
    });
  }

  ngAfterViewInit(): void {
    if (window.innerWidth < 992) {
      this._groupCtrlAllowHide = true;
      this._showGroupTree = false;
    }
  }

  selectDeviceFilterType(filterType: DeviceFilterType): void {
    // change between dev group <--> dev label
    if (this._devFilterType !== filterType) {
      this._devFilterType = filterType;

      switch (this._devFilterType) {
        case DeviceFilterType.ByLabel:
          {
            this.inspectGroup(this.devGroupSvc.getHomeGroup(), true);
          }
          break;
        case DeviceFilterType.ByGroup:
          {
            this._selectedDeviceLabel = null;
          }
          break;
      }

      this.devSvc.resetDeviceFilter();
    }
  }

  inspectGroup(g: DeviceGroupInfo, expand: boolean = false): void {
    this.devGroupSvc.inspectGroup(null, g, expand);
    this.updateSelectedDeviceGroup(g);
  }

  turnOnOffSidebar(target?: GroupSwitch): void {
    this._devGroupSwitch = target || (this._devGroupSwitch === GroupSwitch.on ? GroupSwitch.off : GroupSwitch.on);

    switch (this._devFilterType) {
      case DeviceFilterType.ByLabel:
        {
          this._selectedDeviceLabel = null;
        }
        break;
      case DeviceFilterType.ByGroup:
        {
          this.devGroupSvc.turnOnOffGroup(this._devGroupSwitch);
        }
        break;
    }
  }

  changeDeviceGroup(): void {
    this.playGroupFunc(DEVICE_GROUP_FUNC_MOVE);
  }

  removeDeviceFromGroup(): void {
    this.playGroupFunc(DEVICE_GROUP_FUNC_EXCLUDE);
  }

  clearFilters(): void {
    this.devSvc.resetDeviceFilter();
    this._filterRecord = null;
  }

  onDeviceGroupInspect(ev: { devGroup: DeviceGroupInfo, filter?: { onlineStatus: { [state: string]: boolean } } }): void {
    Logger.logInfo('dashboard', 'onDeviceGroupInspect', 'ev: ', ev);

    this.updateSelectedDeviceGroup(ev.devGroup);

    if (ev.filter?.onlineStatus) {
      this.devSvc.getDevicesByFilter({ onlineStatus: ev.filter?.onlineStatus, devGroup: { id: ev.devGroup.id, switch: this._devGroupSwitch } }).subscribe();
    }
  }

  onDeviceLabelSelected(ev: { label: DeviceLabelInfo, filter?: { onlineStatus: { [state: string]: boolean } } }): void {
    Logger.logInfo('dashboard', 'onDeviceLabelSelected', 'ev: ', ev);

    this._selectedDeviceLabel = ev.label;
    this._proactiveFilter = { label: ev.label?.name };

    if (ev.label && ev.filter?.onlineStatus) {
      this.devSvc.getDevicesByFilter({ onlineStatus: ev.filter?.onlineStatus, labels: [ev.label?.name] }).subscribe();
    }
  }

  onDeviceSelectionChange(selectedDeviceAmount: number): void {
    this._selectedDeviceCount = selectedDeviceAmount;
  }

  onDeviceLoadingStatusChange(isLoading: boolean): void {
    this._isDeviceLoading = isLoading;
  }

  playGroupFunc(funcName: string, g?: DeviceGroupInfo): void {
    const item: DeviceGroupFuncItem = this.devGroupFuncSvc.getFunctionByName(funcName);
    if (!item) {
      return;
    }

    const viewContainerRef = this.devGroupFuncHost.viewContainerRef;
    if (!viewContainerRef) {
      return;
    }

    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(item.component);
    (<DeviceGroupFuncInterface>componentRef.instance).title = item.title;
    (<DeviceGroupFuncInterface>componentRef.instance).group = g || this.devGroupSvc.getActiveGroup();
  }

  onDeviceDetailGroupSearchFilterRequest(ev: { deviceGroup?: { groupID: string }, devicePolicy?: { policyID: string, policyState: string } }): void {
    this.doAdvanceFilterSearch(ev);
  }

  private doAdvanceFilterSearch(searchOptions: {
    deviceGroup?: { groupID: string },
    devicePolicy?: { policyID: string, policyState: string },
    deviceActivity?: { activityID: string, activityState: string }
  }): void {
    of(searchOptions.devicePolicy).pipe(
      concatMap((devicePolicy: { policyID: string, policyState: string }) => devicePolicy?.policyID ? this.policySvc.getPolicyList() : of([])),
      mergeMap((policies: PolicyInfo[]) => {
        let searchByPolicy$: Observable<Partial<DeviceAdvFilterOptionInfo>> = of(null);
        let searchByDevGroup$: Observable<Partial<DeviceAdvFilterOptionInfo>> = of(null);
        let searchByDevActivity$: Observable<Partial<DeviceAdvFilterOptionInfo>> = of(null);

        const policy: PolicyInfo = policies.find(p => p.id === searchOptions.devicePolicy?.policyID);
        if (policy) {
          searchByPolicy$ = this.devSvc.getAdvanceFilterOptionsByPolicy(policy, 'Not Synced');
        }

        if (searchOptions.deviceGroup?.groupID) {
          searchByDevGroup$ = this.devSvc.getAdvanceFilterOptionByDeviceGroupID(searchOptions.deviceGroup?.groupID);
        }

        if (searchOptions.deviceActivity?.activityID) {
          searchByDevActivity$ = of({
            value: `[Ticket:\"${searchOptions.deviceActivity.activityID}${searchOptions.deviceActivity.activityState ? ':' + searchOptions.deviceActivity.activityState : ''}\"]`
          });
        }

        return forkJoin([searchByPolicy$, searchByDevGroup$, searchByDevActivity$])
      }),
      concatMap((res: Partial<DeviceAdvFilterOptionInfo>[]) => this.devSvc.getDevicesByFilter({ rules: res.filter(cmd => cmd).map(cmd => ({ type: cmd.type, value: cmd.value })) }))
    ).subscribe();
  }

  private addFilterRecord(type: string, ...desc: string[]): void {
    this._filterRecord = this._filterRecord || {};
    this._filterRecord[type] = [...desc.filter(d => d)];
  }

  private removeFilterRecord(type: string): void {
    if (this._filterRecord?.[type]) {
      delete this._filterRecord[type];
    }
  }

  private updateSelectedDeviceGroup(g: DeviceGroupInfo): void {
    this._selectedDeviceGroup = g?.id === this.devGroupSvc.getHomeGroup().id ? null : g;
  }
}