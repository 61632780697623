export const DLG_NAME_ISSUE_REPORT: string = 'dlg-issue-report';
export const DLG_NAME_REBOOT: string = 'dlg-reboot';
export const DLG_NAME_FIRMWARE_UPDATE: string = 'dlg-firmware-update';
export const DLG_NAME_APK_UPDATE: string = 'dlg-apk-update';
export const DLG_NAME_BASIC_CONFIG: string = 'dlg-basic-config';
export const DLG_NAME_MULTI_BASIC_CONFIG: string = 'dlg-multi-basic-config';
export const DLG_NAME_NET_CONFIG: string = 'dlg-net-config';
export const DLG_NAME_ALERT: string = 'dlg-alert';
export const DLG_NAME_SECURITY: string = 'dlg-security';
export const DLG_NAME_CLEARCACHE: string = 'dlg-clearCache';
export const DLG_NAME_CLEARAPPDATA: string = 'dlg-clearData';
export const DLG_NAME_OTP: string = 'dlg-otp';
export const DLG_NAME_SCRIPT: string = 'dlg-script';

export enum ConfigDialogPage {
    checking = 1,
    action,
    confirm,
    submit,
    result
}

export class GeneralScheduleInfo {
    enabled: boolean;
    date: string;
    time: string;
    offset: number;

    constructor() {
        this.enabled = false;
        this.date = '';
        this.time = '00:00';
        this.offset = -(new Date().getTimezoneOffset() / 60);
    }

    toDate(): Date {
        if (!this.enabled) {
            return null;
        }

        return new Date(this.date + ' ' + this.time + ' GMT' + (this.offset >= 0 ? '+' : '') + this.offset + '00'); //.toISOString();
    }
}