<!-- nav bar -->
<div #iadeaCareBody>
  <nav class="navbar">
    <div class="logo">
      <img [src]="appSvc.logoLightUrl | safe: 'url'" style="height: 44px">
    </div>
    <!-- global functions -->
    <div class="ml-auto">
      <div class="btn-group func-global">
        <span class="white">{{ _loginAccount }}</span>
        <ng-template [ngIf]="!_isRemoteCtrlMode">
          <i id="dropdownGlobalLink" class="fas fa-caret-down ml-2 white" data-toggle="dropdown"></i>
          <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownGlobalLink">
            <li *ngIf="!isEnterpriseSSO" id="id_tb_do_changeAccountPassword" class="dropdown-item" data-toggle="modal"
              data-target="#dlgChangeAccountPwdModal" (click)="changePassword()">
              Change password
            </li>
            <li id="id_tb_do_logout" class="dropdown-item" (click)="logout()">
              {{ 'key-logout' | translate | capitalFirstWord }}
            </li>
            <div class="dropdown-divider"></div>
            <ng-template [ngIf]="Enable_Theme">
              <li class="dropdown-header">
                Theme : {{ _theme.name }}
              </li>
              <li *ngFor="let theme of _themeList" [id]="'id_tb_do_changeTo' + theme.id + 'Theme'" class="dropdown-item"
                (click)="changeTheme(theme)">
                {{ theme.name | capitalFirstWord }}
                <div class="color-indicator-block">
                  <span *ngFor="let mainColor of theme.mainColorList" [style.background-color]="mainColor"></span>
                </div>
              </li>
            </ng-template>
          </ul>
        </ng-template>
      </div>
    </div>
    <button id="navbar-toggle" class="btn navbar-toggle ml-2" type="button" data-toggle="collapse"
      data-target="#gtop-navbar-toggler-menu" aria-controls="gtop-navbar-toggler-menu" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="icon fas fa-bars"></span>
      <span class="icon-expand fas fa-times"></span>
    </button>
    <div #mobileMenu class="collapse navbar-collapse" id="gtop-navbar-toggler-menu">
      <div class="navbar-nav navbar-nav-mobile">
        <na-top-menu [collapseID]="mobileMenu.id"></na-top-menu>
        <na-top-menu [menu]="menuSvc.menuAdmin" [collapseID]="mobileMenu.id"></na-top-menu>
      </div>
    </div>
  </nav>
  <!-- main block -->
  <div class="container-fluid">
    <div class="row">
      <!-- left menu bar -->
      <div *ngIf="!_isRemoteCtrlMode" class="col g-left">
        <div class="menu menu-top" [class.shrink]="_isAdminMenuExpand">
          <na-top-menu></na-top-menu>
        </div>
        <div class="menu menu-bottom">
          <na-top-menu [menu]="menuSvc.menuAdmin" [direction]="'up'" [rootIconLayout]="'top'" [useDarkTheme]="true">
          </na-top-menu>
        </div>
      </div>
      <!-- right main content -->
      <div class="col g-right px-0">
        <div class="container-fluid">
          <h2 *ngIf="_title" class="my-1 title">{{ _title }}</h2>
        </div>
        <div class="content container-fluid">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- change password dialog -->
<div *ngIf="_showAccountPwdDialog" id='dlgChangeAccountPwdModal' tabindex="-1" class='modal fade' data-backdrop='static'
  data-keyboard='false' role='dialog'>
  <div class='modal-dialog'>
    <div class='modal-content'>
      <div class='modal-header'>
        <span class="modal-title">Change password</span>
      </div>
      <div class='modal-body'>
        <div class="row">
          <div class="col-12">
            <account-pwd (onClosed)="onAccountPwdDialogClosed()"></account-pwd>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button #btnIdle type="button" class="btn d-none" data-toggle="modal" data-target="#dlgIdleModal">
  <span class="sr-only">Launch idle dialog</span>
</button>
<div id='dlgIdleModal' class='modal fade' data-backdrop='static' data-keyboard='false' role='dialog'>
  <div class='modal-dialog modal-dialog-centered'>
    <div class='modal-content'>
      <div class='modal-header'>
        <span class="modal-title">Notice</span>
      </div>
      <div class='modal-body p-3'>
        <div *ngIf="_idleStatus.forceLogout; else templateIdleDlgKeepUseText">
          <div *ngIf="_idleStatus.errorMessage; else templateIdleDlgForceLogoutText">
            IAdeaCare has encountered some errors. Please login again.
            <div class="warning-block">{{ _idleStatus.errorMessage }}</div>
          </div>
          <ng-template #templateIdleDlgForceLogoutText>
            IAdeaCare has been left unused for a long period and need to login again.
          </ng-template>
        </div>
        <ng-template #templateIdleDlgKeepUseText>
          IAdeaCare has been left unused for a long period and will be logged out automatically after 2 minutes. Click
          below button to keep using the system.
        </ng-template>
        <div class="text-center mt-4">
          <button *ngIf="_idleStatus.forceLogout; else templateIdleDlgKeepUseAction"
            class="btn btn-action btn-light ml-2" (click)="logout()">
            Logout
          </button>
          <ng-template #templateIdleDlgKeepUseAction>
            <button type="button" class="btn btn-action btn-light" (click)="keepUse()">
              Keep using the system
            </button>
          </ng-template>
          <button #btnIdleClose type="button" class="btn d-none" data-dismiss="modal">
            <span class="sr-only">Close idle dialog</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" display="none">
  <symbol id="icon-nav-devs" viewBox="0 0 24 24">
    <g id="Layer_1" data-name="Layer 1">
      <path
        d="M19,16.06v-1.2a1.63,1.63,0,0,0,1.63-1.63V4.06A1.63,1.63,0,0,0,19,2.43H5.27A1.63,1.63,0,0,0,3.64,4.06v9.17a1.63,1.63,0,0,0,1.63,1.63v1.2a2.83,2.83,0,0,1-2.83-2.83V4.06A2.83,2.83,0,0,1,5.27,1.23H19a2.83,2.83,0,0,1,2.83,2.83v9.17A2.83,2.83,0,0,1,19,16.06Z" />
      <rect x="22.31" y="4.43" width="1.2" height="8.81" />
      <rect x="0.65" y="4.43" width="1.2" height="8.81" />
      <path
        d="M14.33,22.44H9.82a3.19,3.19,0,0,1-3.19-3.19V14.73a3.19,3.19,0,0,1,3.19-3.18h4.51a3.19,3.19,0,0,1,3.19,3.18v4.52A3.19,3.19,0,0,1,14.33,22.44ZM9.82,12.75a2,2,0,0,0-2,2v4.52a2,2,0,0,0,2,2h4.51a2,2,0,0,0,2-2V14.73a2,2,0,0,0-2-2Z" />
      <rect x="10.69" y="18.59" width="2.62" height="1.2" />
    </g>
  </symbol>
  <symbol id="icon-nav-setting" viewBox="0 0 20.6 22.4">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M11.16,1.2l.24,1.69.2,1.41,1.32.54a7.32,7.32,0,0,1,1.54.88l1.14.88,1.32-.54,1.59-.64.87,1.52L18.05,8l-1.11.87.17,1.42a6.84,6.84,0,0,1,.06.91,8.42,8.42,0,0,1-.06.91l-.17,1.41,1.11.88,1.35,1L18.52,17l-1.58-.64-1.3-.52-1.13.85a7.15,7.15,0,0,1-1.56.91l-1.33.54-.2,1.41-.25,1.69H9.42l-.23-1.69L9,18.1l-1.33-.54a6.94,6.94,0,0,1-1.54-.89L5,15.8l-1.33.54L2.07,17,1.2,15.46l1.35-1.05,1.11-.87-.17-1.41c0-.39-.07-.68-.07-.93s0-.53.07-.91l.17-1.42L2.55,8,1.2,7l.88-1.51,1.58.63L5,6.6l1.13-.85a7.15,7.15,0,0,1,1.56-.91L9,4.3l.2-1.41L9.43,1.2h1.73m0-1.2H9.43A1.21,1.21,0,0,0,8.24,1L8,2.71l-.11.74-.68.28A8.2,8.2,0,0,0,5.36,4.8l-.58.43L4.11,5,2.52,4.32a1.32,1.32,0,0,0-.44-.08,1.2,1.2,0,0,0-1,.6L.16,6.35A1.21,1.21,0,0,0,.46,7.9L1.81,9l.58.45-.09.75a9.68,9.68,0,0,0-.08,1c0,.32,0,.66.07,1l.1.77-.58.46-1.35,1a1.21,1.21,0,0,0-.3,1.55L1,17.57a1.2,1.2,0,0,0,1,.6,1.19,1.19,0,0,0,.45-.08l1.59-.64.69-.28.59.45a8.14,8.14,0,0,0,1.8,1.05L7.9,19l.1.73.24,1.69a1.19,1.19,0,0,0,1.18,1h1.75a1.21,1.21,0,0,0,1.19-1l.25-1.69.1-.74.69-.28a8.2,8.2,0,0,0,1.84-1.07l.58-.43.67.27,1.59.64a1.32,1.32,0,0,0,.44.08,1.2,1.2,0,0,0,1-.6l.88-1.51a1.21,1.21,0,0,0-.3-1.55l-1.35-1.05L18.21,13l.09-.74a8.77,8.77,0,0,0,.07-1.06,7.83,7.83,0,0,0-.07-1.08l-.09-.72.58-.46,1.34-1.07a1.18,1.18,0,0,0,.29-1.53l-.87-1.52a1.19,1.19,0,0,0-1.49-.51L16.47,5l-.69.28-.59-.46a8.51,8.51,0,0,0-1.8-1l-.7-.28-.11-.73L12.35,1a1.2,1.2,0,0,0-1.19-1Z" />
        <path
          d="M10.3,8A3.19,3.19,0,1,1,7.11,11.2,3.19,3.19,0,0,1,10.3,8m0-1.2a4.39,4.39,0,1,0,4.39,4.39A4.4,4.4,0,0,0,10.3,6.81Z" />
      </g>
    </g>
  </symbol>
  <symbol id="icon-nav-dashboard" viewBox="0 0 500 500">
    <g>
      <rect x="55" y="40" width="150" height="200"></rect>
      <rect x="295" y="40" width="150" height="130"></rect>
      <rect x="295" y="260" width="150" height="200"></rect>
      <rect x="55" y="330" width="150" height="130"></rect>
    </g>
  </symbol>
  <symbol id="icon-nav-debug" viewBox="0 0 21.01 21.86">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M19,8.29h-.1a8.42,8.42,0,0,0-16.83,0H2a2,2,0,0,0-2,2v4.28a2,2,0,0,0,2,2H5.21V8.29h-2a7.22,7.22,0,0,1,14.43,0H15.8v8.35h1.92a3.73,3.73,0,0,1-3.67,3.13h-1.3a1.5,1.5,0,0,0-1.4-.94H9.64a1.52,1.52,0,0,0,0,3h1.71A1.52,1.52,0,0,0,12.73,21h1.32a4.93,4.93,0,0,0,4.87-4.33H19a2,2,0,0,0,2-2V10.33A2,2,0,0,0,19,8.29ZM4,15.44H2a.83.83,0,0,1-.83-.83V10.33A.83.83,0,0,1,2,9.5H4Zm7.34,5.22H9.64a.32.32,0,0,1,0-.63h1.71a.32.32,0,1,1,0,.63Zm8.46-6.05a.83.83,0,0,1-.83.83H17V9.5h2a.83.83,0,0,1,.83.83Z" />
      </g>
    </g>
  </symbol>
  <symbol id="icon-nav-notification" viewBox="0 0 24 24">
    <g id="Layer_1" data-name="Layer 1">
      <path
        d="M20.6,18.66V16.81l-2-2V10.06c0-3.3-1.75-5.91-4.5-6.78V3.06a2.1,2.1,0,0,0-4.2,0v.22c-2.75.88-4.5,3.48-4.5,6.78v4.75l-2,2v1.85h8v1.18h-2v.6a2.6,2.6,0,0,0,5.2,0v-.6h-2V18.66ZM12,21.84a1.39,1.39,0,0,1-1.26-.8h2.52A1.39,1.39,0,0,1,12,21.84ZM4.6,17.31l2-2V10.06c0-2.95,1.55-5.15,4-5.74l.46-.11V3.06a.9.9,0,0,1,1.8,0V4.21l.46.11c2.49.59,4,2.79,4,5.74v5.25l2,2v.15H4.6Z" />
    </g>
  </symbol>
  <symbol id="icon-nav-miscellaneous" viewBox="0 0 24 24">
    <g id="Layer_1" data-name="Layer 1">
      <path
        d="M8.1,11.2a2.79,2.79,0,1,1,2.79-2.79A2.79,2.79,0,0,1,8.1,11.2Zm0-4.38A1.59,1.59,0,1,0,9.69,8.41,1.59,1.59,0,0,0,8.1,6.82Z" />
      <path
        d="M8.83,2.06,9,3.28,9.11,4l.69.28a4.28,4.28,0,0,1,1,.58l.6.47.7-.28,1.15-.47L14,5.86l-1,.76-.58.46.09.75a4.4,4.4,0,0,1,0,.58,4.13,4.13,0,0,1,0,.57l-.1.74.59.47L14,11l-.72,1.26-1.15-.47-.7-.28-.6.47a4.67,4.67,0,0,1-1,.58l-.68.29L9,13.54l-.17,1.22H7.38l-.17-1.21-.11-.74-.69-.29a4.28,4.28,0,0,1-1-.58l-.6-.47-.7.29L3,12.22,2.25,11l1-.77.59-.46L3.72,9a4.68,4.68,0,0,1-.05-.59,4.13,4.13,0,0,1,.05-.57l.1-.74-.6-.47-1-.76L3,4.61l1.15.47.7.28.6-.46a4.47,4.47,0,0,1,1-.59L7.1,4l.11-.73.17-1.23H8.83m0-1.2H7.38a1.2,1.2,0,0,0-1.19,1L6,3.12v.06L6,3.21A6.12,6.12,0,0,0,4.68,4l0,0L4.57,4,3.42,3.5A1.19,1.19,0,0,0,1.93,4L1.21,5.27a1.2,1.2,0,0,0,.3,1.54l1,.77.06,0v.06a4.88,4.88,0,0,0-.06.73,5.23,5.23,0,0,0,.06.74v.07l0,0-1,.77a1.21,1.21,0,0,0-.3,1.55l.72,1.25a1.19,1.19,0,0,0,1.49.51l1.15-.46.06,0,0,0A5.4,5.4,0,0,0,6,13.62l.06,0v.06l.17,1.21a1.2,1.2,0,0,0,1.19,1H8.83a1.2,1.2,0,0,0,1.19-1l.17-1.22v-.06l.06,0a5.71,5.71,0,0,0,1.27-.74l.05,0,.06,0,1.15.46a1.19,1.19,0,0,0,1.49-.51L15,11.56A1.21,1.21,0,0,0,14.7,10l-1-.77-.05,0V9.14a4.88,4.88,0,0,0,.06-.73,5.23,5.23,0,0,0-.06-.74V7.61l.06,0,1-.77A1.21,1.21,0,0,0,15,5.25L14.28,4a1.19,1.19,0,0,0-1.49-.51L11.64,4l-.06,0-.05,0a5.4,5.4,0,0,0-1.27-.74l-.06,0V3.11L10,1.9a1.2,1.2,0,0,0-1.19-1Z" />
      <path
        d="M18.37,13.44l.19,1.33.36.14a2.49,2.49,0,0,1,.66.39l.3.23,1.24-.5.63,1.09L20.67,17l.09.4a1.58,1.58,0,0,1,0,.34,2.45,2.45,0,0,1,0,.39l-.05.38,1.05.81-.62,1.08-1.26-.5-.3.24a2.69,2.69,0,0,1-.67.39l-.33.15-.19,1.3H17.14L17,20.62l-.35-.14a2.54,2.54,0,0,1-.67-.39l-.3-.23-1.23.5-.64-1.08,1.06-.82-.06-.38a3.81,3.81,0,0,1,0-.78l.06-.38-1.06-.81L14.39,15l1.24.5.3-.23a2.92,2.92,0,0,1,.69-.4l.33-.15.19-1.3h1.23m0-1.2H17.14a1.2,1.2,0,0,0-1.19,1l-.09.65-.41.23-.6-.24a1.22,1.22,0,0,0-.46-.09,1.2,1.2,0,0,0-1,.6l-.63,1.08a1.18,1.18,0,0,0,.3,1.55l.51.4v.48l-.51.4a1.18,1.18,0,0,0-.3,1.55L13.36,21a1.23,1.23,0,0,0,1.49.51l.6-.25a3.38,3.38,0,0,0,.41.24l.09.64a1.2,1.2,0,0,0,1.19,1h1.24a1.2,1.2,0,0,0,1.19-1l.09-.65.41-.23.61.25a1.42,1.42,0,0,0,.45.08,1.2,1.2,0,0,0,1-.6l.62-1.07a1.22,1.22,0,0,0-.3-1.56L22,17.94c0-.08,0-.16,0-.24a1.77,1.77,0,0,0,0-.24l.51-.4a1.21,1.21,0,0,0,.3-1.55l-.64-1.09a1.19,1.19,0,0,0-1-.59,1.22,1.22,0,0,0-.46.09l-.6.24a3.38,3.38,0,0,0-.41-.24l-.09-.65a1.2,1.2,0,0,0-1.19-1Z" />
      <path
        d="M17.76,16.87a.81.81,0,0,1,.82.82.82.82,0,0,1-1.64,0,.81.81,0,0,1,.82-.82m0-1.2a2,2,0,1,0,2,2,2,2,0,0,0-2-2Z" />
    </g>
  </symbol>
  <symbol id="icon-nav-license" viewBox="0 0 20.48 21.2">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M14.64,18.37,13,16.73H10.51V14.24l-1.69-1.7,1.62-1.79V8.39h2.42l1.79-1.71,1.64,1.64h2.48v2.41l1.71,1.71-1.71,1.79v2.42H16.36Zm-2.93-2.84h1.78l1.15,1.14,1.23-1.22h1.7v-1.7l1.24-1.29-1.24-1.23V9.52H15.79L14.63,8.36,13.34,9.59h-1.7v1.62L10.47,12.5l1.24,1.24Z" />
        <path
          d="M14.65,15.16h0a2.58,2.58,0,0,1-2.56-2.57A2.68,2.68,0,0,1,14.61,10a2.6,2.6,0,0,1,.05,5.2Zm0-4a1.47,1.47,0,0,0-1.35,1.44A1.37,1.37,0,0,0,14.64,14a1.4,1.4,0,0,0,0-2.8Z" />
        <polygon points="15.2 21.2 0 21.2 0 0 15.2 0 15.2 5.05 14 5.05 14 1.2 1.2 1.2 1.2 20 15.2 20 15.2 21.2" />
        <rect x="3.29" y="3.79" width="8.45" height="1.2" />
        <rect x="3.29" y="6.76" width="5.48" height="1.2" />
        <rect x="3.29" y="9.72" width="3.31" height="1.2" />
      </g>
    </g>
  </symbol>
  <symbol id="icon-nav-activity" viewBox="0 -0.5 21.26 23">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M11.36,21.82H0L5.68,11Zm-9.28-1.2h7.2l-3.6-7.23Z" />
        <rect x="5.08" y="15.8" width="1.2" height="2.39" />
        <rect x="5.08" y="18.8" width="1.2" height="1.23" />
        <path
          d="M5.68,10.2a5.1,5.1,0,1,1,5.1-5.1A5.11,5.11,0,0,1,5.68,10.2Zm0-9a3.9,3.9,0,1,0,3.9,3.9A3.91,3.91,0,0,0,5.68,1.2Z" />
        <rect x="5.08" y="3.1" width="1.2" height="2.39" />
        <rect x="5.08" y="6.04" width="1.2" height="1.23" />
        <rect x="12.87" y="3.5" width="8.39" height="1.2" />
        <rect x="12.87" y="6.5" width="5.39" height="1.2" />
        <rect x="12.87" y="15.11" width="8.39" height="1.2" />
        <rect x="12.87" y="18.11" width="5.39" height="1.2" />
      </g>
    </g>
  </symbol>
  <symbol id="icon-nav-admin" viewBox="0 0 24 24">
    <g id="Layer_1" data-name="Layer 1">
      <path
        d="M12,8.47a3.95,3.95,0,1,1,3.94-4A4,4,0,0,1,12,8.47Zm0-6.69a2.75,2.75,0,1,0,2.75,2.74A2.75,2.75,0,0,0,12,1.78Z" />
      <path
        d="M21.27,18.42H18.4v-1.2h1.68V12.41a23.74,23.74,0,0,0-16.16,0v4.81h1.6v1.2H2.72V11.58l.39-.14a24.87,24.87,0,0,1,17.62-.06l.54.2Z" />
      <path
        d="M12.56,23.42H11.44a1.16,1.16,0,0,1-1.15-1.15V21.5l-.6-.34L9,21.55a1.15,1.15,0,0,1-.87.12,1.19,1.19,0,0,1-.71-.53l-.56-1A1.16,1.16,0,0,1,7.3,18.6L8,18.21v-.89l-.65-.41A1.15,1.15,0,0,1,7,15.32l.59-.94A1.16,1.16,0,0,1,9.14,14l.64.41.51-.28v-.76a1.16,1.16,0,0,1,1.15-1.16h1.12a1.16,1.16,0,0,1,1.15,1.16v.76l.6.34L15,14.1a1.15,1.15,0,0,1,.87-.13,1.17,1.17,0,0,1,.71.54l.56,1a1.16,1.16,0,0,1-.41,1.58l-.67.39v.88l.66.41a1.14,1.14,0,0,1,.51.72,1.11,1.11,0,0,1-.15.87l-.59.95a1.14,1.14,0,0,1-.72.51,1.11,1.11,0,0,1-.87-.15l-.64-.4-.51.28v.76A1.16,1.16,0,0,1,12.56,23.42ZM9.68,19.77l1.81,1v1.46l1.07-.05-.05-1.42,1.74-1,1.25.77.52-.92L14.81,19l0-2.23L16.09,16l-.58-.89-1.19.75-1.81-1V13.38l-1.07,0,.05,1.42-1.74,1L8.5,15,8,16l1.21.71,0,2.23-1.25.73.58.9Z" />
      <path d="M12,19.82a2,2,0,1,1,2-2A2,2,0,0,1,12,19.82ZM12,17a.8.8,0,1,0,.8.8A.8.8,0,0,0,12,17Z" />
    </g>
  </symbol>

  <symbol id="icon-groupSwitchOn" viewBox="0 0 120 24">
    <g>
      <rect x="108" y="2" width="12" height="4" />
      <rect x="99.7" y="2" width="4" height="4" />
      <rect x="108" y="10" width="12" height="4" />
      <rect x="99.7" y="10" width="4" height="4" />
      <rect x="108" y="18" width="12" height="4" />
      <rect x="99.7" y="18" width="4" height="4" />
      <path d="M83.1,0H38.2c-6.6,0-12,5.4-12,12s5.4,12,12,12h44.8c6.6,0,12-5.4,12-12S89.7,0,83.1,0z M83.1,22H38.2c-5.5,0-10-4.5-10-10
        s4.5-10,10-10h44.8c5.5,0,10,4.5,10,10S88.6,22,83.1,22z" />
      <path d="M55,4H39c-4.4,0-8,3.6-8,8V12c0,4.4,3.6,8,8,8H55c4.4,0,8-3.6,8-8V12C63,7.6,59.4,4,55,4z" />
      <rect x="13.9" y="6" width="4" height="4" />
      <rect x="13.9" y="14" width="4" height="4" />
      <rect x="5.9" y="6" width="4" height="4" />
      <rect x="5.9" y="14" width="4" height="4" />
      <path d="M1.9,22h20V2h-20V22z M3.9,4h16v16h-16V4z" />
    </g>
  </symbol>
  <symbol id="icon-groupSwitchOff" viewBox="0 0 120 24">
    <g>
      <rect x="108" y="2" width="12" height="4" />
      <rect x="99.7" y="2" width="4" height="4" />
      <rect x="108" y="10" width="12" height="4" />
      <rect x="99.7" y="10" width="4" height="4" />
      <rect x="108" y="18" width="12" height="4" />
      <rect x="99.7" y="18" width="4" height="4" />
      <path d="M83.1,0H38.2c-6.6,0-12,5.4-12,12s5.4,12,12,12h44.8c6.6,0,12-5.4,12-12S89.7,0,83.1,0z M83.1,22H38.2c-5.5,0-10-4.5-10-10
        s4.5-10,10-10h44.8c5.5,0,10,4.5,10,10S88.6,22,83.1,22z" />
      <path
        d="m47,4l19,0c-4.4,0 -8,3.6 -8,8l0,0c0,4.4 3.6,8 8,8l16,0c4.4,0 8,-3.6 8,-8l0,0c0,-4.4 -3.6,-8 -8,-8l-35,0z" />
      <rect x="13.9" y="6" width="4" height="4" />
      <rect x="13.9" y="14" width="4" height="4" />
      <rect x="5.9" y="6" width="4" height="4" />
      <rect x="5.9" y="14" width="4" height="4" />
      <path d="M1.9,22h20V2h-20V22z M3.9,4h16v16h-16V4z" />
    </g>
  </symbol>
</svg>