<div class="d-flex align-items-center">
    <div class="btn-group">
        <button id="id_event_log_do_categoryFilter" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
            data-toggle="dropdown" [disabled]="_loading || _exporting" (click)="startEventFilter()">
            <span *ngIf="!_eventFilter?.filterMessage; else templateFilterByEventTitle">
                <span class="d-md-inline-block d-none">Filter by events</span>
                <span class="d-md-none d-inline-block">Event</span>
            </span>
            <ng-template #templateFilterByEventTitle>
                Event
                <strong class="d-md-none d-inline-block red-1">*</strong>
                <strong class="d-md-inline-block d-none">
                    : {{ _eventFilter?.filterMessage }}
                </strong>
            </ng-template>
        </button>
        <div #dropdownMenuEvent class="dropdown-menu dropdown-menu-left dropdown-menu-event"
            aria-labelledby="id_event_log_do_categoryFilter">
            <div class="container-fluid" (click)="$event.stopPropagation()">
                <div class="py-1">
                    <strong>Filter by event(s)</strong>
                    <strong class="ml-2 red-1">{{ _eventFilter?.filterMessage }}</strong>
                </div>
                <div class="mt-1">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="search-by-subject">
                                <i class="fas fa-search"></i>
                            </span>
                        </div>
                        <input #searchEventItem class="form-control form-control-sm" type='text' name='search'
                            placeholder='Search in event item..., type "cat: xxx" to search by category'
                            aria-label="Search in event item" aria-describedby="search-by-event-item">
                    </div>
                    <div class="filter-event-all mt-1">
                        <input type="checkbox" id="cb-filter-event-all" class="cmn-checkbox"
                            (change)="_eventFilter?.selectAllCategoryAndEvents($event.target.checked)">
                        <label for="cb-filter-event-all">
                            <svg>
                                <use xlink:href="#checkmark" />
                            </svg>
                            <span class="sr-only">Select all categories and events</span>
                        </label>
                        <span class="ml-2">Select all categories and events</span>
                    </div>
                    <div class="divider"></div>
                </div>
                <div class="row mt-1 event-wrapper">
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12 d-sm-block d-none pr-sm-1">
                        <div class="title">CATEGORIES</div>
                        <div class="content content-categories">
                            <div *ngFor="let category of _eventFilter?.tmpDataList" class="category-item">
                                <input type="checkbox" [id]="'cb-fe-cat-' + category.categoryID" class="cmn-checkbox"
                                    [checked]="category.checked"
                                    (change)="_eventFilter?.selectCategory(category, $event.target.checked)">
                                <label [for]="'cb-fe-cat-' + category.categoryID">
                                    <svg>
                                        <use xlink:href="#checkmark" />
                                    </svg>
                                    <span class="sr-only">Select category {{ category.categoryName }}</span>
                                </label>
                                <span class="ml-2">{{ category.categoryName }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-8 col-sm-6 col-12 pl-sm-1">
                        <div class="title">EVENTS</div>
                        <div class="content content-events">
                            <ng-template ngFor let-category [ngForOf]="_eventFilter?.tmpDataList">
                                <div *ngIf="category.show" class="event-detail">
                                    <div class="category-sub-title">
                                        <div class="d-sm-none d-block">
                                            <input type="checkbox" [id]="'cb-fe-cat-' + category.categoryID"
                                                class="cmn-checkbox" [checked]="category.checked"
                                                (change)="_eventFilter?.selectCategory(category, $event.target.checked)">
                                            <label [for]="'cb-fe-cat-' + category.categoryID">
                                                <svg>
                                                    <use xlink:href="#checkmark" />
                                                </svg>
                                                <span class="sr-only">Select category {{ category.categoryName }}</span>
                                            </label>
                                        </div>
                                        <span [id]="category.categoryID" class="ml-sm-0 ml-2">
                                            {{ category.categoryName }}
                                        </span>
                                        <span *ngIf="category.checkCount > 0" class="ml-2 red-1">
                                            ({{ category.checkCount }})
                                        </span>
                                    </div>
                                    <div>
                                        <div *ngFor="let event of category.displayEventList; let recordIndex = index" class="event-item">
                                            <input type="checkbox" class="cmn-checkbox" [checked]="event.checked"
                                                [id]="'cb-fe-cat-' + category.categoryID + '-event-' + recordIndex"
                                                (change)="_eventFilter?.selectEvent(category, event, $event.target.checked)">
                                            <label
                                                [for]="'cb-fe-cat-' + category.categoryID + '-event-' + recordIndex">
                                                <svg>
                                                    <use xlink:href="#checkmark" />
                                                </svg>
                                                <span class="sr-only">Select event {{ event.eventName }}</span>
                                            </label>
                                            <span class="ml-2">{{ event.eventName }}</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="mt-4 mb-2 text-center">
                    <button type="button" class="btn btn-sm btn-action btn-light"
                        (click)="dropdownMenuEvent.classList.remove('show'); applySearch()">
                        Apply
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="btn-group ml-2">
        <button id="id_event_log_do_periodFilter" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
            data-toggle="dropdown" [disabled]="_loading || _exporting" (click)="_timeFilter?.startFilter()">
            Time range
            <strong class="d-md-none d-inline-block red-1">*</strong>
            <strong class="d-md-inline-block d-none">
                : {{ _timeFilter?.filterMessage }}
            </strong>
        </button>
        <div #dropdownMenuTime class="dropdown-menu dropdown-menu-left dropdown-menu-time"
            aria-labelledby="id_event_log_do_periodFilter">
            <div class="container-fluid" (click)="$event.stopPropagation()">
                <div class="py-1">
                    <strong>Filter by time range</strong>
                </div>
                <div class="row">
                    <div class="col-md-6 col-12 pr-md-1">
                        <div>
                            <strong>
                                From :
                                <span class="red-1">
                                    <span *ngIf="_timeFilter?.tmpData.beginDate; else templateNoFilterBeginDate">
                                        {{ _timeFilter?.tmpData.beginDate }}
                                    </span>
                                    <ng-template #templateNoFilterBeginDate>
                                        xxxx-xx-xx
                                    </ng-template>
                                    {{ _timeFilter?.tmpData.beginTime }}
                                </span>
                            </strong>
                            <div>
                                <input type="date" class="form-control form-control-sm"
                                    aria-label="Select event log filter start date"
                                    [attr.min]="_timeFilter?.dateMinLimit" [attr.max]="_timeFilter?.dateMaxLimit"
                                    [value]="_timeFilter?.tmpData.beginDate"
                                    (change)="_timeFilter?.changeBeginDate($event.target.value)">
                            </div>
                        </div>
                        <div class="filter-time-block">
                            <div>
                                <na-timePicker [time]="_timeFilter?.tmpData.beginTime" [minuteIncre]="30"
                                    [autoNotify]="true" [showSelectionTime]="false"
                                    (onTimeChanged)="_timeFilter?.changeBeginTime($event)">
                                </na-timePicker>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-12 pl-md-1">
                        <div>
                            <strong>
                                To :
                                <span class="red-1">
                                    <span *ngIf="_timeFilter?.tmpData.beginDate; else templateNoFilterEndDate">
                                        {{ _timeFilter?.tmpData.endDate }}
                                    </span>
                                    <ng-template #templateNoFilterEndDate>
                                        xxxx-xx-xx
                                    </ng-template>
                                    {{ _timeFilter?.tmpData.endTime }}
                                </span>
                            </strong>
                            <div>
                                <input type="date" class="form-control form-control-sm"
                                    aria-label="Select event log filter end date" [attr.min]="_timeFilter?.dateMinLimit"
                                    [attr.max]="_timeFilter?.dateMaxLimit" [value]="_timeFilter?.tmpData.endDate"
                                    (change)="_timeFilter?.changeEndDate($event.target.value)">
                            </div>
                        </div>
                        <div class="filter-time-block">
                            <div>
                                <na-timePicker [time]="_timeFilter?.tmpData.endTime" [minuteIncre]="30"
                                    [autoNotify]="true" [showSelectionTime]="false"
                                    (onTimeChanged)="_timeFilter?.changeEndTime($event)">
                                </na-timePicker>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="_timeFilter?.errorMessage" class="mt-2">
                    <div class="warning-block">{{ _timeFilter?.errorMessage }}</div>
                </div>
                <div class="my-2 text-right">
                    <button type="button" class="btn btn-sm btn-action btn-light" data-dismiss="modal"
                        [disabled]="_timeFilter?.errorMessage"
                        (click)="dropdownMenuTime.classList.remove('show'); applySearch()">
                        Apply dates
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="btn-group ml-2">
        <button id="id_event_log_do_levelFilter" type="button" class="btn btn-sm btn-action btn-light dropdown-toggle"
            data-toggle="dropdown" [disabled]="_loading || _exporting" (click)="_severityFilter?.startFilter()">
            <span *ngIf="!_severityFilter?.filterMessage; else templateFilterBySeverityTitle">
                <span class="d-md-inline-block d-none">Filter by severity</span>
                <span class="d-md-none d-inline-block">Severity</span>
            </span>
            <ng-template #templateFilterBySeverityTitle>
                Severity
                <strong class="d-md-none d-inline-block red-1">*</strong>
                <strong class="d-md-inline-block d-none">: {{ _severityFilter?.filterMessage }}</strong>
            </ng-template>
        </button>
        <div #dropdownMenuLevel class="dropdown-menu dropdown-menu-left dropdown-menu-level"
            aria-labelledby="id_event_log_do_levelFilter">
            <div class="container-fluid" (click)="$event.stopPropagation()">
                <div class="py-1">
                    <strong>Filter by severity</strong>
                </div>
                <div>
                    <div *ngFor="let severity of _severityFilter?.tmpList" class="d-flex align-items-center mb-1">
                        <input type="checkbox" [id]="'cb-filter-level-' + severity.name" class="cmn-checkbox"
                            [checked]="severity.checked"
                            (change)="_severityFilter?.changeSeverity(severity, $event.target.checked)">
                        <label [for]="'cb-filter-level-' + severity.name">
                            <svg>
                                <use xlink:href="#checkmark" />
                            </svg>
                            <span class="sr-only">Select level {{ severity.name }}</span>
                        </label>
                        <span class="ml-2">{{ severity.name | capitalFirstWord }}</span>
                    </div>
                </div>
                <div class="my-2 text-right">
                    <button type="button" class="btn btn-sm btn-action btn-light"
                        (click)="dropdownMenuLevel.classList.remove('show'); applySearch()">
                        Apply severity
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="ml-auto">
        <button id="id_event_log_do_refreshAll" type="button" [disabled]="_loading || _exporting" class="btn btn-sm btn-action btn-light" (click)="refresh()">
            <i class="fas fa-redo-alt"></i>
        </button>
        <button id="id_event_log_do_export" type="button" class="btn btn-sm btn-action btn-light ml-2" [disabled]="_loading ||_exporting" (click)="export()">
            <span class="d-md-inline-block d-none">Export</span>
            <i class="d-md-none d-inlin-block ml-sm-1 ml-0 fas fa-download"></i>
            <i *ngIf="_exporting" class="ml-2 fas fa-spinner rotate"></i>
        </button>
        <a #exportLink class="d-none"></a>
    </div>
</div>
<div class="input-group mt-2">
    <input #searchSubject class="form-control form-control-sm" type='text' name='search' [disabled]="_loading"
        placeholder='Search in subject ...' aria-label="Search in subject" aria-describedby="search-by-subject" (keypress)="keyDown($event.keyCode)">
    <button id="id_event_log_do_search" type="button" class="btn btn-sm btn-action btn-light" (click)="applySearch()">
        Search
    </button>
</div>
<div class="list-group mt-2">
    <div class="list-group-item list-group-header">
        <div class="row">
            <div *ngFor="let col of _tableHeaderList" class="col title">
                {{ col.displayName }}
                <span class="sorter">
                    <i class="fas fa-caret-up sort-icon descend"
                        [ngClass]="_currentSortKey === col.key && _currentSortType === _enumSortType.descend ? 'active' : ''"
                        (click)="sortDescend(col.key)">
                    </i>
                    <i class="fas fa-caret-down sort-icon ascend"
                        [ngClass]="_currentSortKey === col.key && _currentSortType === _enumSortType.ascend ? 'active' : ''"
                        (click)="sortAscend(col.key)">
                    </i>
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="_loading; else templateContent" class="loading">
        <div class="loading-box"></div>
    </div>
    <ng-template #templateContent>
        <div *ngIf="_errorMessage; else templateContentNoError" class="red-1 text-center m-4">{{ _errorMessage }}</div>
        <ng-template #templateContentNoError>
            <div class="list-group-content list-group-hoverless">
                <div *ngFor="let log of _eventLogList" class="list-group-item">
                    <div class="row align-items-center">
                        <div class="col text-break">
                            {{ log.dateTime | date:'yyyy-MM-dd HH:mm:ss' }}
                        </div>
                        <div class="col text-break">
                            {{ log.category }}
                        </div>
                        <div class="col text-break">
                            <span [ngSwitch]="log.level" class="mr-1">
                                <ng-template [ngSwitchCase]="_enumEventLogLevelCode.Info">
                                    <i class="fas fa-info-circle blue-2"></i>
                                </ng-template>
                                <ng-template [ngSwitchCase]="_enumEventLogLevelCode.Warn">
                                    <i class="fas fa-exclamation-triangle yellow-2"></i>
                                </ng-template>
                                <ng-template [ngSwitchCase]="_enumEventLogLevelCode.Error">
                                    <i class="fas fa-exclamation-circle red-1"></i>
                                </ng-template>
                            </span>
                            <span *ngIf="log.toLink; else templateEventNoLink" class="link d-inline-block"
                                (click)="linkToDeviceTaskActivity(log.id)">{{ log.event }}
                                <i *ngIf="_eventLogLinkLoadStatusMap[log.id]" class="ml-2 fas fa-spinner rotate"></i>
                            </span>
                            <ng-template #templateEventNoLink>
                                <span>{{ log.event }}</span>
                            </ng-template>
                        </div>
                        <div class="col text-break">
                            {{ log.subject }}
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-template>
</div>
<div class="d-flex align-items-center mt-1">
    <div>
        <div class="d-inline-block">
            <i class="fas fa-info-circle blue-2"></i>
            <span class="ml-2">Info</span>
        </div>
        <div class="d-inline-block ml-2">
            <i class="fas fa-exclamation-triangle yellow-2"></i>
            <span class="ml-2">Warning</span>
        </div>
        <div class="d-inline-block ml-2">
            <i class="fas fa-exclamation-circle red-1"></i>
            <span class="ml-2">Error</span>
        </div>
    </div>
    <div class="ml-auto text-muted font-italic">
        total : {{ _total }}
    </div>
    <div class="ml-2">
        <na-pager [currentPage]="_currentPage" [numberInPage]="50" [total]="_total"
            (onPageChanged)="onPageChange($event)">
        </na-pager>
    </div>
</div>