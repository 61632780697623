<div>
    <label for="config-mic" class="title">
        Microphone
        <i *ngIf="_lockInfo" class="fas fa-lock ml-2 red-1" data-toggle="tooltip"
            [title]="'Lock by policy <<' + _lockInfo.policyName + '>>'"></i>
    </label>
    <div *ngIf="_unsupportReason" class="warning-block mb-1">{{ _unsupportReason }}</div>
    <div id="config-mic" [ngClass]="_showBorder ? 'showBorder' : ''">
        <div [ngClass]="_lockInfo || _disabled || _unsupportReason || !_valid ? 'inactive' : ''">
            <div class="form-group row align-items-center">
                <label for="col-mic-mute" class="col-12 col-md-4 col-form-label col-form-label-sm">
                    Mute
                </label>
                <div id="col-mic-mute" class="col-12 col-md-8">
                    <i *ngIf="_mute" class="fas fa-microphone-slash" (click)="mute(false)"></i>
                    <i *ngIf="!_mute" class="fas fa-microphone" (click)="mute(true)"></i>
                </div>
            </div>
            <div class="form-group row align-items-center">
                <label for="col-mic-noise" class="col-12 col-md-4 col-form-label col-form-label-sm">
                    Noise reduction
                </label>
                <div id="col-mic-noise" class="col-12 col-md-8">
                    <div class='switch-container'>
                        <input id="cmn-toggle-mic-noise" class="cmn-toggle cmn-toggle-flat cmn-toggle-rect-flat" type="checkbox"
                            [disabled]="_lockInfo || _disabled || _unsupportReason" [checked]="_noiseReduction"
                            (change)="enableNoiseReduction($event.target.checked)">
                        <label for="cmn-toggle-mic-noise">
                            <span class="sr-only">Enable noise reduction</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>