<ng-container *ngIf="!_loading; else templateLoading">
    <div class="mt-2 container-fluid">
        <div class="row">
            <div *ngFor="let data of datas" class="col data-box-wrapper">
                <div class="data-box">
                    <div class="data-header">
                        <div>{{ data.title }}</div>
                        <div class="summary">
                            <div class="count">{{ data.total }}</div>
                            <div class="count-txt">Total</div>
                        </div>
                    </div>
                    <ng-container *ngIf="!data.loading; else templateLoadingModule">
                        <div class="data-body row">
                            <div class="col-5">
                                <canvas baseChart [data]="data.chartData" [type]="_chartType"></canvas>
                            </div>
                            <div class="col-7">
                                <div *ngFor="let dataset of data.datasets" class="status-item">
                                    <div class="ball" [style.background]="dataset.color"></div>
                                    <div class="ml-2">{{ dataset.name }}</div>
                                    <div class="summary">
                                        <div class="percent">{{ dataset.percent }}%</div>
                                        <div class="count">{{ dataset.amount }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #templateLoadingModule>
                        <div class="loading">
                            <div class="loading-box"></div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12 data-box-wrapper">
                <div class="data-box">
                    <nav>
                        <ul class='nav nav-tabs'>
                            <li class="nav-item">
                                <a id="nav-quick-alert" data-toggle="tab" data-target="#nav-content-alert"
                                    class="nav-link" role="tab" aria-controls="nav-content-alert" aria-selected="true"
                                    [class.active]="_activeEventType === _enumQuickEventType.Alert"
                                    (click)="setActiveQuickEvent(_enumQuickEventType.Alert)">
                                    Alert
                                </a>
                            </li>
                            <li class="nav-item">
                                <a id="nav-quick-eventLog" data-toggle="tab" data-target="#nav-content-eventLog"
                                    class="nav-link" role="tab"
                                    [class.active]="_activeEventType === _enumQuickEventType.EventLog"
                                    (click)="setActiveQuickEvent(_enumQuickEventType.EventLog)">
                                    Event Log
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div class='tab-content'>
                        <div class="tab-pane fade show active" id="nav-content-alert" role="tabpanel"
                            aria-labelledby="nav-quick-alert">
                            <ng-container *ngIf="!_loadingAlert; else templateLoadingAlert">
                                <div class="data-body mt-2">
                                    <div class="list-group mt-2">
                                        <div class="list-group-item list-group-header">
                                            <div class="row">
                                                <div class="col title">Occurred on</div>
                                                <div class="col title">Type</div>
                                                <div class="col title">Subject</div>
                                            </div>
                                        </div>
                                        <div class="list-group-content">
                                            <div *ngFor="let alert of _alerts" class="list-group-item">
                                                <div class="row align-items-center">
                                                    <div class="col text-break">
                                                        {{ alert.lastDateTime | date:'yyyy-MM-dd HH:mm:ss' }}
                                                    </div>
                                                    <div class="col text-break">
                                                        {{ alert.type }}
                                                    </div>
                                                    <div class="col text-break">
                                                        {{ alert.subject }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #templateLoadingAlert>
                                <div class="loading">
                                    <div class="loading-box"></div>
                                </div>
                            </ng-template>
                        </div>
                        <div class="tab-pane fade" id="nav-content-eventLog" role="tabpanel"
                            aria-labelledby="nav-quick-eventLog">
                            <ng-container *ngIf="!_loadingEventLog; else templateLoadingEventLog">
                                <div class="data-body mt-2">
                                    <div class="list-group mt-2">
                                        <div class="list-group-item list-group-header">
                                            <div class="row">
                                                <div class="col title">Occurred on</div>
                                                <div class="col title">Category</div>
                                                <div class="col title">Event</div>
                                                <div class="col title">Subject</div>
                                            </div>
                                        </div>
                                        <div class="list-group-content">
                                            <div *ngFor="let log of _eventLogs" class="list-group-item">
                                                <div class="row align-items-center">
                                                    <div class="col text-break">
                                                        {{ log.dateTime | date:'yyyy-MM-dd HH:mm:ss' }}
                                                    </div>
                                                    <div class="col text-break">
                                                        {{ log.category }}
                                                    </div>
                                                    <div class="col text-break">
                                                        <span class="d-inline-block" [class.link]="log.toLink"
                                                            (click)="linkToDeviceTaskActivity(log.id)">
                                                            {{ log.event }}
                                                            <i *ngIf="log.toLink && _eventLogLinkStatus[log.id]"
                                                                class="ml-2 fas fa-spinner rotate"></i>
                                                        </span>
                                                    </div>
                                                    <div class="col text-break">
                                                        {{ log.subject }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #templateLoadingEventLog>
                                <div class="loading">
                                    <div class="loading-box"></div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #templateLoading>
    <div class="loading">
        <p>Please wait...</p>
        <div class="loading-box"></div>
    </div>
</ng-template>