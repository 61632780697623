<div>
  <div>
    <span class="text-info">Enabled :</span>
    <span class="ml-2">{{ _enabled }}</span>
  </div>
  <div>
    <span class="text-info">Sync location :</span>
    <span class="ml-2">{{ _source }}</span>
  </div>
</div>
