export type CameraAutoFramingMode = 'Group' | 'Speaker' | 'Grid';

export class CameraInfo {
    fov: number;
    isAutoframingOn: boolean;
    autoframingMode: CameraAutoFramingMode;

    constructor(v?: any) {
        this.fov = v?.fov || 117;
        this.isAutoframingOn = v?.isAutoframingOn ?? true;
        this.autoframingMode = v?.autoframingMode || 'Speaker';
    }
}