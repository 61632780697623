<div class="d-flex align-items-center">
    <span class="txt-bold">{{ _title }}</span>
    <i class="fas fa-file-upload icon-file ml-2" (click)="selectFile()"></i>
    <input #imgFile type="file" [attr.accept]="_fileFormat" class="d-none" [disabled]="_disabled"
        (click)="onFileClick($event)" (change)="onFileChanged($event)">
    <small class="highlight ml-2">(maximum file size: {{ _maxAllowSize }} MB)</small>
    <button type="button" class="btn btn-sm btn-action btn-light btn-nb ml-auto" [class.disabled]="!_data?.isChanged()" (click)="reset()">
        <i class="fas fa-undo undo mr-1" data-target="tooltip" title="Reset" [style.color]="_iconColor"></i>
        Reset
    </button>
</div>
<div class="img-tile" [class.updated]="_data?.isChanged()" [style.background]="_bgColor">
    <img *ngIf="_data?.urlAfter" [src]="_data.urlAfter | safe: 'url'">
    <i class="fas fa-tags tag ml-2" data-target="tooltip" title="File information" [style.color]="_iconColor"></i>
    <div class="details">
        <div>name : {{ _data?.name }}</div>
        <div>size : {{ _data?.size }} </div>
    </div>
</div>
<small *ngIf="_note" class="d-block text-center">{{ _note }}</small>