<div *ngIf="_source" class="d-flex flex-wrap">
    <ng-container *ngFor="let filter of _source.subGroupList">
        <ng-container *ngIf="!filter.hide">
            <div [ngSwitch]="filter.type" class="filter-block" [ngClass]="'layer-' + filter.layer" [class.option-container]="filter.optionList.length > 0" [class.changed]="filter.changedOptionsCount > 0">
                <ng-template [ngSwitchCase]="FILTER_TYPE_GROUP">
                    <div class="title">
                        {{ filter.groupName }}
                    </div>
                    <na-dev-adv-filter-item [source]="filter" [loading]="_loading"></na-dev-adv-filter-item>
                </ng-template>
                <ng-template [ngSwitchCase]="FILTER_TYPE_SELECT">
                    <div class="filter-type filter-type-select">
                        <strong>{{ filter.groupName }}</strong>
                        <div>
                            <div *ngFor="let option of filter.optionList" class="cmn-radio">
                                <input [id]="option.id" type="radio" [attr.name]="'option-' + filter.groupName" [disabled]="_loading"
                                    [checked]="option.isDefault" (click)="chooseSingleSelectFilterOption(filter, option)">
                                <label [for]="option.id" class="radio"></label>
                                <span class="ml-2">{{ option.name }}</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngSwitchCase]="FILTER_TYPE_TEXT">
                    <div class="dropdown filter-type filter-type-textOption">
                        <button [id]="filter.id" type="button" class="btn btn-sm btn-action btn-light w-100" [disabled]="_loading"
                            data-toggle="dropdown" (click)="selectFilter(filter)">
                            <div class="d-flex align-items-center">
                                <span [ngSwitch]="filter.option.name">
                                    <ng-template [ngSwitchCase]="'Contain following text'">
                                        <i class="fa fa-check green-2"></i>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'No filter'">
                                        <i class="fa fa-times gray-6"></i>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'is empty'">
                                        <i class="fa fa-square white border"></i>
                                    </ng-template>
                                    <span *ngSwitchDefault>
                                        <i class="fa fa-square red-1"></i>
                                    </span>
                                </span>
                                <span class="ml-2">{{ filter.groupName }}</span>
                                <i class="fa fa-caret-down ml-auto" aria-hidden="true"></i>
                            </div>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" [class.show]="filter.dropdownShow"
                            [attr.aria-labelledby]="filter.id">
                            <div *ngFor="let option of filter.optionList" class="dropdown-item"
                                [class.active]="option.isDefault" (click)="chooseSingleSelectFilterOption(filter, option)">
                                <span [ngSwitch]="option.name">
                                    <ng-template [ngSwitchCase]="'Contain following text'">
                                        <i class="fa fa-check green-2"></i>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'No filter'">
                                        <i class="fa fa-times gray-6"></i>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'is empty'">
                                        <i class="fa fa-square white border"></i>
                                    </ng-template>
                                    <span *ngSwitchDefault>
                                        <i class="fa fa-square red-1"></i>
                                    </span>
                                </span>
                                <span class="ml-2">{{ option.name }}</span>
                            </div>
                        </div>
                        <div [ngSwitch]="filter.option.name" class="filter-type-textOption-content">
                            <ng-template [ngSwitchCase]="'Contain following text'">
                                <input type="text" class="form-control form-control-sm" placeholder="Enter keyword" [(ngModel)]="filter.option.data">
                            </ng-template>
                            <ng-template [ngSwitchCase]="'No filter'">
                                <input type="text" class="form-control form-control-sm" disabled>
                            </ng-template>
                            <ng-template [ngSwitchCase]="'is empty'">
                                <input type="text" class="form-control form-control-sm" disabled>
                            </ng-template>
                            <div *ngSwitchDefault>
                                <input type="text" class="form-control form-control-sm"
                                    placeholder="Enter excluded keyword" [(ngModel)]="filter.option.data">
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngSwitchCase]="FILTER_TYPE_SIMPLE_SELECT">
                    <div class="dropdown filter-type filter-type-simpleSelect">
                        <button [id]="filter.id" type="button" class="btn btn-sm btn-action btn-light w-100" [disabled]="_loading"
                            data-toggle="dropdown" (click)="selectFilter(filter)">
                            <div class="d-flex align-items-center">
                                <span [ngSwitch]="filter.option.name" class="option-label">
                                    <ng-template [ngSwitchCase]="'Enabled'">
                                        <i class="fa fa-check green-2"></i>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'Disabled'">
                                        <i class="fa fa-square red-1"></i>
                                    </ng-template>
                                    <span *ngSwitchDefault>
                                        <i class="fa fa-times gray-6"></i>
                                    </span>
                                </span>
                                <span class="ml-2">{{ filter.groupName }}</span>
                                <i class="fa fa-caret-down ml-auto" aria-hidden="true"></i>
                            </div>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" [class.show]="filter.dropdownShow"
                            [attr.aria-labelledby]="filter.id">
                            <div *ngFor="let option of filter.optionList" class="dropdown-item"
                                [class.active]="option.isDefault" (click)="chooseSingleSelectFilterOption(filter, option)">
                                <span [ngSwitch]="option.name">
                                    <ng-template [ngSwitchCase]="'Enabled'">
                                        <i class="fa fa-check green-2"></i>
                                    </ng-template>
                                    <ng-template [ngSwitchCase]="'Disabled'">
                                        <i class="fa fa-square red-1"></i>
                                    </ng-template>
                                    <span *ngSwitchDefault>
                                        <i class="fa fa-times gray-6"></i>
                                    </span>
                                </span>
                                <span class="ml-2">{{ option.name }}</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template [ngSwitchCase]="FILTER_TYPE_MULTI_SELECT">
                    <div class="filter-type filter-type-multiselect">
                        <div class="d-flex align-items-center">
                            <input type="checkbox" [id]="filter.id" class="cmn-checkbox" [checked]="filter.isDefault" [disabled]="_loading"
                                (change)="chooseMultiSelectFilter(filter, $event.target.checked)">
                            <label [for]="filter.id">
                                <svg>
                                    <use xlink:href="#checkmark" />
                                </svg>
                                <span class="sr-only">Filter {{ filter.groupName }}</span>
                            </label>
                            <span class="ml-2">{{ filter.groupName }}</span>
                        </div>
                        <div class="ml-3">
                            <div *ngFor="let option of filter.optionList" class="d-flex align-items-center">
                                <input type="checkbox" [id]="option.id" class="cmn-checkbox" [checked]="option.isDefault" [disabled]="_loading"
                                    (change)="chooseMultiSelectFilterOption(filter, option, $event.target.checked)">
                                <label [for]="option.id">
                                    <svg>
                                        <use xlink:href="#checkmark" />
                                    </svg>
                                    <span class="sr-only">Filter option {{ option.name }}</span>
                                </label>
                                <span class="ml-2">v. {{ option.name }}</span>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>
        </ng-container>
    </ng-container>
</div>