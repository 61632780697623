import { NgModule } from "@angular/core";
import { DashboardComponent } from "./dashboard.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "app/translate/translate.module";
import { CustomPipeModule } from "app/lib/pipes/custom-pipe.module";
import { NgChartsModule } from "ng2-charts";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,

        TranslateModule,
        CustomPipeModule,


        NgChartsModule
    ],
    declarations: [
        DashboardComponent,
    ],
    providers: [

    ]
})
export class DashboardModule { }