import { Component, Input, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { fromEvent, of } from 'rxjs';
import { concatMap, debounceTime, map, takeUntil } from 'rxjs/operators';

import { ScepService } from '../scep.service';
import { SortType } from '../../../../../app/lib/common/common.data';
import { DeviceService } from '../../../../../app/content/device/device.service';
import { DeviceInfo } from '../../../../../app/content/device/data/device-info';
import { ScepDeviceInfo, ScepDeviceOperationMode, ScepServerInfo } from '../scep.data';
import { ConstantService } from '../../../../../app/lib/common/constant.service';
import { LicenseService } from '../../../license/license.service';
import { LicenseScopeType } from '../../../license/license.data';
import { HelperLib } from '../../../../lib/common/helper.lib';
import { AutoUnsubscribeComponent } from 'app/content/virtual/auto-unsubscribe.component';

@Component({
    selector: 'na-scep-dev-pickup',
    templateUrl: './scep-dev-pickup.component.html',
    styleUrls: ['../scep.style.css', './scep-dev-pickup.component.css']
})
export class ScepDevicePickupComponent extends AutoUnsubscribeComponent implements OnInit {
    static InstanceID: number = 1;

    readonly COL_DEV_NAME: string = 'devName';
    readonly COL_DEV_MAC: string = 'devMAC';
    readonly COL_DEV_GROUP: string = 'devGroup';
    readonly COL_SCEP_STATUS: string = 'scepEnrollStatus';
    readonly COL_SCEP_ALIAS: string = 'scepAlias';
    readonly COL_SCEP_EXPIRY: string = 'scepExpiryDate';

    private _devMap: { [virtualDeviceID: string]: { info: ScepDeviceInfo, checked: boolean, hasLicense: boolean, show?: boolean } } = {};
    private _devInfoList: { info: ScepDeviceInfo, checked: boolean, hasLicense: boolean, show?: boolean }[] = [];

    _devDisplayList: { info: ScepDeviceInfo, checked: boolean, hasLicense: boolean }[] = [];
    _devDisplayTotal: number;
    _tableHeaderMap: { [key: string]: { name: string, sortSupport?: boolean, show?: boolean } } = {};

    _compID: string;
    _loading: boolean;
    _searchTxt: string = '';
    _currentSortKey: string;
    _currentSortType: SortType = SortType.ascend;
    _enumSortType: typeof SortType = SortType;
    _numInPage: number = 100;
    _currentPage: number = 1;
    _refreshCountdown: number = 0;
    _bSelectAll: boolean = false;

    _scep: ScepServerInfo;
    @Input('scep')
    set scep(v: ScepServerInfo) {
        this._scep = v;
    }

    _enumScepDeviceOpMode: typeof ScepDeviceOperationMode = ScepDeviceOperationMode;
    _mode: ScepDeviceOperationMode = ScepDeviceOperationMode.update;
    @Input('mode')
    set mode(v: ScepDeviceOperationMode) {
        this._mode = v;
    }

    private _searchRef: ElementRef;
    @ViewChild('search', { static: true })
    set search(v: ElementRef) {
        if (v) {
            this._searchRef = v;

            fromEvent(this._searchRef.nativeElement, 'input').pipe(
                debounceTime(300),
                takeUntil(this._unsubscribe$)
            ).subscribe((e: any) => {
                this._searchTxt = e.target.value.trim().toLocaleLowerCase();
                this.onDeviceSearchChanged.emit(this._searchTxt);
                this.refactor();
            });
        }
    }

    @Output() onDeviceSelected = new EventEmitter<ScepDeviceInfo[]>();
    @Output() onDeviceSearchChanged = new EventEmitter<string>();

    constructor(
        private devSvc: DeviceService,
        private scepSvc: ScepService,
        private licenseSvc: LicenseService,
        private constantSvc: ConstantService
    ) {
        super();
        this._compID = 'SCEP-DEV-PICKUP-' + ScepDevicePickupComponent.InstanceID++;
        this._refreshCountdown = this.scepSvc.deviceRefreshCountdown;
    }

    ngOnInit(): void {
        this.initDeviceColumn();

        this.scepSvc.onDeviceRefreshCountdownChanged.pipe(
            takeUntil(this._unsubscribe$)
        ).subscribe((counter: number) => {
            this._refreshCountdown = counter;
        });

        this.refreshDevices(false);
    }

    private initDeviceColumn(): void {
        this._tableHeaderMap = this._tableHeaderMap || {};
        this._tableHeaderMap[this.COL_DEV_MAC] = { name: 'MAC', sortSupport: true, show: true };
        this._tableHeaderMap[this.COL_DEV_GROUP] = { name: 'Device group', sortSupport: true, show: true };
        this._tableHeaderMap[this.COL_SCEP_STATUS] = { name: 'Enrollment status', sortSupport: true, show: this._mode === ScepDeviceOperationMode.update || this._mode === ScepDeviceOperationMode.selectAll ? true : false };
        this._tableHeaderMap[this.COL_SCEP_ALIAS] = { name: 'Credential in use', sortSupport: true, show: this._mode === ScepDeviceOperationMode.add ? true : false };
        this._tableHeaderMap[this.COL_SCEP_EXPIRY] = { name: 'Expiry date', sortSupport: true, show: true };
    }

    refreshDevices(force: boolean = false): void {
        if (force) {
            this._refreshCountdown = this.scepSvc.startRefreshDevice();
        }

        this._loading = true;
        this.devSvc.getDevicesByBatch('device-overview-table.onInit', force).pipe(
            concatMap((res: { hasNext: boolean, isFault: boolean, devices: DeviceInfo[], errorMessage?: string }) => {
                if (!res.isFault) {
                    return this.licenseSvc.checkLicenseLegality(res.devices, LicenseScopeType.taskConfig).pipe(
                        map((licenseRet: { dev: DeviceInfo, isLegal: boolean }[]) => {
                            this.transform(licenseRet);

                            return res.hasNext;
                        })
                    );
                }

                return of(res.hasNext);
            })
        ).subscribe((hasNext: boolean) => {
            if (!hasNext) {
                this.refactor();
                this._loading = false;

                if (this._mode === ScepDeviceOperationMode.selectAll) {
                    this.selectAllScepDevices(true);
                }
            }
        });
    }

    selectAllScepDevices(checked: boolean): void {
        this._bSelectAll = checked;
        this._devDisplayList.forEach(d => {
            if (d.hasLicense) {
                d.checked = checked;
            }
        });
        this.onDeviceSelected.emit(this._devInfoList.filter(devInfo => devInfo.checked).map(devInfo => devInfo.info));
    }

    selectDevice(dev: { info: ScepDeviceInfo, checked: boolean }, checked: boolean): void {
        dev.checked = checked;
        this.onDeviceSelected.emit(this._devInfoList.filter(devInfo => devInfo.checked).map(devInfo => devInfo.info));
    }

    sortDescend(key: string): void {
        if (this._currentSortKey === key && this._currentSortType === SortType.descend) {
            return;
        }

        this._currentSortKey = key;
        this._currentSortType = SortType.descend;
        this.refactor();
    }

    sortAscend(key: string): void {
        if (this._currentSortKey === key && this._currentSortType === SortType.ascend) {
            return;
        }

        this._currentSortKey = key;
        this._currentSortType = SortType.ascend;
        this.refactor();
    }

    onPageChange(page: number): void {
        if (this._currentPage !== page) {
            this._currentPage = page;
            this.refactor();

            this._bSelectAll = true;
            for (let dev of this._devDisplayList) {
                if (!dev.checked) {
                    this._bSelectAll = false;
                    break;
                }
            }
        }
    }

    private transform(licenseRetList: { dev: DeviceInfo, isLegal: boolean }[]): void {
        licenseRetList.forEach(ld => { 
            const scep: { status: string, alias: string, expiryDate: string } = { status: '', alias: '', expiryDate: ''};
            const scepCandidate = ld.dev.scep.candidates.find(c => {
                const stateLowerCase: string = c.state.toLocaleLowerCase();
                return c.certName === this._scep.alias && (stateLowerCase === 'enrolling' || stateLowerCase === 'enrolled');
            });

            let show: boolean = false;
            if (this._mode === ScepDeviceOperationMode.update || this._mode === ScepDeviceOperationMode.selectAll) {
                //filter the scep info from candidates which device had for current scep
                if (scepCandidate) {
                    scep.status = scepCandidate.state;
                    scep.alias = scepCandidate.certName;
                    scep.expiryDate = scepCandidate.notAfter;
                    show = true;
                }
            }
            else if (this._mode === ScepDeviceOperationMode.add) {
                //under add dialog
                if (ld.dev.scep.inUse) {
                    const stateLowercase: string = ld.dev.scep.inUse.state.toLocaleLowerCase();
                    scep.status = ld.dev.scep.inUse.state;
                    scep.alias = (stateLowercase === 'enrolling' || stateLowercase === 'enrolled') ? ld.dev.scep.inUse.certName : '',
                    scep.expiryDate = ld.dev.scep.inUse.notAfter;
                }

                if (!scepCandidate) {
                    show = true;
                }
            }

            this._devMap[ld.dev.virtualId] = {
                info: {
                    dev: ld.dev,
                    devName: ld.dev.virtualName || '',
                    devMAC: ld.dev.currentSettings[this.constantSvc.DEVKEY_NET_LAN_MAC] || ld.dev.currentSettings[this.constantSvc.DEVKEY_NET_WIFI_MAC] || '',
                    devGroup: ld.dev.virtualDeviceGroup?.groupName || '',
                    scep: scep
                },
                checked: false,
                hasLicense: ld.isLegal,
                show: show
            }
        });
    }

    private refactor(): void {
        this._devInfoList = HelperLib.mapToList(this._devMap);

        this._devDisplayList = this._devInfoList.filter(devInfo => devInfo.show && devInfo.info?.dev?.isPaired);
        this._devDisplayList = this._searchTxt ? this._devDisplayList.filter(d => d.info.dev.virtualName?.toLocaleLowerCase().indexOf(this._searchTxt) >= 0 || d.info.devMAC?.toLocaleLowerCase().indexOf(this._searchTxt) >= 0) : this._devDisplayList;
        this._devDisplayTotal = this._devDisplayList.length;

        //by sort
        this._devDisplayList = this._devDisplayList.sort((a: { info: ScepDeviceInfo, checked: boolean }, b: { info: ScepDeviceInfo, checked: boolean }) => {
            let ca: string = a.info[this._currentSortKey];
            let cb: string = b.info[this._currentSortKey];

            if (ca === cb) {
                return 0;
            }

            switch (this._currentSortType) {
                case SortType.ascend:
                    {
                        return ca >= cb ? 1 : -1;
                    }
                case SortType.descend:
                    {
                        return ca >= cb ? -1 : 1;
                    }
            }
        });

        //by page number
        if (this._devDisplayList.length > this._numInPage) {
            const startIndex = (this._currentPage - 1) * this._numInPage;
            this._devDisplayList = this._devDisplayList.slice(startIndex, startIndex + this._numInPage);
        }
    }
}