<div>
    <div class="d-flex align-items-center">
        <div class="btn-group">
            <button id="id_event_devAct_do_categoryFilter" type="button"
                class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown" [disabled]="_isLoading">
                Activity: <strong>{{ _filterTaskType.name | capitalFirstWord }}</strong>
            </button>
            <ul class="dropdown-menu dropdown-menu-left option-list"
                aria-labelledby="id_event_devAct_do_categoryFilter">
                <li *ngFor="let type of FILTER_TASK_CATEGORY_LIST" (click)="changeTaskType(type)">
                    {{ type.name | capitalFirstWord }}
                </li>
            </ul>
        </div>
        <div class="btn-group ml-2">
            <button id="id_event_devAct_do_statusFilter" type="button"
                class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown" [disabled]="_isLoading">
                Status: <strong>{{ _filterTaskStatus | capitalFirstWord }}</strong>
            </button>
            <ul class="dropdown-menu dropdown-menu-left option-list" aria-labelledby="id_event_devAct_do_statusFilter">
                <li *ngFor="let status of FILTER_TASK_STATUS_LIST" (click)="changeTaskStatus(status)">
                    {{ status | capitalFirstWord }}
                </li>
            </ul>
        </div>
        <div class="btn-group ml-2">
            <button id="id_event_devAct_do_dateFilter" type="button"
                class="btn btn-sm btn-action btn-light dropdown-toggle" data-toggle="dropdown" [disabled]="_isLoading"
                (click)="startChangeTaskData()">
                Date: <strong>{{ _filterDate.from }} ~ {{ _filterDate.to }}</strong>
            </button>
            <div class="dropdown-menu dropdown-menu-left option-list" aria-labelledby="id_event_devAct_do_dateFilter">
                <div>
                    <label for="id_event_devAct_do_fromDateFilter">From</label>
                    <input id="id_event_devAct_do_fromDateFilter" type="date" class="form-control form-control-sm mx-2"
                        name="filter-date-from" [value]="_filterTempDate.from" [disabled]="_isLoading"
                        (change)="_filterTempDate.from = $event.target.value">
                </div>
                <div>
                    <label for="id_event_devAct_do_toDateFilter">To</label>
                    <input id="id_event_devAct_do_toDateFilter" type="date" class="form-control form-control-sm mx-2"
                        name="filter-date-from" [value]="_filterTempDate.to" [disabled]="_isLoading"
                        (change)="_filterTempDate.to = $event.target.value">
                </div>
                <div class="mt-4 text-center">
                    <button type="button" class="btn btn-sm btn-action btn-light"
                        [disabled]="!_filterDate.from && !_filterDate.to" (click)="resetDateFilter()">
                        Reset
                    </button>
                    <button type="button" class="btn btn-sm btn-action btn-light ml-2"
                        [disabled]="!_filterTempDate.from || !_filterTempDate.to || _filterTempDate.from === _filterTempDate.to"
                        (click)="changeTaskDate()">
                        Select
                    </button>
                </div>
            </div>
        </div>
        <button *ngIf="_isFilterChanged" class="btn btn-sm btn-action btn-nb btn-edit active ml-2"
            (click)="applyFilter()">Apply filter</button>
        <button id="id_event_devAct_do_refreshAll" type="button" class="btn btn-sm btn-action btn-light ml-auto"
            [disabled]="_refreshCounter > 0 || _isLoading" data-toggle="tooltip"
            title='{{ "key-dev-refresh" | translate | capitalFirstWord }}' (click)="getActivityList(true)">
            <i [class.rotate]="_refreshCounter > 0" class="fas fa-sync-alt mr-1"></i>
            <span class="d-none d-sm-inline-block">Refresh</span>
            <ng-template [ngIf]="_refreshCounter > 0"> - {{ _refreshCounter }} s</ng-template>
        </button>
    </div>
    <div class="input-group mt-2">
        <input #search class="form-control form-control-sm" type='text' name='search' [disabled]="_isLoading"
            placeholder='Search in subject ...' aria-label="Search in activity name"
            aria-describedby="id_dev_activity_do_search" (keypress)="keyDown($event.keyCode)">
        <div class="input-group-append">
            <button id="id_dev_activity_do_search" type="button" class="btn btn-sm btn-action btn-light"
                (click)="applySearch()">
                Search
            </button>
        </div>
    </div>
    <div class="list-group mt-2">
        <div class="list-group-item list-group-header">
            <div class="row">
                <div *ngFor="let col of _tableHeaderList" class="col title">
                    {{ col.displayName }}
                    <span class="sorter">
                        <i class="fas fa-caret-up sort-icon descend"
                            [ngClass]="_currentSortKey === col.key && _currentSortType === _enumSortType.descend ? 'active' : ''"
                            (click)="sortDescend(col.key)"></i>
                        <i class="fas fa-caret-down sort-icon ascend"
                            [ngClass]="_currentSortKey === col.key && _currentSortType === _enumSortType.ascend ? 'active' : ''"
                            (click)="sortAscend(col.key)"></i>
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="_isLoading; else templateContent" class="loading">
            <div class="loading-box"></div>
        </div>
        <ng-template #templateContent>
            <div class="list-group-content list-group-hoverless">
                <div *ngFor="let activity of _activityList" class="list-group-item">
                    <div class="row align-items-center">
                        <div class="col">
                            {{ activity.issueDateTime | date:'yyyy-MM-dd HH:mm:ss' }}
                            <span *ngIf="activity.isScheduled" data-toggle="tooltip"
                                title="{{ activity.scheduledDateTime | date: 'yyyy-MM-dd HH:mm:ss' }}">
                                <i class="far fa-calendar-alt"></i>
                            </span>
                        </div>
                        <div class="col">
                            <a [routerLink]="['/app/event/activity', activity.activityID]">{{ activity.subject }}</a>
                        </div>
                        <div class="col">
                            <div class="status-bar">
                                <div class="d-none d-md-flex align-items-center">
                                    <ng-template [ngIf]="activity.statusCount.success > 0">
                                        <div class="ball status-success"></div>
                                        <div>{{ activity.statusCount.success }}</div>
                                    </ng-template>
                                    <ng-template [ngIf]="activity.statusCount.failed > 0">
                                        <div class="ball status-fail"></div>
                                        <div>{{ activity.statusCount.failed }}</div>
                                    </ng-template>
                                    <ng-template [ngIf]="activity.statusCount.cancelled > 0">
                                        <div class="ball status-cancel"></div>
                                        <div>{{ activity.statusCount.cancelled }}</div>
                                    </ng-template>
                                    <ng-template [ngIf]="activity.statusCount.progress">
                                        <div class="ball status-progress"></div>
                                        <div>{{ activity.statusCount.progress }}</div>
                                    </ng-template>
                                    <ng-template [ngIf]="activity.statusCount.pending">
                                        <div class="ball status-pending"></div>
                                        <div>{{ activity.statusCount.pending }}</div>
                                    </ng-template>
                                </div>
                                <div class="ml-2">{{ activity.statusCode | capitalFirstWord }}</div>
                            </div>
                        </div>
                        <div class="col">
                            {{ activity.issuer }}
                            <span *ngIf="activity.isAdmin" class="badge badge-info font-weight-normal">superuser</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <div class="status-bar d-none d-md-flex mt-1">
                    <div class="ball status-success"></div>
                    <div>{{ _enumTaskStatus.success | capitalFirstWord }}</div>
                    <div class="ball status-fail"></div>
                    <div>{{ _enumTaskStatus.fail | capitalFirstWord }}</div>
                    <div class="ball status-cancel"></div>
                    <div>{{ _enumTaskStatus.cancel | capitalFirstWord }}</div>
                    <div class="ball status-progress"></div>
                    <div>{{ _enumTaskStatus.progress | capitalFirstWord }}</div>
                    <div class="ball status-pending"></div>
                    <div>{{ _enumTaskStatus.pending | capitalFirstWord }}</div>
                </div>
                <div class="ml-auto">
                    <na-pager [currentPage]="_currentPage" [numberInPage]="_numberInPage" [total]="_total"
                        (onPageChanged)="onPageChange($event)">
                    </na-pager>
                </div>
            </div>
        </ng-template>
    </div>
</div>